app.service('telService', [
  function () {
    this.buildFakeTelInput = function (phone) {
      var telInputElemnt = $(document.createElement('ipnut'));
      telInputElemnt.intlTelInput();
      telInputElemnt.intlTelInput('setNumber', '+' + phone);
      return telInputElemnt;
    };
  },
]);
