app.service('trackerService', [
  'mainConfig',
  '$filter',
  '$http',
  'Analytics',
  'productService',
  'fbPixelSettings',
  'gaService',
  'slFeatureService',
  '$cookies',
  function (
    mainConfig,
    $filter,
    $http,
    Analytics,
    productService,
    fbPixelSettings,
    gaService,
    slFeatureService,
    $cookies,
  ) {
    var fbContentType = function (fbPixelSetting) {
      return fbPixelSetting && fbPixelSetting.single_variation
        ? 'product_group'
        : 'product';
    };

    var trackerFilters = {
      RecommendItem: function (props) {
        return {
          product_id: props.product_id,
          based_product_id: props.based_product_id,
          rank: props.rank,
        };
      },
      ProductClick: function (props) {
        return {
          page_type: props.page_type,
          product_id: props.product_id,
        };
      },
      ViewRelatedProducts: function (props) {
        return {
          product_infos: props.product_infos,
        };
      },
      WishlistAdd: function (props) {
        return {
          product_id: props.product_id,
        };
      },
      WishlistRemove: function (props) {
        return {
          product_id: props.product_id,
        };
      },
      ProductImpression: function (props) {
        return {
          page_type: props.page_type,
          product_ids: props.product_ids,
        };
      },
    };

    var getUniqueId = function () {
      var unique_id = window.localStorage.getItem('shoplytics_unique_id');
      return unique_id;
    };

    return {
      track: function (eventName, props) {
        var userId = mainConfig.currentUser
          ? mainConfig.currentUser['_id']
          : null;
        var filter = trackerFilters[eventName];
        if (typeof filter === 'function') {
          props = typeof props === 'object' ? filter(props) : {};
          if (slFeatureService.hasFeature('shoplytics_to_hd')) {
            Object.assign(props, { tracker_hd: true });
          }

          var trackData = {
            user_id: userId,
            data: JSON.stringify(props),
          };

          if (slFeatureService.hasFeature('cross_shop_tracker')) {
            Object.assign(trackData, { unique_id: getUniqueId() });
          }

          shoplytics('track', eventName, trackData);
        } else {
          console.warn('Event "' + eventName + '" does not exist.');
        }
      },
      shouldSendDefaultPageView: function () {
        var specificPage = ['products-show', 'orders-confirm'];
        return !_.include(specificPage, mainConfig.pageType);
      },
      pageView: function (props) {
        var userId = mainConfig.currentUser && mainConfig.currentUser._id;
        var pageViewData = Object.assign(
          {
            user_id: userId,
            product_id: mainConfig.trackerEventData['Product ID'],
          },
          props,
        );
        if (slFeatureService.hasFeature('shoplytics_to_hd')) {
          pageViewData.data = Object.assign(pageViewData.data || {}, {
            tracker_hd: true,
          });
        }

        if (slFeatureService.hasFeature('cross_shop_tracker')) {
          Object.assign(pageViewData, { unique_id: getUniqueId() });
        }

        pageViewData.data = JSON.stringify(pageViewData.data);
        shoplytics('track', 'PageView', pageViewData);
      },
      gaSendDetails: function (product, variation, related_products) {
        // Analytics.addProduct(productId, name, category, brand, variant, price, quantity, coupon, position);
        if (Analytics.configuration.enhancedEcommerce) {
          gaService.setUserId();
          if (related_products != null) {
            var ga_temp_variation = null;
            for (var index = related_products.length - 1; index >= 0; --index) {
              ga_temp_variation = null;
              if (
                related_products[index]['variations'] != null &&
                related_products[index]['variations'].length > 0
              ) {
                ga_temp_variation = related_products[index]['variations'][0];
              }
              // values marked "" are either for phase2, or not marked as required on GA examples
              Analytics.addImpression(
                productService.getSku(
                  related_products[index]['id'],
                  related_products[index]['sku'],
                  ga_temp_variation,
                ),
                $filter('translateModel')(
                  related_products[index]['title_translations'],
                ),
                '',
                '',
                '',
                productService.getVariationName(ga_temp_variation),
                '0',
                '',
              );
            }
          }
          Analytics.addProduct(
            productService.getSku(product._id, product.sku, variation),
            $filter('translateModel')(product.title_translations),
            '',
            '',
            productService.getVariationName(variation),
            '',
            '',
            '',
            '0',
          );
          Analytics.trackDetail();
        }
      },

      fbSendViewContent: function (product, value, variationSelected) {
        try {
          // facebook tracking pixel Standard Event: ViewContent
          var eventId = [
            'ViewContent',
            new Date().getTime(),
            mainConfig.currentUser
              ? mainConfig.currentUser._id
              : nanoidNonSecure(),
          ].join('.');

          if (slFeatureService.hasFeature('fb_ssapi')) {
            var shoplyticsTrackingData = {
              event_id: eventId,
              fbc: $cookies.get('_fbc'),
              fbp: $cookies.get('_fbp'),
            };

            if (mainConfig.currentUser) {
              if (mainConfig.currentUser._id) {
                shoplyticsTrackingData.external_id = mainConfig.currentUser._id;
              }
              if (mainConfig.currentUser.name) {
                shoplyticsTrackingData.fn = forge_sha256(
                  mainConfig.currentUser.name,
                );
              }
              if (mainConfig.currentUser.email) {
                shoplyticsTrackingData.em = forge_sha256(
                  mainConfig.currentUser.email,
                );
              }
              if (
                mainConfig.currentUser.phones &&
                mainConfig.currentUser.phones[0]
              ) {
                shoplyticsTrackingData.ph = forge_sha256(
                  mainConfig.currentUser.phones[0],
                );
              }
            }

            fbPixelSettings.forEach(function (setting) {
              if (!setting.trackingToken) return;

              var viewContentData = {
                token: setting.trackingToken,
                code: setting.trackingCode,
                data: JSON.stringify(
                  Object.assign(
                    {
                      content_type: fbContentType(setting),
                      content_ids: [
                        productService.getSku(
                          product._id,
                          product.sku,
                          variationSelected,
                          setting,
                        ),
                      ],
                      content_name: $filter('translateModel')(
                        product.title_translations,
                      ),
                      currency: mainConfig.merchantData.base_currency_code,
                      value: value,
                    },
                    shoplyticsTrackingData,
                  ),
                ),
              };

              if (slFeatureService.hasFeature('cross_shop_tracker')) {
                Object.assign(viewContentData, { unique_id: getUniqueId() });
              }

              shoplytics('track', 'ViewContent', viewContentData);
            });
          }

          fbq(
            'track',
            'ViewContent',
            {
              content_type: fbContentType(fbPixelSettings[0]),
              content_ids: [
                productService.getSku(
                  product._id,
                  product.sku,
                  variationSelected,
                  fbPixelSettings[0],
                ),
              ],
              content_name: $filter('translateModel')(
                product.title_translations,
              ),
              currency: mainConfig.merchantData.base_currency_code,
              value: value,
            },
            {
              eventID: eventId,
            },
          );
        } catch (e) {
          // facebook pixel does not exist
        }
      },

      fbAddToCart: function (product, value, variationSelected, eventId) {
        try {
          // facebook tracking pixel Standard Event: addToCart
          fbq(
            'track',
            'AddToCart',
            {
              content_name: $filter('translateModel')(
                product.title_translations,
              ),
              content_ids: [
                productService.getSku(
                  product._id,
                  product.sku,
                  variationSelected,
                  fbPixelSettings[0],
                ),
              ],
              content_type: fbContentType(fbPixelSettings[0]),
              value: value,
              currency: mainConfig.merchantData.base_currency_code,
            },
            {
              eventID: eventId,
            },
          );
        } catch (e) {
          // facebook tracking pixel doesn't exist
        }
      },

      addToWishlist: function (product, value, variationSelected) {
        try {
          // facebook tracking pixel Standard Event: AddToWishlist
          fbq('track', 'AddToWishlist', {
            value: value,
            currency: mainConfig.merchantData.base_currency_code,
            content_name: $filter('translateModel')(product.title_translations),
            content_ids: [
              productService.getSku(
                product._id,
                product.sku,
                variationSelected,
                fbPixelSettings[0],
              ),
            ],
            content_type: fbContentType(fbPixelSettings[0]),
          });
        } catch (e) {
          // facebook tracking pixel doesn't exist
        }
        this.track('WishlistAdd', {
          product_id: product._id,
        });
      },

      removeFromWishlist: function (product) {
        this.track('WishlistRemove', {
          product_id: product._id,
        });
      },

      fbPurchase: function (order) {
        try {
          // facebook tracking pixel Standard Event: purchase
          fbq(
            'track',
            'Purchase',
            {
              content_type: fbContentType(fbPixelSettings[0]),
              content_ids: _.map(order.subtotal_items, function (item) {
                return productService.getSku(
                  item.object_data._id,
                  item.object_data.sku,
                  item.item_data.variation_data,
                  fbPixelSettings[0],
                );
              }),
              num_items: _.reduce(
                order.subtotal_items,
                function (totalQuantity, item) {
                  return totalQuantity + (item.quantity || 0);
                },
                0,
              ),
              value: order.total.dollars,
              currency: order.total.currency_iso,
              order_id: order.order_number,
            },
            {
              eventID: 'Purchase.' + order._id,
            },
          );
        } catch (e) {
          // facebook tracking pixel doesn't exist
        }
      },

      fbInitiateCheckout: function (items, subtotal) {
        try {
          // facebook tracking pixel Standard Event: initiateCheckout
          if (subtotal === undefined || subtotal.currency_iso === '') return;

          var eventName = 'InitiateCheckout';
          var eventId =
            eventName +
            '.' +
            String(Math.floor(Math.random() * 100000 + 1)) +
            '.' +
            String(Math.floor(Date.now()));
          $cookies.put('fb_initiate_checkout_event_id', eventId);
          fbq(
            'track',
            eventName,
            {
              content_type: fbContentType(fbPixelSettings[0]),
              content_ids: _.map(items, function (item) {
                return productService.getSku(
                  item.product_id,
                  item.product.sku,
                  item.variation,
                  fbPixelSettings[0],
                );
              }),
              num_items: _.reduce(
                items,
                function (totalQuantity, item) {
                  return totalQuantity + (item.quantity || 0);
                },
                0,
              ),
              value: subtotal.dollars || 0,
              currency: subtotal.currency_iso,
            },
            {
              eventID: eventId,
            },
          );
        } catch (e) {
          // facebook tracking pixel doesn't exist
        }
      },

      fbAddPaymentInfo: function (items, total) {
        try {
          // facebook tracking pixel Standard Event: AddPaymentInfo
          fbq('track', 'AddPaymentInfo', {
            content_type: fbContentType(fbPixelSettings[0]),
            content_ids: _.map(items, function (item) {
              return productService.getSku(
                item.product_id,
                item.product.sku,
                item.variation,
                fbPixelSettings[0],
              );
            }),
            num_items: items.length,
            value: total.dollars,
            currency: total.currency_iso,
          });
        } catch (e) {
          // facebook tracking pixel doesn't exist
        }
      },
      userAction: function (action, eventCategory, options) {
        options = options || {};
        var getValue = function (key) {
          var value = options[key];
          return value !== undefined ? value : null;
        };
        var productId =
          getValue('productId') || mainConfig.trackerEventData['Product ID'];
        var eventData = {
          event_name: action,
          event_category: eventCategory,
          user_id: mainConfig.currentUser
            ? mainConfig.currentUser['_id']
            : null,
          product_id: productId, // Can be removed after the event tracker has finished refactoring
          variation_id: getValue('variationId'), // Can be removed after the event tracker has finished refactoring
          quantity: getValue('quantity'), // Can be removed after the event tracker has finished refactoring
          data: JSON.stringify({
            type: getValue('type'),
            product_id: productId,
            variation_id: getValue('variationId'),
            quantity: getValue('quantity'),
            price: getValue('price'),
            tracker_hd: slFeatureService.hasFeature('shoplytics_to_hd') || null,
          }),
        };

        if (slFeatureService.hasFeature('cross_shop_tracker')) {
          Object.assign(eventData, { unique_id: getUniqueId() });
        }

        shoplytics('track', action, eventData);
      },
      fbSignup: function (id) {
        try {
          //facebook tracking pixel Standard Event: Lead
          fbq(
            'track',
            'Lead',
            {
              content_name: id,
            },
            {
              eventID: 'Lead.' + id,
            },
          );
        } catch (e) {
          // facebook tracking pixel doesn't exist
        }
      },
    };
  },
]);
