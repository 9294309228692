app.directive('categoryFilterButton', [
  'mainConfig',
  '$location',
  'advanceFilterData',
  function (mainConfig, $location, advanceFilterData) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl:
        '/themes/shared/category/templates.category_filter_button.html',
      link: function (scope) {
        var getCurrentFilterTagCount = function () {
          var searchParams = $location.search();
          var allowTypes = ['brand[]', 'color[]', 'size[]', 'material[]'];
          var count = 0;

          Object.keys(searchParams).forEach(function (key) {
            var isFilterTag = /filter_tag/.test(key);
            if (isFilterTag || allowTypes.includes(key)) {
              var param = searchParams[key];
              count += Array.isArray(param) ? param.length : 1;
            }
          });

          if (searchParams.min_price || searchParams.max_price) {
            count += 1;
          }

          return count;
        };

        var currentThemeKey = mainConfig.merchantData.current_theme_key;
        var openDrawerThemes = ['king', 'chic', 'bianco'];
        var shouldOpenDrawer = openDrawerThemes.includes(currentThemeKey);

        scope.openFilter = function () {
          if (
            window.matchMedia('(min-width: 992px)').matches &&
            $('.side-category-filter').length &&
            !shouldOpenDrawer
          ) {
            var headHeight = $('.NavigationBar-container').height() || 0;
            window.scrollTo({
              behavior: 'smooth',
              top:
                $('.side-category-filter')[0].getBoundingClientRect().y -
                headHeight,
            });
          } else {
            $('.category-advance-filter').addClass('open');
          }
        };

        if (advanceFilterData) {
          scope.currentFilterTagCount = getCurrentFilterTagCount();
          scope.showFilterButton = true;
        }
      },
    };
  },
]);
