app.directive('customerCancelOrder', [
  'order',
  '$timeout',
  '$uibModal',
  function (order, $timeout, $uibModal) {
    return {
      restrict: 'E',
      templateUrl: '/themes/shared/order/templates.customer_cancel_order.html',
      scope: {
        isIntegratedPayment: '=',
      },
      link: function (scope) {
        scope.openCancelModal = function () {
          $uibModal.open({
            templateUrl:
              '/themes/shared/order/templates.customer_cancel_order-confirm-modal.html',
            scope: scope,
            backdrop: 'static',
            windowClass: 'Theme-modal Customer-cancel-order-modal',
            controller: [
              '$scope',
              '$filter',
              'ordersService',
              '$uibModalInstance',
              'staticResourceHost',
              function (
                $scope,
                $filter,
                ordersService,
                $uibModalInstance,
                staticResourceHost,
              ) {
                $scope.staticResourceHost = staticResourceHost;
                $scope.currentSection = 'confirm'; // confirm -> loading -> success or failed
                $scope.maxMessageWord = 150;
                $scope.state = {
                  reason: null,
                  message: '',
                };
                $scope.cancelReasons = [
                  'change_mind',
                  'expensive_price',
                  'repeat_purchase',
                  'wait_too_long',
                  'change_item',
                  'incorrect_customer_info',
                  'other',
                ];

                $scope.closeModal = function () {
                  $uibModalInstance.dismiss();
                };

                $scope.confirmCancelOrder = function () {
                  if (!$scope.state.form.$valid) return;
                  $scope.currentSection = 'loading';
                  var cancelReason = {
                    key: $scope.state.reason,
                    message:
                      $scope.state.reason === 'other'
                        ? $scope.state.message
                        : $filter('translate')(
                            'orders.show.customer_cancel_order.reasons.' +
                              $scope.state.reason,
                          ),
                  };

                  $timeout(function () {
                    ordersService
                      .customerRefund(order._id, cancelReason)
                      .then(function () {
                        $('customer-cancel-order').remove();
                        $scope.currentSection = 'success';
                        ordersService.customerCancel(order._id, cancelReason);
                      })
                      .catch(function () {
                        $scope.currentSection = 'failed';
                      });
                  }, 1000);
                };
              },
            ],
          });
        };
      },
    };
  },
]);
