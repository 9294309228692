app.service('backInStockService', [
  'slFeatureService',
  function (slFeatureService) {
    // back is stock service is available when 'back_in_stock_notify' and 'wishlist_app' are both installed
    this.isBackInStockAvailable =
      slFeatureService.hasFeature('back_in_stock_notify') &&
      slFeatureService.hasFeature('wishlist_app');
    this.shouldShowBtn = function (product, variationSelected) {
      if (this.isBackInStockAvailable) {
        if (product.unlimited_quantity || product.out_of_stock_orderable) {
          return false;
        }
        return variationSelected
          ? variationSelected.quantity <= 0
          : product.quantity <= 0;
      } else {
        return false;
      }
    };
  },
]);
