app.directive('productVariationDropdown', [
  'productService',
  'cartService',
  'trackerService',
  '$filter',
  'locationService',
  'slPixelService',
  '$location',
  function (
    productService,
    cartService,
    trackerService,
    $filter,
    locationService,
    slPixelService,
    $location,
  ) {
    return {
      restrict: 'AE',
      link: function (scope, element, attrs) {
        scope.variationName = [];

        scope.onVariationChanged = function (index) {
          scope.notEnoughStock = -1; //means that there is enough stock
          scope.notEnoughStockQty = -1;
          if (!index) {
            // Index is undefined when first load, and legacy variations are selected
            updateVariationOption(index);
          }

          scope.variationSelected = _.find(scope.product.variations, function (
            variation,
          ) {
            // Get list of matching variation fields
            // example: ["small", "red"]
            var variationSelected = _.filter(variation.fields, function (
              field,
              index,
            ) {
              return (
                scope.variationName[index] ==
                $filter('translateModel')(
                  variation.fields[index].name_translations,
                )
              );
            });
            return variationSelected.length == variation.fields.length;
          });

          if (
            angular.isUndefined(scope.variationSelected) ||
            !scope.variationSelected
          ) {
            scope.addItemQuantity = 1;
            scope.hasStock = false;
            scope.state.checkoutReady = false;
            return;
          }
          if (
            scope.variationSelected.max_order_quantity <= 0 &&
            !scope.product.out_of_stock_orderable
          ) {
            scope.productQuantities = [
              {
                label: $filter('translate')('product.out_of_stock'),
                value: '',
              },
            ];
            scope.productQuantities.push();
            scope.addItemQuantity = 0;
            scope.hasStock = false;
            scope.state.checkoutReady = false;
          } else {
            scope.hasStock = true;
            // if (scope.checkoutReady) scope.checkoutReady = true; // checking for not have delivery option and payment method, hide price etc is in
            scope.state.checkoutReady = productService.validateCheckoutReady(
              scope.product,
              scope.variationSelected,
              cartService.getItemPrice({ product: scope.product }),
            );
            scope.addItemQuantity = 1;
            scope.productQuantities = [];
            for (
              var i = 1;
              i <= scope.variationSelected.max_order_quantity;
              i++
            ) {
              scope.productQuantities.push({ label: i, value: i });
            }
          }
          // get variation price or member_price or price_sale
          if (
            scope.variationSelected.price ||
            scope.variationSelected.member_price ||
            scope.variationSelected.price_sale
          ) {
            scope.price = productService.showVariationPrice(
              scope.variationSelected,
            );
          }

          trackerService.gaSendDetails(
            scope.product,
            scope.variationSelected,
            scope.products,
          );
          scope.products = null; //clear the related products as they are only for impressions
          var price = cartService.getItemPrice({
            product: scope.product,
            variation: scope.variationSelected,
          });
          trackerService.fbSendViewContent(
            scope.product,
            _.isObject(price) ? price.dollars : 0,
            scope.variationSelected,
          );

          var wishlistBlockCenter =
            !scope.product.hide_price &&
            scope.product.addon_promotions &&
            scope.product.addon_promotions.length &&
            scope.hasStock;
          angular
            .element('.Wishlist-addItem-block')
            [wishlistBlockCenter ? 'addClass' : 'removeClass'](
              'Wishlist-block-center',
            );

          var productDetailPagePathRegex = /^\/products\/.*/;
          if (productDetailPagePathRegex.test($location.path()))
            sendSlPixelTracking();
        };

        function sendSlPixelTracking() {
          var product = scope.product;
          var targetVariation = scope.variationSelected;
          if (!_.isObject(targetVariation) || !_.isObject(product)) return;

          var priceTarget = product.same_price ? product : targetVariation;
          var variationId = targetVariation.key;
          var salePrice = priceTarget.price_sale
            ? priceTarget.price_sale.dollars
            : 0;
          var memberPrice = priceTarget.member_price
            ? priceTarget.member_price.dollars
            : 0;
          var currency =
            priceTarget.price && priceTarget.price.currency_iso
              ? targetVariation.price.currency_iso
              : null;
          var trackingProductParams = {
            productID: product._id,
            variationID: variationId,
            name: $filter('translateModel')(product.title_translations),
            price: priceTarget.price && priceTarget.price.dollars,
            currency: currency,
            salePrice: salePrice,
            memberPrice: memberPrice,
          };
          return slPixelService.hdPageView('productDetail', {
            product: trackingProductParams,
            inStock: scope.hasStock,
          });
        }

        scope.onVariationClick = function (e) {
          var variationValue = $(e.currentTarget).data('value');
          var dropdownIndex = $(e.currentTarget)
            .parents('.dropdown')
            .data('id');
          scope.variationName[dropdownIndex] = variationValue;
          scope.onVariationChanged(dropdownIndex);
        };

        function updateVariationOption(index) {
          angular.forEach(scope.product.digest_variations, function (v, k) {
            if (k != 0) {
              var matchedVariations = _.filter(
                scope.product.variations,
                function (value, key) {
                  return (
                    scope.variationName[0] ==
                    $filter('translateModel')(value.fields[0].name_translations)
                  );
                },
              );
              scope.product.digest_variations[k] = _.map(
                matchedVariations,
                function (variation) {
                  return $filter('translateModel')(
                    variation.fields[k].name_translations,
                  );
                },
              );
            }

            //To select the first available
            if (angular.isUndefined(index)) {
              selectBestOption(k);
            }
          });
          if (
            scope.product.digest_variations.length > 1 &&
            (scope.variationName[1] == null ||
              scope.variationName[1].length == 0 ||
              scope.product.digest_variations[1].indexOf(
                scope.variationName[1],
              ) < 1)
          ) {
            // Set the 2nd variation to choose the first option
            scope.variationName[1] = $filter('translateModel')(
              scope.product.digest_variations[1][0],
            );
          }
        }

        //Handling duplication issue
        function sortObject(object) {
          var sortedObj = {},
            keys = Object.keys(object);

          keys.sort(function (key1, key2) {
            (key1 = key1.toLowerCase()), (key2 = key2.toLowerCase());
            if (key1 < key2) return -1;
            if (key1 > key2) return 1;
            return 0;
          });

          for (var index in keys) {
            var key = keys[index];
            if (
              typeof object[key] == 'object' &&
              !(object[key] instanceof Array)
            ) {
              sortedObj[key] = sortObject(object[key]);
            } else {
              sortedObj[key] = object[key];
            }
          }

          return sortedObj;
        }

        //End of handling duplication issue

        function selectBestOption(variation_key) {
          var bestVariation =
            getRequestedVariation() ||
            getInStockVariation() ||
            scope.product.variations[0];
          scope.variationName[variation_key] = $filter('translateModel')(
            bestVariation.fields[variation_key].name_translations,
          );
        }

        function dedup(variations, field_titles) {
          var unionVariations = {};

          angular.forEach(variations, function (variation) {
            angular.forEach(field_titles, function (v, index) {
              if (angular.isUndefined(unionVariations[index])) {
                unionVariations[index] = [];
              }
              var variationTitles = sortObject(variation.fields[index]);

              var duplicate = _.find(unionVariations[index], function (
                variation,
              ) {
                return (
                  JSON.stringify(variation) == JSON.stringify(variationTitles)
                );
              });

              if (angular.isUndefined(duplicate)) {
                unionVariations[index].push(variationTitles);
              }
            });
          });
          return unionVariations;
        }

        var getUrlVariationId = function () {
          // TODO: update variationId params onVariationChange
          var urlParams = locationService.getQueryParams();
          return urlParams['variation'];
        };

        var getRequestedVariation = function () {
          var urlVariationId = getUrlVariationId();
          return _.findWhere(scope.product.variations, { key: urlVariationId });
        };

        var getInStockVariation = function () {
          return _.find(scope.product.variations, function (variation) {
            return variation.quantity > 0 || scope.product.unlimited_quantity;
          });
        };

        // variant selected from pdp
        var previewVariant = scope.product.variations.find(function (option) {
          return option.key === scope.variantId;
        });

        var watchProduct = scope.$watch('product', function (newValue) {
          if (newValue) {
            angular.forEach(
              dedup(scope.product.variations, scope.product.field_titles),
              function (value, index) {
                var variantOptionsOfIndex = scope.product.variant_options.filter(
                  function (option) {
                    return option.index === parseInt(index);
                  },
                );
                var previewVariantIndex = previewVariant
                  ? variantOptionsOfIndex.findIndex(function (option) {
                      return (
                        option.key === previewVariant.variant_option_ids[index]
                      );
                    })
                  : -1;
                var variationName = _.map(value, function (variant) {
                  return $filter('translateModel')(variant.name_translations);
                });
                scope.product.digest_variations[index] = variationName;
                scope.variationName[index] =
                  variationName[
                    previewVariantIndex > -1 ? previewVariantIndex : 0
                  ];
              },
            );

            if (
              scope.product.variations &&
              scope.product.variations.length > 0 &&
              !scope.product.hide_price
            ) {
              if (previewVariant) {
                previewVariant.variant_option_ids.forEach(function (index) {
                  scope.onVariationChanged(index);
                });
              } else {
                scope.onVariationChanged();
              }
            }
            watchProduct();
          }
        });
      },
    };
  },
]);
