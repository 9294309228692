app.directive('dynamicDeliveryFeeInfo', [
  '$filter',
  '$rootScope',
  '$timeout',
  'moneyService',
  function ($filter, $rootScope, $timeout, moneyService) {
    return {
      restrict: 'E',
      template:
        '<div class="dynamic-delivery-hint" ng-if="showDynamicDeliveryFeeInfo" ng-cloak>' +
        '{{infoText}}' +
        '</div>',
      scope: {},
      link: function (scope) {
        var generateDefaultText = function () {
          scope.infoText = $filter('translate')(
            'dynamic.delivery.fee.no.state.info',
          );
        };

        $rootScope.$on('delivery.fee.isUpdated', function () {
          $timeout(function () {
            scope.infoText = $filter('translate')(
              'dynamic.delivery.fee.calculate.info',
              {
                totalDeliveryFee: $('.dynamic-delivery-fee')
                  .children('.pull-right')
                  .html(),
              },
            );
            scope.showDynamicDeliveryFeeInfo = true;
          }, 100);
        });

        $rootScope.$on('delivery.fee.need.recalculate', function () {
          generateDefaultText();
        });

        $rootScope.$on('delivery.fee.isReady', function () {
          scope.showDynamicDeliveryFeeInfo = false;
        });

        $rootScope.$on('delivery.fee.need.recalculate', function () {
          scope.showDynamicDeliveryFeeInfo = true;
        });
      },
    };
  },
]);
