app.constant('LOCALE_CODE', {
  NORMAL_OUTLYING: [
    // 南海諸島
    '817',
    '819',
    // 澎湖
    '880',
    '881',
    '882',
    '883',
    '884',
    '885',
    // 琉球
    '929',
    // 台東
    '951',
    '952',
    // 金門
    '890',
    '891',
    '892',
    '893',
    '894',
    '896',
    // 連江
    '209',
    '210',
    '211',
    '212',
  ],
  TCAT_OUTLYING: [
    '880',
    '881',
    '884',
    '885',
    '951',
    '890',
    '891',
    '892',
    '893',
    '894',
    '209',
    '210',
    '211',
    '212',
  ],
  TCAT_NOT_SEND: ['817', '819', '882', '883', '952', '896'],
  TCAT_DELIVERY: [
    'tw_tcat_roomtemp',
    'tw_tcat_roomtemp_cod',
    'tw_tcat_refrigerated',
    'tw_tcat_refrigerated_cod',
    'tw_tcat_frozen',
    'tw_tcat_frozen_cod',
  ],
});

app.constant('CROSS_BORDER_CODE', {
  SEVEN_ELEVEN: {
    MY: 87000, // 0 ~ 86999 is west MY, 87000 ~ is east MY
  },
});
