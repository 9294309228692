app.service('productSetService', [
  'slFeatureService',
  function (slFeatureService) {
    function calculateProductSetStock(productSetData) {
      var stocks = productSetData
        .filter(function (productData) {
          return (
            !productData.childProduct.unlimited_quantity &&
            !productData.childProduct.out_of_stock_orderable
          );
        })
        .map(function (productData) {
          return productData.childVariation
            ? productData.childVariation.quantity
            : productData.childProduct.quantity;
        });
      return Math.min.apply(null, stocks);
    }

    function generateProductSetParams(productSetData) {
      return productSetData.map(function (childProductData) {
        return {
          child_product_id: childProductData.childProduct._id,
          child_variation_id:
            childProductData.childVariation &&
            childProductData.childVariation.key,
        };
      });
    }

    return {
      calculateProductSetStock: calculateProductSetStock,
      generateProductSetParams: generateProductSetParams,
      isProductSetEnabled: function (product) {
        return (
          product.type === 'product_set' &&
          slFeatureService.hasFeature('product_set')
        );
      },
    };
  },
]);
