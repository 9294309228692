app.directive('lineMessengerCheckbox', [
  '$q',
  'mainConfig',
  '$filter',
  'benchatService',
  'pnotifyService',
  'browserService',
  'imageServiceEndpoint',
  function (
    $q,
    mainConfig,
    $filter,
    benchatService,
    pnotifyService,
    browserService,
    imageServiceEndpoint,
  ) {
    return {
      restrict: 'A',
      templateUrl:
        '/themes/v1/default/views/templates.line-messenger-checkbox.html',
      scope: {
        benchatChannel: '=',
        customerId: '@',
        sessionId: '@',
        topicStr: '@',
      },
      link: function (scope) {
        scope.imageServiceEndpoint = imageServiceEndpoint;
        scope.customerIdentifier = scope.customerId
          ? scope.customerId
          : scope.sessionId;
        scope.userRef = benchatService.generateUserRef(
          scope.customerIdentifier,
        );
        scope.topics = scope.topicStr.split(',');
        scope.subscriptions = [];
        var createLineLoginWindow = function () {
          var url = benchatService.getLineMessageSubscriptionPopupUri(
            scope.benchatChannel.platformChannelId,
            scope.customerIdentifier,
          );
          scope.newWindowRef.location = url;
          listenLineLoginPopupClose();
        };

        var getWindowSize = function () {
          // For centering window, 612 and 600 is following Facebook`s size
          var top = window.screen.height - 612;
          top = top > 0 ? top / 2 : 0;

          var left = window.screen.width - 600;
          left = left > 0 ? left / 2 : 0;

          var width = 600;
          var height = 612;

          var windowSize =
            'width=' +
            width +
            ',height=' +
            height +
            ',top=' +
            top +
            ',left=' +
            left;

          return windowSize;
        };

        var getSubscriptions = function () {
          return benchatService
            .getSubscriptions(scope.customerIdentifier)
            .then(function (result) {
              var subscriptions = result.data.subscriptions;
              if (_.isEmpty(subscriptions)) {
                return [];
              }
              return benchatService.getLineActiveSubscriptions(
                scope.topics,
                subscriptions,
              );
            });
        };

        var listenLineLoginPopupClose = function () {
          var timer = setInterval(function () {
            if (scope.newWindowRef.closed) {
              clearInterval(timer);
              getSubscriptions().then(function (activeSubscriptions) {
                scope.subscriptions = activeSubscriptions;
                if (scope.subscriptions.length > 0) {
                  var successMessage = $filter('translate')(
                    'line.checkbox.connect.success',
                  );
                  pnotifyService.notify(successMessage, {});
                } else {
                  var failureMessage = $filter('translate')(
                    'line.checkbox.connect.failure',
                  );
                  pnotifyService.notify(failureMessage, {
                    customClass: 'error',
                    icon: 'fa fa-exclamation-triangle',
                  });
                }
              });
            }
          }, 1000);
        };

        var useLineLoginPop = function () {
          scope.newWindowRef = window.open('', 'LINE LOGIN', getWindowSize());
          createSubscription()
            .then(function () {
              // subscriptions successfully created
              createLineLoginWindow();
              return getSubscriptions();
            })
            .then(function (activeSubscriptions) {
              scope.subscriptions = activeSubscriptions;
            });
        };

        var createLineLoginRedirect = function () {
          var url = benchatService.getLineMessageSubscriptionRedirectToUri(
            scope.benchatChannel.platformChannelId,
            scope.customerIdentifier,
          );
          url = url + '&shopRedirectUri=' + window.location.pathname;
          window.location.replace(url);
        };

        var useLineLoginRedirection = function () {
          createSubscription()
            .then(function () {
              // subscriptions successfully created
              return createLineLoginRedirect();
            })
            .then(function (activeSubscriptions) {
              scope.subscriptions = activeSubscriptions;
            });
        };

        var createSubscription = function () {
          var subscriptions = _.map(scope.topics, function (topic) {
            return {
              userId: scope.customerIdentifier,
              channelOwnerId: mainConfig.merchantData._id,
              userRef: scope.userRef,
              platform: 'LINE_MES_API',
              topic: topic,
            };
          });

          subscriptions.push({
            userId: scope.customerIdentifier,
            channelOwnerId: mainConfig.merchantData._id,
            userRef: scope.userRef,
            platform: 'LINE_LOGIN',
            topic: 'USER_PROFILE',
          });

          return benchatService.patchSubscriptions(subscriptions);
        };

        scope.handleSubscription = function () {
          if (browserService.isInAppBrowser()) {
            useLineLoginRedirection();
          } else {
            useLineLoginPop();
          }
        };

        scope.handleDeactivateSubscription = function () {
          var tasks = _.map(scope.subscriptions, function (subscription) {
            return benchatService.deactivateSubscription(subscription._id);
          });

          $q.all(tasks)
            .then(function () {
              return getSubscriptions();
            })
            .catch(function () {
              return getSubscriptions();
            })
            .then(function (activeSubscriptions) {
              scope.subscriptions = activeSubscriptions;
            });
        };

        var init = function () {
          getSubscriptions().then(function (activeSubscriptions) {
            scope.subscriptions = activeSubscriptions;
          });
        };

        init();
      },
    };
  },
]);
