app.controller('SessionsNewController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  '$location',
  '$filter',
  'resource',
  'mainConfig',
  'pnotifyService',
  'benchatService',
  'flash',
  'staticImageHost',
  'fbService',
  'customValidator',
  'RecaptchaService',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    $location,
    $filter,
    resource,
    mainConfig,
    pnotifyService,
    benchatService,
    flash,
    staticImageHost,
    fbService,
    customValidator,
    RecaptchaService,
  ) {
    $scope.handleSimplifiedCircleBtnClick = function (type) {
      var $userForm = $('form[name="userForm"]');
      $scope.activeType = type;
      $userForm.show();
    };

    $scope.emailRegex = customValidator.EMAIL_REGEX;
    $scope.emailMobileRegex = customValidator.EMAIL_MOBILE_REGEX;
    $scope.staticImageHost = staticImageHost;

    $scope.resource = resource.data;
    $scope.resource.owner_id = mainConfig.merchantData._id;

    $scope.clickFBLogin = function (appId, version) {
      if (fbService.isMessengerInAppBrowser()) {
        return window.location.replace('/users/auth/facebook');
      }
      fbService.login(appId, version).then(function (facebookResponse) {
        window.location.replace('/');
      });
    };

    $scope.formSubmit = function () {
      if ($scope.userForm.submitting) {
        return;
      }

      $scope.userForm.showUnVerifiedMessage = false;
      $scope.userForm.submitting = true;
      $scope.userForm.submitted = true;
      $scope.userForm.errorMessage = null;

      if ($scope.userForm.$invalid) {
        $scope.userForm.submitting = false;
        return;
      }
      var method = 'POST';
      var path = '/api/users/sign_in';
      $http({
        method: method,
        url: path,
        data: {
          user: $scope.resource,
          recaptchable: true,
        },
      })
        .then(function (res) {
          var redirect_to = res.data.redirect_to;
          window.location.href = redirect_to;
          window.location.reload();
        })
        .catch(function (res) {
          var data = res.data;
          $scope.userForm.errorMessage = data.message;

          if (data.error) {
            if (data.reached_rate_limit)
              $scope.userForm.errorMessage = data.error;
            $scope.userForm.showUnVerifiedMessage = !data.reached_rate_limit;
            $scope.unConfirmedEmail = data.unconfirmed_email;
          }

          $scope.userForm.submitting = false;
        })
        .finally(function () {
          RecaptchaService.reset('sign-in-recaptcha');
        });
    };

    $scope.line_url = function (channel) {
      return benchatService.getLineLoginUri(channel.platformChannelId);
    };
  },
]);
