app.directive('productReviewCommentsMediaUpload', [
  '$upload',
  '$filter',
  '$timeout',
  'pnotifyService',
  function ($upload, $filter, $timeout, pnotifyService) {
    return {
      restrict: 'E',
      scope: {
        medias: '=',
      },
      templateUrl:
        '/themes/shared/product_review_comments/templates.product_review_comments_media_upload.html',
      link: function ($scope, element) {
        $scope.openFile = function () {
          if ($scope.imageUploading) {
            return;
          }
          var input = element.find('input');
          input.trigger('click');
        };

        $scope.deleteMedia = function (media) {
          var index = $scope.medias.indexOf(media);
          $scope.medias.splice(index, 1);
        };

        $scope.onFileSelect = function ($files) {
          $scope.flash = [];
          if ($files.length == 0) {
            return;
          }

          if ($files.length > 1) {
            pnotifyService.notify(
              $filter('translate')('media.validation.file_limit.one'),
              {
                customClass: 'error',
                icon: 'fa fa-exclamation-triangle',
              },
            );
            return;
          }

          $scope.imageUploading = true;

          for (var i = 0; i < $files.length; i++) {
            var file = $files[i];
            $scope.uploadMedia(file);
          }
        };

        $scope.uploadMedia = function (image) {
          $scope.upload = $upload
            .upload({
              url: '/api/media',
              method: 'POST',
              data: {
                recaptchable: false,
              },
              file: image,
              fileFormDataName: 'media[image_clip]',
              formDataAppender: function (fd, key, val) {
                if (angular.isArray(val)) {
                  angular.forEach(val, function (v) {
                    fd.append(key, v);
                  });
                } else if (angular.isObject(val)) {
                  angular.forEach(val, function (value, idx2) {
                    fd.append(key + '[' + idx2 + ']', value);
                  });
                } else {
                  fd.append(key, val);
                }
              },
            })
            .then(function (res) {
              var data = res.data;
              $scope.medias.push(data.data);

              $timeout(function () {
                window.resizeImages(element.find('.media-container').last()[0]);
              });
            })
            .catch(function () {
              pnotifyService.notify(
                $filter('translate')('media.validation.error'),
                {
                  customClass: 'error',
                  icon: 'fa fa-exclamation-triangle',
                },
              );
            })
            .finally(function () {
              $scope.imageUploading = false;
            });
        };
      },
    };
  },
]);
