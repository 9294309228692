app.directive('memberPointsSummary', [
  'checkoutService',
  '$compile',
  function (checkoutService, $compile) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        scope.$on('tax.fee.update', function () {
          checkoutService
            .requestPartial('cart', 'member_point_summary', {
              is_checkout_tax_update: true,
            })
            .then(function (res) {
              element.html($compile(res.data)(scope));
            })
            .catch(function (err) {
              console.error('Unable to load member point summary ', err);
            });
        });
        scope.$on('checkout.cart.summary.changed', function () {
          checkoutService
            .requestPartial('cart', 'member_point_summary')
            .then(function (res) {
              element.html($compile(res.data)(scope));
            })
            .catch(function (err) {
              console.error('Unable to load member point summary ', err);
            });
        });
        scope.$on('checkout.cart.summary.reload', function () {
          checkoutService
            .requestPartial('cart', 'member_point_summary')
            .then(function (res) {
              element.html($compile(res.data)(scope));
            })
            .catch(function (err) {
              console.error('Unable to load member point summary ', err);
            });
        });
      },
    };
  },
]);
