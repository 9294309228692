app.directive('slListPageWishlistButton', [
  '$rootScope',
  '$cookies',
  'mainConfig',
  'slFeatureService',
  'backInStockService',
  'wishlistService',
  'trackerService',
  'productService',
  'productSetService',
  function (
    $rootScope,
    $cookies,
    mainConfig,
    slFeatureService,
    backInStockService,
    wishlistService,
    trackerService,
    productService,
    productSetService,
  ) {
    return {
      templateUrl:
        '/themes/shared/product/templates.sl_list_page_wishlist_button.html',
      restrict: 'E',
      scope: {
        product: '=',
        hasVariations: '=',
        wishlistItems: '=',
        loginPath: '@',
      },
      link: function (scope, element) {
        scope.merchantId = mainConfig.merchantId;
        scope.userId = mainConfig.currentUser
          ? mainConfig.currentUser._id
          : null;
        scope.productId = scope.product._id;
        scope.shouldShowButton =
          slFeatureService.hasFeature('plp_wishlist') &&
          mainConfig.themeSettings.plp_wishlist;
        scope.isHoverButton = element.hasClass('hover-button');

        function isAllowMobileHoverButton() {
          var currentTheme = mainConfig.merchantData.current_theme_key;
          var enableTheme = ['philia', 'kingsman_v2', 'doris_bien'];
          return enableTheme.includes(currentTheme);
        }

        if (scope.userId && scope.wishlistItems) {
          scope.isActive = scope.wishlistItems.some(function (item) {
            return item.product._id === scope.productId;
          });
        }

        scope.getWishlist = function () {
          if (
            $('sl-list-page-wishlist-button.hover-button').length === 0 ||
            (scope.isHoverButton &&
              window.matchMedia('(min-width: 992px)').matches) ||
            ((!scope.isHoverButton || isAllowMobileHoverButton()) &&
              window.matchMedia('(max-width: 991px)').matches) ||
            (mainConfig.merchantData.current_theme_key === 'swanky' &&
              window.matchMedia('(min-width: 992px)').matches)
          ) {
            wishlistService
              .getList({
                product_id: scope.productId,
                merchant_id: scope.merchantId,
                user_id: scope.userId,
              })
              .then(function (res) {
                scope.isActive = res.data.items.length > 0;
                $rootScope.$emit('plpWishlistItemUpdate', {
                  productId: scope.productId,
                  isActive: scope.isActive,
                });
              })
              .finally(function () {
                scope.isUpdating = false;
              });
          }
        };

        scope.sendTrackEvent = function (action) {
          if (action === 'addItem') {
            trackerService.addToWishlist(scope.product, 0, null);
          } else {
            trackerService.removeFromWishlist(scope.product);
          }
        };

        scope.toggleWishlistItem = function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (
            scope.hasVariations ||
            productSetService.isProductSetEnabled(scope.product)
          ) {
            return scope.openQuickCartModal(scope.productId);
          }

          if (!$rootScope.isUserLoggedIn) {
            angular.element(document).on('click.wishlistTooltip', function () {
              if (element.find('.Tooltip-wishlistTooltip').length) {
                angular
                  .element('.Tooltip-wishlistTooltip.is-tooltip-show')
                  .fadeOut()
                  .removeClass('is-tooltip-show');
              } else {
                angular.element(document).off('click.wishlistTooltip');
              }
            });

            element.find('.Tooltip-wishlistTooltip').fadeIn(300, function () {
              element
                .find('.Tooltip-wishlistTooltip')
                .addClass('is-tooltip-show');
            });
          } else {
            if (scope.isUpdating) return;

            scope.isUpdating = true;
            var wishlistItemData = {
              product_id: scope.productId,
              variation_key: '',
            };
            var toggleAction = function () {
              var apiAction = scope.isActive ? 'removeItem' : 'addItem';
              wishlistService[apiAction](wishlistItemData).finally(function () {
                scope.getWishlist();
                scope.sendTrackEvent(apiAction);
              });
            };

            if (!scope.isActive && backInStockService.isBackInStockAvailable) {
              productService.getById(scope.productId).then(function (response) {
                var product = response.data.data;
                if (backInStockService.shouldShowBtn(product)) {
                  wishlistItemData.is_subscribed_notify = true;
                }
                toggleAction();
              });
            } else {
              toggleAction();
            }
          }
        };

        scope.openQuickCartModal = function (productId) {
          if ($('.QuickCart-modal').length) return;

          var productItemElement = angular
            .element('product-item[product-id="' + productId + '"]')
            .has(element);
          var addToCartBtn = productItemElement.find(
            '.btn-add-to-cart:not(.quick-cart-item)',
          );
          var addToPromotionCartBtn = productItemElement.find(
            '.btn-add-to-promotion-cart',
          );

          if (addToCartBtn.length) {
            addToCartBtn.click();
          } else if (addToPromotionCartBtn.length) {
            addToPromotionCartBtn.click();
          } else {
            var productItemScope = productItemElement.scope();
            if (!productItemScope.modalOpened) {
              productItemScope.modalOpened = true;
              var variantId = element.attr('data-variant-id');
              productItemScope.quickAdd(variantId);
            }
          }
        };

        scope.toLoginPage = function (e) {
          e.preventDefault();
          e.stopPropagation();
          $cookies.put(
            'cookieWishlistItem',
            JSON.stringify({
              product_id: scope.productId,
              variation_key: '',
            }),
          );
          window.location.href = scope.loginPath;
        };

        if (scope.isHoverButton) {
          scope.$watch('isActive', function () {
            scope.isActive
              ? element.addClass('is-active')
              : element.removeClass('is-active');
          });
        }

        $rootScope.$on('modal.close', function (event, data) {
          if ($rootScope.isUserLoggedIn && data.productId === scope.productId) {
            scope.getWishlist();
          }
        });

        $rootScope.$on('plpWishlistItemUpdate', function (event, data) {
          if (data.productId === scope.productId) {
            scope.isActive = data.isActive;
          }
        });

        $rootScope.$watch('cookieWishlistItem', function (newVal) {
          if (newVal && newVal.product_id === scope.productId) {
            if (newVal.variation_key) {
              scope.openQuickCartModal(newVal.product_id);
            }
            scope.getWishlist();
          }
        });
      },
    };
  },
]);
