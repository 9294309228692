app.controller('MessageFormController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  '$uibModalStack',
  'mainConfig',
  '$upload',
  'customValidator',
  '$element',
  '$filter',
  'RecaptchaService',
  'pnotifyService',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    $uibModalStack,
    mainConfig,
    $upload,
    customValidator,
    $element,
    $filter,
    RecaptchaService,
    pnotifyService,
  ) {
    // console.log("$window.location.href:"+$window.location.pathname);
    $scope.emailRegex = customValidator.EMAIL_REGEX;
    $scope.message = {
      recipient_id: mainConfig.merchantId,
      recipient_type: 'Merchant',
    };
    if ($rootScope.isUserLoggedIn) {
      $scope.message.sender_id = $rootScope.currentUser._id;
      $scope.message.sender_email = $rootScope.currentUser.email;
      $scope.message.sender_type = 'User';
    } else {
      $scope.message.sender_email = '';
      // parse current URL
      current_query = new URI(window.location.href);
      current_query = current_query.query();
      current_query = URI.parseQuery(current_query);
      $scope.message.sender_id = current_query['sender_id'];
      $scope.message.sender_type = 'User';
    }
    $scope.$on('userService.update', function (event, data) {
      if (userService.isUserLoggedIn) {
        $scope.message.sender_id = userService.getUserID();
        $scope.message.sender_email = $rootScope.currentUser.email;
        $scope.message.sender_type = 'User';
      } else {
        $scope.message.sender_email = '';
        // parse current URL
        current_query = new URI(window.location.href);
        current_query = current_query.query();
        current_query = URI.parseQuery(current_query);
        $scope.message.sender_id = current_query['sender_id'];
        $scope.message.sender_type = 'User';
      }
    });
    $scope.message.ref_data = {
      full_path: $window.location.href,
      path: $window.location.pathname,
    };

    function resetSubmitMessageRecaptcha() {
      if ($('#submit-message-recaptcha').length > 0) {
        RecaptchaService.reset('submit-message-recaptcha');
      }
      if ($('#btn-form-submit-modal').length > 0) {
        RecaptchaService.reset('btn-form-submit-modal');
      }
    }

    $scope.submitMessageForm = function () {
      if ($scope.messageForm.submitting == true) {
        return;
      }
      $scope.messageForm.submitted = true;
      $scope.messageForm.submitting = true;

      if (!$scope.messageForm.$valid) {
        $scope.messageForm.submitting = false;
        resetSubmitMessageRecaptcha();
        return;
      }
      if ($scope.message.sender_email == '' && $scope.message.sender_id == '')
        return;
      $scope.loading = true;
      $http({
        method: 'POST',
        url: '/api/messages',
        data: {
          message: $scope.message,
          recaptchable: true,
        },
      })
        .then(function (res) {
          $scope.loading = false;
          $scope.messageForm.submitting = false;
          // if (data.result == false) {
          //   $scope.errorMessage = data.message;
          //   return;
          // }
          $rootScope.$broadcast('chat.message.created', res.data);
          $scope.message.text = '';
          $scope.deleteMedia();
          $scope.loading = false;
          if ($('.contact-us-modal').length > 0) {
            $rootScope.closeMessageForm();
          }
          $scope.messageForm.submitted = false;
        })
        .catch(function (res) {
          pnotifyService.notify(
            $filter('translate')(
              'order_comment_and_message.' + res.data.message,
            ),
            {
              customClass: 'error',
              icon: 'fa fa-exclamation-triangle',
            },
          );
          $scope.loading = false;
          $scope.messageForm.submitting = false;
        })
        .finally(function () {
          resetSubmitMessageRecaptcha();
        });
    };

    $scope.validateMediaRecaptcha = function ($files) {
      if ($files.length === 0) {
        return;
      }
      $scope.tempFiles = $files;
      RecaptchaService.reset('select-media-recaptcha');
      RecaptchaService.execute('select-media-recaptcha');
    };

    $scope.onMediaRecaptchaSuccess = function () {
      $scope.onFileSelect($scope.tempFiles);
    };

    $scope.onFileSelect = function ($files) {
      if ($scope.imageUploading == true) {
        return;
      }
      $scope.flash = [];
      if ($files.length > 4) {
        $scope.flash.push({
          type: 'error',
          text: 'You may only upload up to 4 files',
        });
        return;
      }

      for (var i = 0; i < $files.length; i++) {
        var file = $files[i];
        // if (file.type !='image/png' && file.type !='image/jpeg') {
        //   $scope.imageUploading = false;
        //   $scope.flash.push ({type:"error", text: "Only Jpeg and Png files are supported"});
        //   return;
        // }

        $scope.imageUploading = true;
        $scope.uploadMedia(file);
      }
    };

    $scope.uploadMedia = function (image) {
      $scope.errorMessage = '';
      $scope.upload = $upload
        .upload({
          url: '/api/media',
          method: 'POST',
          data: {
            media: {
              status: 'temp',
              trackable_type: 'Chat',
            },
            recaptchable: true,
          },
          file: image,
          fileFormDataName: 'media[image_clip]',
          formDataAppender: function (fd, key, val) {
            if (angular.isArray(val)) {
              angular.forEach(val, function (v) {
                fd.append(key, v);
              });
            } else if (angular.isObject(val)) {
              angular.forEach(val, function (value, idx2) {
                fd.append(key + '[' + idx2 + ']', value);
              });
            } else {
              fd.append(key, val);
            }
          },
        })
        .then(function (res) {
          var data = res.data;
          $scope.message.media = data.data;
          $scope.message.media_id = data.data._id;
        })
        .catch(function () {
          $scope.errorMessage = $filter('translate')('media.validation.error');
        })
        .finally(function () {
          $scope.imageUploading = false;
          if ($('#select-media-recaptcha').length > 0) {
            RecaptchaService.reset('select-media-recaptcha');
          }
        });
    };

    $scope.deleteMedia = function () {
      $scope.message.media = null;
      $scope.message.media_id = null;
      if ($('#select-media-recaptcha').length > 0) {
        RecaptchaService.reset('select-media-recaptcha');
      }
    };
  },
]);
