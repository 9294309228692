app.service('familyMartService', [
  function () {
    var isFamilyMart = function (region_type) {
      switch (region_type) {
        case 'tw_fm_c2c_pay':
        case 'tw_fm_c2c_nopay':
        case 'tw_fm_b2c_pay':
        case 'tw_fm_b2c_nopay':
          return true;
        default:
          return false;
      }
    };

    return {
      isFamilyMart: isFamilyMart,
    };
  },
]);
