app.service('productService', [
  '$rootScope',
  '$http',
  'mainConfig',
  '$filter',
  '$q',
  'featureService',
  'slFeatureService',
  function (
    $rootScope,
    $http,
    mainConfig,
    $filter,
    $q,
    featureService,
    slFeatureService,
  ) {
    this.merchantId = '';
    var merchantData = {};
    var deliveryOptions = null;
    var paymentMethods = null;

    this.setMerchantData = function (data) {
      merchantData = data;
      //$rootScope.$broadcast( 'MerchantService.update', merchantData );
    };
    this.getCurrency = function () {
      return merchantData.base_currency;
    };

    this.fetchData = function () {
      $http({
        method: 'GET',
        url:
          mainConfig.apiBaseUrl + '/api/theme/v1/merchants/' + this.merchantId,
      }).then(function (res) {
        var merchantData = res.data.data;
        $rootScope.$broadcast('merchantService.update', merchantData);
      });
    };
    this.getDeliveryOptions = function () {
      if (deliveryOptions == null) return [];
      return deliveryOptions;
    };
    this.fetchDeliveryOptions = function () {
      $http({
        method: 'GET',
        url:
          mainConfig.apiBaseUrl +
          '/api/theme/v1/merchants/' +
          this.merchantId +
          '/delivery_options',
      }).then(function (res) {
        var deliveryOptions = res.data.data;
        $rootScope.$broadcast(
          'merchantService.deliveryOptions.update',
          deliveryOptions,
        );
      });
    };
    this.fetchCheckoutOptions = function () {
      $http({
        method: 'GET',
        url:
          mainConfig.apiBaseUrl +
          '/api/theme/v1/merchants/' +
          this.merchantId +
          '/checkout_options',
      }).then(function (res) {
        var data = res.data;
        deliveryOptions = data.data.delivery_options;
        paymentMethods = data.data.payment_methods;
        $rootScope.$broadcast(
          'merchantService.checkoutOptions.update',
          data.data,
        );
      });
    };
    this.checkStock = function (id, v_id, pageId, isFastCheckoutCart) {
      var params = { variation_id: v_id, page_id: pageId };
      if (isFastCheckoutCart) {
        params.product_id = id;
      }
      return $http({
        method: 'GET',
        url:
          '/api/merchants/' +
          mainConfig.merchantId +
          '/products/' +
          id +
          '/check_stock',
        params: params,
      });
    };
    this.checkStocks = function (products, isFastCheckoutCart) {
      var params = { products: products };
      if (isFastCheckoutCart) {
        params.product_id = products[0].product_id;
      }
      return $http({
        method: 'POST',
        url:
          '/api/merchants/' + mainConfig.merchantId + '/products/check_stocks/',
        data: params,
      });
    };
    this.checkPurchaseLimitByCustomer = function (productId, quantity) {
      var params = { quantity: quantity };

      return $http({
        method: 'GET',
        url:
          '/api/merchants/' +
          mainConfig.merchantId +
          '/products/' +
          productId +
          '/purchase_limit_by_customer',
        params: params,
      });
    };
    (this.checkProductSetStock = function (params) {
      const productSetParams = params.productSetData
        .map((data) => {
          let param =
            'selected_child_products[][child_product_id]=' +
            data.child_product_id;
          if (data.child_variation_id) {
            param +=
              '&&selected_child_products[][child_variation_id]=' +
              data.child_variation_id;
          }
          return param;
        })
        .join('&');
      return $http({
        method: 'GET',
        url:
          '/api/merchants/' +
          mainConfig.merchantId +
          '/products/' +
          params.id +
          '/check_stock?' +
          productSetParams,
      });
    }),
      (this.getVariationName = function (variation) {
        variation_name = '';
        if (variation) {
          temp = $filter('translateModel')(variation.fields_translations);
          if (temp[0]) {
            if (temp[1]) {
              variation_name = temp[0] + temp[1];
            } else {
              variation_name = temp[0];
            }
          }
        }
        return variation_name;
      });
    this.getSku = function (_id, product_sku, variation, settings) {
      // Note: Logic here must match product_sku from fb_feed_helper.rb
      if (settings != null) {
        if (settings.single_variation && settings.unique_ids) {
          return _id;
        }
        if (settings.single_variation && !settings.unique_ids) {
          return product_sku || _id;
        }
        if (settings.unique_ids && variation != null) {
          return _id + ':' + variation.key;
        }
        if (settings.unique_ids) {
          return _id;
        }
      }
      if (_.isEmpty(variation) == false) {
        if (variation.sku != null && variation.sku.length > 0) {
          return variation.sku;
        }
        if (product_sku != null && product_sku.length > 0) {
          return product_sku + ':' + variation.key;
        }
        if (variation.sku == null && product_sku == null) {
          return _id + ':' + variation.key;
        }
      }
      if (
        _.isEmpty(variation) &&
        product_sku != null &&
        product_sku.length > 0
      ) {
        return product_sku;
      }
      return _id;
    };
    this.getByCategory = function (categoryId, options) {
      if (options === undefined) {
        options = {};
      }
      options.limit = options.limit || 24;
      options.page = options.page || 1;
      var requestOptions = {
        url: '/api/categories/' + categoryId + '/products',
        params: options,
        method: 'GET',
      };
      var offset = (options.page - 1) * options.limit;
      requestOptions.params.offset = offset;

      if (options.format && options.format == 'html') {
        requestOptions.url = '/categories/' + categoryId;
        requestOptions.headers = {
          'X-Requested-With': 'XMLHttpRequest',
        };
      }

      var additionOptions = ['col_class'];
      for (var i = 0; i < additionOptions.length; i++) {
        var key = additionOptions[i];
        if (options[key]) {
          requestOptions.params[key] = options[key];
        }
      }

      return $q(function (resolve, reject) {
        $http(requestOptions)
          .then(function (res) {
            resolve(res.data);
          })
          .catch(function (res) {
            reject(res.status);
          });
      });
    };
    this.validateCheckoutReady = function (
      product,
      variationSelected,
      productPrice,
    ) {
      if (variationSelected) {
        if (product.same_price) {
          var validVariationPrice = productPrice && productPrice.cents > 0;
        } else {
          var validVariationPrice =
            (variationSelected.price &&
              variationSelected.price.cents &&
              variationSelected.price.cents > 0) ||
            (variationSelected.member_price &&
              variationSelected.member_price.cents &&
              variationSelected.member_price.cents > 0 &&
              mainConfig.currentUser &&
              slFeatureService.hasFeature('member_price')) ||
            (variationSelected.price_sale &&
              variationSelected.price_sale.cents &&
              variationSelected.price_sale.cents > 0);
        }

        return (
          mainConfig.merchantData.payment_method_count > 0 &&
          mainConfig.merchantData.delivery_option_count > 0 &&
          variationSelected.max_order_quantity > 0 &&
          validVariationPrice
        );
      } else {
        return (
          mainConfig.merchantData.payment_method_count > 0 &&
          mainConfig.merchantData.delivery_option_count > 0 &&
          productPrice != null &&
          productPrice.cents > 0 &&
          product.hide_price != true
        );
      }
    };

    this.getById = function (id) {
      return $http({
        method: 'GET',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        url: '/api/merchants/' + mainConfig.merchantId + '/products/' + id,
      });
    };

    this.hasLowerPrice = function (product) {
      var tierMemberPriceEnable = slFeatureService.hasFeature(
        'tier_member_price',
      );
      if (
        tierMemberPriceEnable &&
        product.price &&
        product.lowest_member_price &&
        product.price.cents === product.lowest_member_price.cents
      ) {
        return false;
      }

      return (
        (product.price_sale && product.price_sale.cents > 0) ||
        (((tierMemberPriceEnable &&
          product.lowest_member_price &&
          product.lowest_member_price.cents > 0) ||
          (product.member_price && product.member_price.cents > 0)) &&
          mainConfig.currentUser &&
          slFeatureService.hasFeature('member_price'))
      );
    };

    this.showVariationPrice = function (variation) {
      if (
        variation.member_price &&
        variation.member_price.cents > 0 &&
        mainConfig.currentUser &&
        slFeatureService.hasFeature('member_price')
      ) {
        return variation.member_price.label;
      }
      if (variation.price_sale && variation.price_sale.cents > 0) {
        return variation.price_sale.label;
      }
      return variation.price.label;
    };

    this.variationPriceMemberTag = function (variation) {
      if (!variation) {
        return '';
      }

      if (
        variation.member_price &&
        variation.member_price.cents > 0 &&
        mainConfig.currentUser &&
        slFeatureService.hasFeature('member_price')
      ) {
        var memberOnlyTag = $filter('translate')('product.member_only');
        var memberTierPrice =
          mainConfig.currentUser && variation.product_price_tiers
            ? variation.product_price_tiers[
                mainConfig.currentUser.membership_tier_id
              ]
            : null;
        if (
          slFeatureService.hasFeature('tier_member_price') &&
          !_.isEmpty(memberTierPrice) &&
          memberTierPrice.cents > 0
        ) {
          memberOnlyTag = $filter('translate')('product.tier_member_only', {
            tierName: $filter('translateModel')(
              mainConfig.currentUser.membership_tier.name_translations,
            ),
          });
        }
        return memberOnlyTag;
      }
      return '';
    };

    this.showMemberPriceTip = function (product) {
      if (
        mainConfig.currentUser ||
        !mainConfig.merchantData.product_setting.enabled_show_member_price
      )
        return false;
      return (
        product &&
        product.member_price &&
        product.member_price.cents > 0 &&
        slFeatureService.hasFeature('member_price')
      );
    };

    this.getVariationType = function (isSelectorEnabled) {
      if (isSelectorEnabled) {
        return 'selector';
      }
      if (
        !isSelectorEnabled &&
        featureService.hasFeature('variation_label_swatches') &&
        mainConfig.merchantData.product_setting.variation_display === 'swatches'
      ) {
        return 'label';
      }
      return 'dropdown';
    };

    this.showPromotionLabel = function (subtotalItem) {
      if (['addon', 'addonproduct'].includes(subtotalItem.object_data.type)) {
        return $filter('translate')('product.addon_products.label');
      } else if (subtotalItem.object_data.type === 'gift') {
        return $filter('translate')('product.gift.label');
      } else if (subtotalItem.type === 'subscription_product') {
        return $filter('translate')('product.subscription_product.label');
      }
      return false;
    };

    this.isItemVariant = function (subtotalItem) {
      return (
        subtotalItem.item_data.variation_data &&
        subtotalItem.item_data.variation_data.key !== ''
      );
    };

    this.getProductItemLink = function (subtotalItem) {
      if (_.isEmpty(subtotalItem)) {
        return;
      }
      if (
        ['addon', 'addonproduct'].includes(subtotalItem.object_data.type) ||
        subtotalItem.object_data.type === 'gift'
      ) {
        return;
      }
      return '/products/' + subtotalItem.item_id;
    };
  },
]);
