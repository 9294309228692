app.controller('MemberPointsController', [
  '$scope',
  '$rootScope',
  '$uibModal',
  '$timeout',
  '$filter',
  'mainConfig',
  'memberPointsService',
  'promotionService',
  function (
    $scope,
    $rootScope,
    $uibModal,
    $timeout,
    $filter,
    mainConfig,
    memberPointsService,
    promotionService,
  ) {
    $scope.memberPoints = [];
    $scope.pagination = {
      total: 0,
      itemsPerPage: 0,
      current: 0,
    };
    $scope.state = {
      loadingItems: false,
      loadingSummary: false,
      loadingRule: false,
    };
    $scope.errors = {
      loadMoreError: false,
      loadSummaryError: false,
      loadingRuleError: false,
    };
    $scope.isPromotionExist = false;
    $scope.memberPointRules = [];
    currentLocale = mainConfig.localeData.loadedLanguage.code;
    $scope.showRuleModal = function () {
      ruleRemarks = $scope.ruleRemarks;
      ruleDescription = validateRemark(ruleRemarks[currentLocale])
        ? ruleRemarks[currentLocale]
        : ruleRemarks['en'];
      $rootScope.currentModal = $uibModal.open({
        templateUrl:
          '/themes/v1/default/views/users/templates.dialog.member_point_rule.html',
        controller: [
          '$scope',
          '$filter',
          '$uibModalInstance',
          function ($scope, $filter, $uibModalInstance) {
            $scope.ruleDescription = ruleDescription;
            $scope.confirm = function () {
              $uibModalInstance.close();
            };
            $timeout(function () {
              $('.promotion-term-dialog .scroll-area').overlayScrollbars({
                overflowBehavior: { x: 'hidden' },
              });
              $('.promotion-term-modal-backdrop').one('click', function () {
                $uibModalInstance.dismiss('cancel');
              });
            });
          },
        ],
        windowClass: 'promotion-term-modal-window',
        backdropClass: 'promotion-term-modal-backdrop',
      });
      $rootScope.currentModal.opened = $rootScope.currentModal.opened.then(
        function () {
          $rootScope.$emit('modal.open');
        },
      );
    };
    $scope.getMemberPointSummary = function () {
      $scope.errors.loadSummaryError = false;
      $scope.state.loadingSummary = true;
      var limit = 2;
      memberPointsService
        .getFulfillmentSummary(limit)
        .then(function (response) {
          res = response.data;
          $scope.memberPointsFulfillments = res.items;
          $scope.remainingPointSummary = res.remaining_value;
        })
        .catch(function () {
          $scope.errors.loadSummaryError = true;
        })
        .finally(function () {
          $scope.state.loadingSummary = false;
        });
    };
    $scope.getMemberPointRules = function () {
      $scope.state.loadingRule = true;
      memberPointsService
        .getMemberPointRules()
        .then(function (response) {
          $scope.memberPointRules = response.data.items;
          var earnFromOrderRule = _.find($scope.memberPointRules, function (
            rule,
          ) {
            return rule.rule_type === 'earn_from_order';
          });
          $scope.ruleRemarks = earnFromOrderRule.remarks_translations;
          var redeemOrderRule = _.find($scope.memberPointRules, function (
            rule,
          ) {
            return rule.rule_type !== 'earn_from_order';
          });
          if (redeemOrderRule) {
            $scope.isActive = redeemOrderRule.status === 'active';
            var baseCurrency = mainConfig.merchantData.base_currency;
            if (baseCurrency.symbol_first) {
              var costMoney =
                baseCurrency.alternate_symbol + earnFromOrderRule.unit_price;
              var discountMoney =
                baseCurrency.alternate_symbol + redeemOrderRule.price_per_unit;
              var pointEqualCash =
                baseCurrency.alternate_symbol +
                parseInt(
                  ($scope.currentUser.member_point_balance || 0) /
                    redeemOrderRule.unit_point,
                ) *
                  redeemOrderRule.price_per_unit;
            } else {
              var costMoney =
                earnFromOrderRule.unit_price + baseCurrency.alternate_symbol;
              var discountMoney =
                redeemOrderRule.price_per_unit + baseCurrency.alternate_symbol;
              var pointEqualCash =
                parseInt(
                  ($scope.currentUser.member_point_balance || 0) /
                    redeemOrderRule.unit_point,
                ) *
                  redeemOrderRule.price_per_unit +
                baseCurrency.alternate_symbol;
            }
            $scope.redeemOrderRuleDescription = $filter('translate')(
              'member_points.redeem_cash_description',
              {
                costMoney: costMoney,
                earnPoint: earnFromOrderRule.points_per_unit,
                costPoint: redeemOrderRule.unit_point,
                discountMoney: discountMoney,
              },
            );
            $scope.pointEqualCash = pointEqualCash;
          }
        })
        .catch(function () {
          $scope.errors.loadRuleError = true;
        })
        .finally(function () {
          $scope.state.loadingRule = false;
        });
    };
    var validateRemark = function (remark) {
      if (remark === '' || remark === undefined) {
        return false;
      } else {
        return true;
      }
    };
    $scope.getMemberPoints = function () {
      $scope.errors.loadMoreError = false;
      if (
        $scope.pagination.current > $scope.maxPage ||
        $scope.state.loadingItems
      ) {
        return;
      }
      $scope.state.loadingItems = true;
      memberPointsService
        .getMemberPoints($scope.pagination.current + 1)
        .then(function (response) {
          $scope.pagination.current += 1;
          var data = response.data;
          data.items.forEach(function (item) {
            if (!_.findWhere($scope.memberPoints, { _id: item._id })) {
              $scope.memberPoints.push(item);
            }
          });
          $scope.pagination.total = data.total;
          $scope.pagination.itemsPerPage = data.limit;
          $scope.maxPage = Math.ceil(
            $scope.pagination.total / $scope.pagination.itemsPerPage,
          );
        })
        .catch(function () {
          $scope.errors.loadMoreError = true;
        })
        .finally(function () {
          $scope.state.loadingItems = false;
        });
    };
    $scope.getPromotionExistOrNot = function () {
      promotionService
        .checkRedeemGiftPromotionExist()
        .then(function (response) {
          $scope.isPromotionExist = response.data.result;
        });
    };
    $scope.getMemberPointSummary();
    $scope.getMemberPointRules();
    $scope.getMemberPoints();
    $scope.getPromotionExistOrNot();
  },
]);
