app.controller('ConfirmationsNewController', [
  '$scope',
  '$http',
  '$filter',
  '$timeout',
  'resource',
  'mainConfig',
  'flash',
  'pnotifyService',
  'customValidator',
  'trackerService',
  'Analytics',
  '$attrs',
  function (
    $scope,
    $http,
    $filter,
    $timeout,
    resource,
    mainConfig,
    flash,
    pnotifyService,
    customValidator,
    trackerService,
    Analytics,
    $attrs,
  ) {
    if (flash.registration_message) {
      PNotify.removeAll();
      pnotifyService.notify(flash.registration_message, {});
    }

    if ($attrs.userId) {
      trackerService.fbSignup($attrs.userId);
    }

    if (Analytics.configuration.enhancedEcommerce) {
      Analytics.pageView();
    }

    $scope.count = 0;
    $scope.countDownText = '';
    $scope.resource = resource.data;
    $scope.resource.owner_id = mainConfig.merchantData._id;
    $scope.userForm = {
      submitting: false,
    };

    $scope.emailRegex = customValidator.EMAIL_REGEX;

    $scope.resendConfirmationEmail = function () {
      if ($scope.userForm.submitting) {
        return;
      }

      $scope.userForm.submitting = true;
      $scope.userForm.errorMessage = null;
      $scope.userForm.infoMessage = null;

      $http({
        method: 'POST',
        url: '/users/confirmation',
        data: { user: $scope.resource },
      })
        .then(
          function (res) {
            if (res.data.confirmed) {
              $scope.userForm.infoMessage = res.data.message;
            } else {
              $scope.count = 5;
              countDown();
              pnotifyService.notify(res.data.message, {});
            }
          },
          function (res) {
            $scope.userForm.errorMessage = res.data.message;
          },
        )
        .finally(function () {
          $scope.userForm.submitting = false;
        });
    };

    var countDown = function () {
      $scope.count--;
      if ($scope.count) {
        $scope.countDownText = $filter(
          'translate',
        )('users.signup.verification.hint.send', { count: $scope.count });
      } else {
        return;
      }
      $timeout(countDown, 1000);
    };
  },
]);
