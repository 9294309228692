app.controller('LineLiffControlller', [
  '$scope',
  'mainConfig',
  'lineService',
  'locationService',
  'imageServiceEndpoint',
  function (
    $scope,
    mainConfig,
    lineService,
    locationService,
    imageServiceEndpoint,
  ) {
    $scope.imageServiceEndpoint = imageServiceEndpoint;
    $scope.error = {};
    $scope.isNewUser = false;
    liff.init(
      function () {
        liff
          .getProfile()
          .then(function (lineUser) {
            lineService.getLineUser(lineUser.userId).then(function (result) {
              if (result.data.user) {
                $scope.isNewUser = false;
              } else {
                lineService
                  .createUser({
                    user: {
                      line_id: lineUser.userId,
                      name: lineUser.displayName,
                      profile_picture_url: lineUser.pictureUrl,
                    },
                  })
                  .then(function () {
                    $scope.isNewUser = true;
                  });
              }
            });
          })
          .catch(function (err) {
            $scope.error = err;
          });
      },
      function (err) {
        $scope.error = err;
      },
    );

    $scope.handleConfirmClick = function () {
      liff.closeWindow();
    };
  },
]);
