app.directive('productReviewEntry', [
  '$location',
  'featureService',
  'productReviewCommentService',
  'mainConfig',
  function (
    $location,
    featureService,
    productReviewCommentService,
    mainConfig,
  ) {
    return {
      templateUrl:
        '/themes/shared/product_review_comments/templates.product_review_entry.html',
      restrict: 'E',
      scope: false,
      link: function (scope) {
        if (!productReviewCommentService.productReviewEnabled) {
          return;
        }

        scope.productReviewUrl =
          '/users/' +
          mainConfig.currentUser._id +
          '/product_review_comments/orders';

        var getUncommentedOrderAmount = function () {
          var params = {
            page: 1,
            limit: 1,
          };
          // Do not show product review entry at last step of checkout flow
          if ($location.url().indexOf('/confirm') == -1) {
            productReviewCommentService
              .getUncommentedOrders(params)
              .then(function (result) {
                return (scope.completedUnratedOrderTotal =
                  result.data.data['total']);
              });
          }
        };
        getUncommentedOrderAmount();
      },
    };
  },
]);
