app.directive('variationLabelSelector', [
  '$filter',
  function ($filter) {
    return {
      restrict: 'E',
      scope: {
        index: '=',
        model: '=',
        product: '=',
        onChange: '&',
      },
      templateUrl:
        '/themes/shared/product/templates.product_variation_label_selector?v=123',
      link: function (scope) {
        scope.isUnlimitedQuantity = scope.product.unlimited_quantity;
        scope.outOfStockOrderable = scope.product.out_of_stock_orderable;

        if (!scope.isUnlimitedQuantity) {
          var productVariations = scope.product.variations;
          var hasSecondLevel = scope.product.digest_variations.length === 2;

          var stockData = productVariations.reduce(function (obj, variation) {
            var firstKey = $filter('translateModel')(
              variation.fields[0].name_translations,
            );
            if (hasSecondLevel) {
              var secondKey = $filter('translateModel')(
                variation.fields[1].name_translations,
              );
              if (!obj[firstKey]) {
                obj[firstKey] = {};
              }
              obj[firstKey][secondKey] = variation.quantity;
            } else {
              obj[firstKey] = variation.quantity;
            }
            return obj;
          }, {});

          scope.getQuantity = function (name) {
            if (hasSecondLevel) {
              if (scope.index === 0) {
                return Object.keys(stockData[name]).reduce(function (
                  total,
                  key,
                ) {
                  return (total += stockData[name][key]);
                },
                0);
              }
              return stockData[scope.model[0]][name];
            }
            return stockData[name];
          };
        }

        scope.selectVariation = function (name) {
          scope.model[scope.index] = name;
          scope.onChange();
        };

        scope.$watch(
          'product.digest_variations',
          function () {
            scope.options = scope.product.digest_variations[scope.index];
          },
          true,
        );
      },
    };
  },
]);
