app.service('moneyService', [
  'mainConfig',
  function (mainConfig) {
    function centsToDollars(cents, currencyIso) {
      var precision = getPrecision(currencyIso);
      var multiplier = Math.pow(10, precision + 1);
      var wholeNumber = Math.floor(
        (parseFloat(cents) / Math.pow(10, precision)) * multiplier,
      );
      return (Math.round(wholeNumber / 10) * 10) / multiplier;
    }

    function centsToMoney(cents, currencyIso, symbol, symbol_first) {
      return toMoney({
        cents: cents,
        currencyIso: currencyIso,
        symbol: symbol,
        symbol_first: symbol_first,
      });
    }

    function toMoney(data) {
      var baseCurrency = mainConfig.merchantData.base_currency;
      data = angular.extend(
        {
          cents: 0,
          symbol: baseCurrency.alternate_symbol,
          symbol_first: baseCurrency.symbol_first,
          currencyIso: baseCurrency.iso_code,
          withPrecision: true,
        },
        data,
      );
      if (data.dollars === undefined) {
        // Calculate dollars by cents
        data.dollars = centsToDollars(data.cents, data.currencyIso);
      }
      var labelValue = data.dollars;
      var precision = 0;
      if (data.withPrecision) {
        precision = getPrecision(data.currencyIso);
      }
      if (labelValue.toFixed !== undefined) {
        labelValue = labelValue.toFixed(precision);
      }
      labelValue = labelValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      data.label = data.symbol_first
        ? data.symbol + labelValue
        : labelValue + ' ' + data.symbol;
      return data;
    }

    function getPrecision(currencyIso) {
      if (
        currencyIso === 'TWD' ||
        currencyIso === 'JPY' ||
        currencyIso === 'VND'
      ) {
        return 0;
      } else {
        return 2;
      }
    }

    function getMultiplier(currencyIso) {
      return Math.pow(10, getPrecision(currencyIso));
    }

    return {
      centsToDollars: centsToDollars,
      centsToMoney: centsToMoney,
      getPrecision: getPrecision,
      getMultiplier: getMultiplier,
      toMoney: toMoney,
    };
  },
]);
