/**
 * Directive to search product
 **/
app.directive('slProductSearch', [
  'locationService',
  '$window',
  'mainConfig',
  'slPixelService',
  function (locationService, $window, mainConfig, slPixelService) {
    return {
      require: ['form'],
      restrict: 'C',
      scope: {},
      link: function (scope, element, attr) {
        if (element.find('input').length <= 0) {
          return;
        } // require a child input
        element.find('input,button').removeProp('disabled'); // Re-enable input when angular is ready

        element.on('click', '.js-btn-clear,.sl-search-clear', function (event) {
          event.preventDefault();
          element[0].reset();
          element.find('.sl-search-clear').hide();
        });

        element.on('keyup', 'input', function () {
          if ($(this).val().length > 0) {
            element.find('.sl-search-clear').css('display', 'inline-block');
          } else {
            element.find('.sl-search-clear').hide();
          }
        });

        element.on('submit', function (event) {
          event.preventDefault();

          var query = '/products?query=';
          var location_pathname = location.pathname;
          var locale = location_pathname.split('/')[1];
          if (mainConfig.merchantData.supported_languages.includes(locale)) {
            query = '/' + locale + query;
          }

          var keyword = element.find('input').first().val();
          if (keyword) {
            query += $window.encodeURIComponent(keyword.slice(0, 100));
            if (locationService.getQueryParams().limit) {
              query += '&limit=' + locationService.getQueryParams().limit;
            }
            if (typeof fbq === 'function') {
              // facebook tracking pixel Standard Event: search
              fbq('track', 'Search', { search_string: keyword });
            }
            slPixelService.hdPageView('searchResult', { keyword: keyword });
            $window.location.href = query + mainConfig.appendToUrl;
          }
        });
        element.on('$destroy', function () {
          element.off(); // Unbind events
        });
      },
    };
  },
]);
