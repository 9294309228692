app.directive('deliveryFormAddressOption', [
  'mainConfig',
  '$rootScope',
  '$timeout',
  '$compile',
  '$filter',
  'flash',
  'staticResourceHost',
  function (
    mainConfig,
    $rootScope,
    $timeout,
    $compile,
    $filter,
    flash,
    staticResourceHost,
  ) {
    return {
      restrict: 'A',
      scope: {
        type: '@',
        country: '@',
        deliveryTargetArea: '@',
        isTcat: '@',
        regionName: '=?',
        postcode: '=?',
        districtName: '=?',
      },
      link: function (scope, element, attrs) {
        $timeout(function () {
          var $region = element.find('.sl-delivery-address-region');
          var $district = element.find('.sl-delivery-address-district');
          var $state = element.find('.sl-delivery-address-state');
          scope.listOfRegions;
          scope.listOfDistricts;
          scope.listOfStates;

          $region
            .attr('ng-model', 'region')
            .attr(
              'ng-options',
              'region as region.regionName for region in listOfRegions',
            )
            .attr('ng-change', 'onRegionChange()')
            .attr('ng-disabled', '!listOfRegions.length');

          $compile($region)(scope);

          $district
            .attr('ng-model', 'district')
            .attr(
              'ng-options',
              'district as district.districtName for district in listOfDistricts',
            )
            .attr('ng-change', 'onDistrictChange()')
            .attr('ng-disabled', '!listOfDistricts.length');

          $compile($district)(scope);

          $state
            .attr('ng-model', 'state')
            .attr(
              'ng-options',
              'state as state.stateName for state in listOfStates',
            )
            .attr('ng-change', 'onStateChange()')
            .attr('ng-disabled', '!listOfStates.length');

          $compile($state)(scope);

          scope.onRegionChange = function (callback) {
            $('#order-delivery-city').val(scope.region.regionName);
            scope.regionName = scope.region.regionName;
            scope.district = undefined;
            $timeout(function () {
              generateListOfDistricts(scope.region.regionAbb);
              callback && callback();
            }, 0);
          };

          scope.onDistrictChange = function () {
            if (scope.country == 'TW') {
              scope.postcode = scope.district.districtName
                .replace(/[^0-9]/g, '')
                .trim();
              scope.districtName = scope.district.districtName
                .replace(/[0-9]/g, '')
                .trim();
              $('#order-delivery-postcode').val(scope.postcode);
              $('#order-delivery-address2').val(scope.districtName);
            } else {
              scope.districtName = scope.district.districtName;
              $('#order-delivery-address2').val(scope.district.districtName);
            }
            return null;
          };

          scope.onStateChange = function () {
            $('#order-delivery-state').val(scope.state.stateName);
            $('#order-delivery-state-key').val(scope.state.data.key);
            $('#order-delivery-region-code').val(scope.state.data.region_code);
            scope.stateName = scope.state.stateName;
            $rootScope.$broadcast('tax.fee.address.changed');
          };

          $('#order-delivery-postcode').keyup(function () {
            $rootScope.$broadcast('tax.fee.address.changed');
          });

          function generateListOfRegions() {
            var selectedRegions = _.filter(scope.districtRecord, function (
              record,
            ) {
              return record.type == 'region';
            });

            scope.listOfRegions = _.map(selectedRegions, function (region) {
              return {
                regionAbb: region.data.key,
                regionName: $filter('translateModel')(region.data),
              };
            });

            if (flash && (flash.data || {}).delivery_address) {
              var selectedRegion = _.find(scope.listOfRegions, function (
                region,
              ) {
                return region.regionName === flash.data.delivery_address.city;
              });

              if (selectedRegion) {
                scope.region = selectedRegion;
                generateListOfDistricts(scope.region.regionAbb);
              }
            }
            $timeout(function () {
              scope.$apply();
            }, 0);
          }

          function generateListOfDistricts(region) {
            var selectedDistricts = _.filter(scope.districtRecord, function (
              record,
            ) {
              return (
                record.type == 'district' && record.data.region_key == region
              );
            });

            scope.listOfDistricts = _.map(selectedDistricts, function (
              district,
            ) {
              return {
                data: district.data,
                districtName: $filter('translateModel')(district.data),
              };
            });

            if (flash && (flash.data || {}).delivery_address) {
              var selectedDistrict = _.find(scope.listOfDistricts, function (
                district,
              ) {
                return (
                  district.districtName ===
                  flash.data.delivery_address.address_2
                );
              });

              if (selectedDistrict) {
                $timeout(function () {
                  scope.district = selectedDistrict;
                  scope.$apply();
                }, 0);
              }
            }
          }

          function generateListOfStates() {
            scope.listOfStates = scope.districtRecord
              .filter(function (record) {
                return record.type === 'state';
              })
              .map(function (state) {
                return {
                  data: state.data,
                  stateName: $filter('translateModel')(state.data),
                };
              });
          }

          function filterDistricts(records) {
            excludedDistricts = [];
            tcatOnlyOutlyingAsLocal = [929];

            availableRecords = _.filter(records, function (record) {
              if (scope.country != 'TW' || scope.deliveryTargetArea == 'all') {
                return true;
              }

              if (
                !scope.deliveryTargetArea ||
                scope.deliveryTargetArea == 'localOnly'
              ) {
                if (
                  scope.isTcat == 'true' &&
                  tcatOnlyOutlyingAsLocal.indexOf(record.data.postcode) >= 0
                ) {
                  return true;
                } else {
                  return record.data.is_outlying_island == 'false';
                }
              } else if (scope.deliveryTargetArea == 'outlyingIslandOnly') {
                //specially handle for Taitung (exclude Pingtung for region 929)
                if (scope.isTcat == 'true') {
                  if (
                    tcatOnlyOutlyingAsLocal.indexOf(record.data.postcode) >= 0
                  ) {
                    return false;
                  } else {
                    return (
                      record.data.key == 'tt' ||
                      record.data.is_outlying_island == 'true'
                    );
                  }
                  return false;
                } else {
                  return (
                    record.data.key == 'tt' ||
                    record.data.key == 'pt' ||
                    record.data.is_outlying_island == 'true'
                  );
                }
              }
            });

            if (scope.isTcat == 'true') {
              excludedDistricts = [952, 817, 819, 882, 883, 896, 290];
            }

            var filteredRecords = _.filter(availableRecords, function (record) {
              return !_.contains(excludedDistricts, record.data.postcode);
            });
            return filteredRecords;
          }

          scope.$watch('country', function (country) {
            if (!country) {
              return;
            }

            var country_json_file = 'tw.json';
            var country_file_map = {
              HK: 'hk.json',
              JP: 'ja.json',
              TH: 'th.json',
              DE: 'de.json',
              FR: 'fr.json',
              ID: 'id.json',
              CA: 'ca.json',
              US: 'us.json',
              VN: 'vn.json',
            };

            if (Object.keys(country_file_map).indexOf(country) > -1) {
              country_json_file = country_file_map[country];
            }

            var jsonUrl =
              staticResourceHost +
              'web/v1/translations/districts_' +
              country_json_file;
            $.getJSON(jsonUrl, function (data) {
              scope.districtRecord = filterDistricts(data);
              generateListOfRegions();
              if (
                ['JP', 'TH', 'DE', 'FR', 'ID', 'CA', 'US', 'VN'].indexOf(
                  country,
                ) > -1
              ) {
                generateListOfStates();
              }
              scope.district = null;
              // restore region and district
              var localeCodes = mainConfig.localeData.supportedLocales
                .concat(mainConfig.localeData.loadedLanguage)
                .map(function (l) {
                  return l.code;
                });

              function findMatchTranslation(data, text) {
                return localeCodes.some(function (code) {
                  return data[code] && data[code].indexOf(text) !== -1;
                });
              }

              if (scope.regionName && scope.districtName) {
                var region = _.find(scope.districtRecord, function (r) {
                  return (
                    r.type === 'region' &&
                    findMatchTranslation(r.data, scope.regionName)
                  );
                });

                scope.region = _.find(scope.listOfRegions, function (r) {
                  return region.data.key === r.regionAbb;
                });

                scope.onRegionChange(function () {
                  var district = _.find(scope.districtRecord, function (d) {
                    return (
                      d.type === 'district' &&
                      d.data.region_key === scope.region.regionAbb &&
                      findMatchTranslation(d.data, scope.districtName)
                    );
                  });

                  scope.district = _.find(scope.listOfDistricts, function (d) {
                    return (
                      district.data.region_key === d.data.region_key &&
                      localeCodes.every(function (code) {
                        return district.data[code] === d.data[code];
                      })
                    );
                  });
                });
              }
            });
          });
        });
      },
    };
  },
]);
