app.directive('unescapeHtml', [
  function () {
    return {
      restrict: 'A',
      scope: {},
      link: function (scope, element) {
        element.context.innerHTML = _.unescape(element.context.innerHTML);
      },
    };
  },
]);
