app.directive("checkoutCartItems", [
  '$http'
  'checkoutService'
  'cartService'
  'trackerService'
  '$uibModal'
  '$compile'
  'slFeatureService'
  (
    $http
    checkoutService
    cartService
    trackerService
    $uibModal
    $compile
    slFeatureService
  ) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        cartService.fetchItems();

        scope.errorItems = []
        scope.$on "checkout.cart.items.reload", () ->
          element.addClass "checkout-section-loading"
          checkoutService.requestPartial('cart', 'items')
            .then (res) ->
              element.html($compile(res.data)(scope))
              scope.$broadcast "checkout.cart.addons.reload"
              setVariationIdError()
            .catch (error) ->
              console.error("Unable to load cart items")
            .finally () ->
              checkCartItemStatus()

        updateCart = _.debounce(((itemId, variationId, quantity) ->
          scope.$emit("checkout.cart.items.changing")
          if quantity == 0 #remove item
            # bundlePricing remove logic in cart
            cartService.removeItem(itemId, cartItemValidate, { pageType: 'orderEdit' })
          else # update item
            data = item:
                    id: itemId
                    variation_id: variationId
                    quantity: quantity
            # we update cart item inside requset partial
            checkoutService.requestPartial('cart', 'items', data)
              .then (res) ->
                cartService.fetchItems(() ->
                  productId = cartService.getItemById(itemId).product_id
                  cartService.tracking('UpdateItem', cartService.getTrackItem(productId, variationId), { pageType: 'orderEdit' })
                )
                scope.$emit 'checkout.cart.item.updated'
                element.html(res.data)
                cartItemValidate()
              .catch (error) ->
                scope.$emit 'checkout.cart.nothing.changed', { code: error.status }
                element.html(error.data)
              .finally () ->
                checkCartItemStatus()
        ), 500)

        checkCartItemStatus = () ->
          element.removeClass "checkout-section-loading"
          setExcluedPromotionItemsHint()
          if element.find('.cart-item').length <= 0
            scope.$emit "checkout.cart.empty"
          else
            scope.$emit "checkout.cart.content.loaded"

        cartItemValidate = () ->
          scope.errorItems = []
          cartService.validate()
            .then (data) ->
              scope.$emit("checkout.cart.items.changed", { success: true })
            .catch (response) ->
              scope.errorItems = response.items
              scope.$emit('checkout.cart.items.changed', {
                success: false, cartValidateItem: response
              })

        cartItemValidate()

        setVariationIdError = () ->
          scope.errorItems.forEach (item) ->
            if item.cart_item_ids && item.cart_item_ids.length > 0
              item.cart_item_ids.forEach (cartItemId) ->
                cartItemElement = element.find('[data-item-id="' + cartItemId + '"]')
                if cartItemElement.length
                  cartItemElement.find('.item-information, .item-price, .item-total').addClass('disable')
                  cartItemElement.find('.fa-trash-o').addClass('text-danger')
            else
              cartItemElement = element.find('[product-id=' + item.item_id + '][data-variation-id="' + item.variation_id + '"]')
              if cartItemElement.length && item.variation_id?
                cartItemElement.find('.item-information, .item-price, .item-total').addClass('disable')
                cartItemElement.find('.fa-trash-o').addClass('text-danger')

        setExcluedPromotionItemsHint = () ->
          element.find('.promotion-excluded-item').each (index, item) ->
            $compile(item)(scope)

        onQuantityChange = (event, offset) ->
          $container = angular.element(event.currentTarget).closest(".item-quantity")
          $input = $container.find(".input-item_quantity")
          quantity = parseInt($input.val(), 10)
          return if _.isNaN(quantity)
          if offset?
            quantity = Math.min(Math.max(0, quantity + offset), 99999)
            $input.val(quantity)

          $cartItem = angular.element(event.currentTarget).closest(".cart-item")
          updateCart($cartItem.attr("data-item-id"), $cartItem.attr("data-variation-id"), quantity)

          targetItem = cartService.getItemById($cartItem.attr('data-item-id'))
          slPixelActionName = if +offset > 0 then 'addToCart' else 'removeFromCart'
          cartService.sendSlPixelTracking(slPixelActionName, targetItem, Math.abs(+offset)) if quantity > 0

        onRemoveItem = (event) ->
          itemId = angular.element(event.currentTarget).closest(".cart-item").attr("data-item-id")
          cartService.removeItem(itemId, cartItemValidate, { pageType: 'orderEdit' })

        element.on "click", ".btn-quantity-decrease", ((event) -> onQuantityChange(event, -1))
        element.on "click", ".btn-quantity-increase", ((event) -> onQuantityChange(event, 1))
        element.on "input", ".input-item_quantity", ((event) -> onQuantityChange(event))

        element.on "click", ".btn-remove-cart-item", ((event) ->
          scope.currentModal = $uibModal.open({
            templateUrl: '/themes/v1/default/views/messages.confirm.modal.html',
            controller: ['$scope', '$uibModalInstance' , ($scope, $uibModalInstance) ->
              $scope.cancel = () -> $uibModalInstance.dismiss('cancel')

              $scope.submitConfirm = () -> $uibModalInstance.close()
            ]
          })

          scope.currentModal.result.then () -> onRemoveItem(event)
        )

        element.on "destroy", (() -> element.off()) # Unbind events
    }
])