app.directive('taxFeePopover', [
  'taxService',
  '$rootScope',
  '$filter',
  function (taxService, $rootScope, $filter) {
    return {
      restrict: 'E',
      templateUrl: '/themes/shared/tax/templates.popover.tax.fee.html',
      scope: {
        taxData: '=?',
        isTaxUpdate: '=?',
      },
      link: function (scope, element) {
        if (scope.taxData.total.cents <= 0) return;

        if (scope.taxData.sales_tax_fee.cents <= 0) {
          scope.taxData.sales = {};
        }
        if (scope.taxData.delivery_tax_fee.cents <= 0) {
          scope.taxData.delivery = {};
        }

        if (scope.isTaxUpdate) {
          $rootScope.$broadcast('tax.fee.isUpdated', scope.taxData);
        }

        var getTitleText = function (data) {
          data = _.isEmpty(data.sales) ? data.delivery : data.sales;
          var titleText = $filter('translate')(data.country_code);
          if (data.region || data.city) {
            titleText += ' - ' + (data.city || data.region);
          }
          return titleText;
        };

        var getContentText = function (data) {
          var contentText = '';
          var salesTaxRate = taxService.getTaxRateText(data.sales);
          var deliveryTaxRate = taxService.getTaxRateText(data.delivery);
          if (salesTaxRate) {
            var salesTaxName =
              data.sales.name || $filter('translate')('tax.sales_tax');
            contentText += salesTaxName + ' ' + salesTaxRate;
          }
          if (deliveryTaxRate) {
            if (salesTaxRate) {
              contentText += ', ';
            }
            contentText +=
              $filter('translate')('tax.delivery_tax') + ' ' + deliveryTaxRate;
          }
          return contentText;
        };

        element.on('click', function (e) {
          e.stopPropagation();
        });

        element.popover({
          animation: true,
          trigger: 'hover',
          placement: 'right',
          html: true,
          content:
            '<div class="tax-fee-block">' +
            '<p class="tax-fee-title">' +
            getTitleText(scope.taxData) +
            '</p>' +
            '<p>' +
            getContentText(scope.taxData) +
            '</p>' +
            '</div>' +
            '<p class="tax-fee-title">' +
            $filter('translate')('tax.calculation_title') +
            '</p>' +
            '<p>' +
            $filter('translate')('tax.calculation_content') +
            '</p>',
          template:
            '<div class="tax-fee-popover popover" role="tooltip">' +
            '<h3 class="popover-title"></h3>' +
            '<div class="popover-content">' +
            '</div>' +
            '</div>',
        });
      },
    };
  },
]);
