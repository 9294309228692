angular.module('sl.change-currency-filter', []).filter('changeCurrency',['mainConfig', function(mainConfig){
  return function(price) {
    if (!price ) return;
    if (!mainConfig.themeSettings.is_multicurrency_enabled ) return price;
    var oldCurrency = mainConfig.merchantData.base_currency_code;
    var newCurrency =  Currency.cookie.read() || oldCurrency;
    
    return Currency.convertPrice(price, oldCurrency, newCurrency);
  };
}]);
