app.service('imageService', [
  'imageServiceEndpoint',
  'slFeatureService',
  'mainConfig',
  function (imageServiceEndpoint, slFeatureService, mainConfig) {
    var webpHandler = function (sourceFormat) {
      return mainConfig.webpSupported
        ? 'webp?source_format=' + sourceFormat
        : sourceFormat + '?';
    };
    var getImageSize = function (size) {
      switch (size) {
        case '1000x':
          return 'transparent_xlarge';
          break;
        case '750x':
          return 'source';
          break;
        case '500x':
          return 'transparent_large';
          break;
        case '200x200f':
          return 'thumb';
          break;
        default:
          return 'original';
          break;
      }
    };

    return {
      getExternalImageUrl: function (url, option) {
        if (option === undefined) {
          option = {};
        }
        if (option.size === undefined) {
          option.size = '1000x';
        }

        if (
          slFeatureService.hasFeature('disable_image_service') ||
          !/http/.test(url)
        ) {
          return url;
        } else {
          return (
            imageServiceEndpoint +
            mainConfig.merchantId +
            '/external-' +
            md5(url) +
            '/' +
            option.size +
            '?image_url=' +
            encodeURIComponent(url)
          );
        }
      },
      getMediaImageUrl: function (media, option) {
        if (option === undefined) {
          option = {};
        }
        if (option.size === undefined) {
          option.size = '200x';
        }

        if (slFeatureService.hasFeature('disable_image_service')) {
          return media.images[getImageSize(option.size)].url;
        } else {
          if (!media) return;
          var id = media._id;
          //url.match(/(image|second)_clips\/([0-9A-z]+)\//)[2];
          var regex = new RegExp(/\.([A-z]+)\?/);
          var result = regex.exec(media.images.original.url);
          var sourceFormat = result && result[1];

          if (/png|jpg|jpeg|gif/i.test(sourceFormat)) {
            return (
              imageServiceEndpoint +
              mainConfig.merchantId +
              '/' +
              id +
              '/' +
              option.size +
              '.' +
              webpHandler(sourceFormat)
            );
          } else {
            return media.images.original.url;
          }
        }
      },
      getLargeImage: function (imgUrl) {
        if (!imgUrl) return '';
        if (!mainConfig.merchantData.is_image_service_enabled) return imgUrl;

        var fileName = _.last(imgUrl.split('/'));
        var size = fileName.split('.')[0];
        // This regex will differentiate between image url of imageService and image url of S3
        var regex = /\.(webp|png|jpg|jpeg|gif)\?/i;
        var result = regex.test(fileName);
        if (result) imgUrl = imgUrl.replace(size, '800x');
        return imgUrl;
      },
    };
  },
]);
