app.directive('redirectLabel', [
  '$interval',
  'mainConfig',
  function ($interval, mainConfig) {
    var template =
      '<span class="redirect-label">' +
      '{{ "redirect.go_home_in_seconds" | translate: { seconds: secondsLeft } }}' +
      '</span>';

    return {
      restrict: 'AE',
      template: template,
      replace: true,
      scope: {
        to: '@',
        timeout: '@',
      },
      link: function (scope) {
        var timer;
        var toPath = scope.to || mainConfig.merchantData.shop_default_home_url;

        var timer = $interval(function () {
          scope.secondsLeft -= 1;

          if (scope.secondsLeft <= 0) {
            $interval.cancel(timer);
            location.href = toPath;
          }
        }, 1000);

        scope.secondsLeft = parseInt(scope.timeout, 10) / 1000;
      },
    };
  },
]);
