app.controller('productQuickCartController', [
  '$scope',
  '$timeout',
  '$filter',
  '$uibModalInstance',
  'productService',
  'trackerService',
  'backInStockService',
  'cartService',
  'mainConfig',
  'modalTypes',
  '$rootScope',
  '$window',
  'slFeatureService',
  'buyNowService',
  'imageService',
  'fbService',
  'fastCheckoutService',
  'productSetService',
  function (
    $scope,
    $timeout,
    $filter,
    $uibModalInstance,
    productService,
    trackerService,
    backInStockService,
    cartService,
    mainConfig,
    modalTypes,
    $rootScope,
    $window,
    slFeatureService,
    buyNowService,
    imageService,
    fbService,
    fastCheckoutService,
    productSetService,
  ) {
    var currentThemeKey = mainConfig.merchantData.current_theme_key;
    //variables shared by directive product.variation-dropdown
    //TODO: Refactor
    $scope.addItemQuantity = 1;
    $scope.variationSelected = $scope.product.variations.find(function (
      variation,
    ) {
      return variation.key === $scope.variantId;
    });

    $scope.state = {
      loading: false,
      checkoutReady: false,
    };

    $scope.shouldShowMessageMeBtn = function () {
      return (
        (!$scope.state.checkoutReady ||
          !$scope.hasStock ||
          $scope.product.hide_price) &&
        !backInStockService.shouldShowBtn(
          $scope.product,
          $scope.variationSelected,
        )
      );
    };

    $timeout(function () {
      return $('.title-container.ellipsis').each(function () {
        var desiredHeight;
        desiredHeight = $(this).attr('data-max-height')
          ? parseInt($(this).attr('data-max-height'), 10)
          : 45;
        return $(this).dotdotdot({
          wrap: 'letter',
          height: desiredHeight,
          ellipsis: '...',
        });
      });
    }, 100);

    var afterProductLoaded = function () {
      $scope.state.checkoutReady = productService.validateCheckoutReady(
        $scope.product,
        $scope.variationSelected,
        cartService.getItemPrice({
          product: $scope.product,
          variation: $scope.variationSelected,
        }),
      );
      $scope.product.digest_variations = [];
      $scope.hasStock =
        $scope.product.quantity > 0 ||
        $scope.product.unlimited_quantity ||
        $scope.product.variations.length > 0 ||
        $scope.product.out_of_stock_orderable;

      if (
        !$scope.product.variations ||
        $scope.product.variations.length < 1 ||
        $scope.product.hide_price
      ) {
        trackerService.gaSendDetails($scope.product, false, $scope.products);
        $scope.products = null; //clear the related products as they are only for impressions
        var price = cartService.getItemPrice({ product: $scope.product });
        trackerService.fbSendViewContent(
          $scope.product,
          _.isObject(price) ? price.dollars : 0,
        );
      }

      var media = $scope.product.media[0];
      if (media) {
        $scope.productImageUrl =
          media.default_image_url || imageService.getMediaImageUrl(media);
        $scope.productImageAlt = media.alt_translations;
      }
    };

    function getVariationId() {
      if ($scope.product.variations.length === 0) {
        return '';
      }
      return $scope.variationSelected
        ? $scope.variationSelected.key
        : $scope.product.variations[0].key;
    }

    function shouldShowSpbModal() {
      // select bianco hidden spb btn render by backend
      var isSpbEnabled = $('.QuickCart-modal paypal-fast-checkout-btn').length;
      if (
        currentThemeKey === 'bianco' &&
        window.matchMedia('(max-width: 991px)').matches &&
        isSpbEnabled
      ) {
        return true;
      }
    }
    // watch after product-variation-dropdown  loaded
    setTimeout(function () {
      $scope.$watch('variationSelected', function checkStockQuantity() {
        if (
          mainConfig.merchantData.enabled_stock_reminder &&
          !$scope.product.unlimited_quantity &&
          $scope.product.type !== 'product_set'
        ) {
          var variation_id = getVariationId();

          productService
            .checkStock($scope.product._id, variation_id)
            .then(function (res) {
              // check if variation id changed after request
              if (variation_id !== getVariationId()) {
                return;
              }

              var data = res.data;
              var result = cartService.checkStockResult(0, data);
              $scope.quantityOfStock = result.quantityOfStock;
            });
        }
      });
    });

    $scope.isDefined = angular.isDefined;

    if (!_.isEmpty($scope.product)) {
      afterProductLoaded();
    }

    $scope.showMessageForm = function () {
      $uibModalInstance.dismiss('canceled');
      $rootScope.showMessageForm();
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('canceled');
      $rootScope.$emit('modal.close', { modalType: modalTypes.QUICK_CART });
      fbService.hideFacebookCustomerChat();
    }; // end cancel

    $scope.incQuantity = function () {
      if (parseInt($scope.addItemQuantity, 10) < 99999) {
        $scope.addItemQuantity = parseInt($scope.addItemQuantity, 10) + 1;
      }
    };

    $scope.decQuantity = function () {
      if (parseInt($scope.addItemQuantity, 10) > 1) {
        $scope.addItemQuantity = parseInt($scope.addItemQuantity, 10) - 1;
      }
    };

    $scope.addItemToCart = function (event, config) {
      var vm = this;

      var regex = /^[1-9][0-9]{0,4}$/;
      $scope.invalidQuantity = !regex.test($scope.addItemQuantity);

      if ($scope.invalidQuantity) {
        $rootScope.$broadcast('cartItemUpdateFailed');
        return;
      }

      if (!config.isSPBFastCheckout) $scope.state.loading = true;

      var variation_id;
      if ($scope.variationSelected) {
        variation_id = $scope.variationSelected.key;
      } else {
        variation_id = '';
      }

      // Turn inputbox value (ng-model=addItemQuantity) String to Number.
      vm.addItemQuantity = parseInt($scope.addItemQuantity, 10);

      var isFastCheckoutCart =
        (config.isBuyNow && fastCheckoutService.isEnableEc) ||
        (config.isSPBFastCheckout && fastCheckoutService.isSpbEnabled);
      var checkStockFn = function () {
        return productSetService.isProductSetEnabled($scope.product)
          ? productService.checkProductSetStock({
              id: $scope.product._id,
              productSetData: productSetService.generateProductSetParams(
                $scope.selectedProductSetData,
              ),
            })
          : productService.checkStock(
              $scope.product._id,
              variation_id,
              null,
              isFastCheckoutCart,
            );
      };
      checkStockFn().then(function (data) {
        var data = data.data;
        $scope.product.max_order_quantity = data.max_order_quantity;

        var result = cartService.checkStockResult(
          vm.addItemQuantity,
          data,
          isFastCheckoutCart,
        );
        $scope.reachedPurchaseLimit = result.reachedPurchaseLimit;
        $scope.notEnoughStockQty = result.notEnoughStockQty;
        $scope.quantityOfStock = result.quantityOfStock;

        // should not add item to cart
        if (
          (result.notEnoughStockQty >= 0 &&
            !$scope.product.out_of_stock_orderable) ||
          $scope.reachedPurchaseLimit
        ) {
          $rootScope.$broadcast('cartItemUpdateFailed');
          $scope.state.loading = false;
          return;
        }

        var cartItemData = {
          quantity: vm.addItemQuantity,
          variation: $scope.variationSelected,
          blacklisted_payment_option_ids:
            $scope.product.blacklisted_payment_option_ids,
          blacklisted_delivery_option_ids:
            $scope.product.blacklisted_delivery_option_ids,
        };

        if (productSetService.isProductSetEnabled($scope.product)) {
          cartItemData.type = 'product_set';
          cartItemData.productSetData = productSetService.generateProductSetParams(
            $scope.selectedProductSetData,
          );
        }

        if ($scope.product.subscription_enabled) {
          cartItemData['type'] = 'subscription_product';
        }

        if (config.isBuyNow) {
          cartItemData.isFastCheckoutCart = fastCheckoutService.isEnableEc;
        }

        if (config.isSPBFastCheckout) {
          cartItemData.isFastCheckoutCart = fastCheckoutService.isSpbEnabled;
        }

        cartService.addItem($scope.product._id, cartItemData, function (
          data,
          status,
        ) {
          var value =
            vm.addItemQuantity *
            cartService.getItemPrice({
              product: product,
              variation: $scope.variationSelected,
            }).dollars;
          if (slFeatureService.hasFeature('data_layer_info')) {
            $rootScope.$emit(
              'add.item.to.cart',
              cartService.getGaAddItemData({
                product: $scope.product,
                variant: $scope.variationSelected,
              }),
            );
          }

          trackerService.fbAddToCart(
            $scope.product,
            value,
            $scope.variationSelected,
            data.event_id,
          );
          $scope.notEnoughStock = -1; //means that there is enough stock
          if (config.isBuyNow) {
            $rootScope.$broadcast('clicked_buy_now_button');
          } else if (
            shouldShowSpbModal() ||
            buyNowService.shouldShowBuyNowModal()
          ) {
            buyNowService.openModal();
          } else if (config.isSPBFastCheckout) {
            // do nothing, handle redirect in directive.paypal.fast-checkout-button.js
          } else {
            if (!config || (config && !config.isPromotionCart)) {
              jQuery.sidr('open', 'cart-panel');
            }
            $scope.cancel();
            $scope.state.loading = false;
          }
        });
      }); // End of productService.checkStock
    };

    $scope.$on('product.gallery.selectMedia', function (e, selectedMedia) {
      $scope.productImageUrl =
        selectedMedia.default_image_url ||
        imageService.getMediaImageUrl(selectedMedia);
      $scope.productImageAlt = selectedMedia.alt_translations;
    });

    $scope.$on('biancoModalFastCheckout', function () {
      $scope.addItemToCart(undefined, { isSPBFastCheckout: true });
    });

    $scope.width = $window.innerWidth;
    angular.element($window).bind('resize', function () {
      $scope.width = $window.innerWidth;
      $scope.$digest();
    });

    $scope.isTabletWidth = function () {
      // this is strictly for the specific case where we want a button that shouldnt exist on mobile OR desktop
      return $scope.width == 768;
    };

    $scope.$on('quick_cart.product.loaded', function (event, product) {
      if (productSetService.isProductSetEnabled(product)) {
        productService.getById($scope.product._id).then(function (res) {
          $scope.product = res.data.data;
          $scope.selectedProductSetData = [];
        });
      } else {
        $scope.product = product;
      }
      afterProductLoaded();
    });

    if (productSetService.isProductSetEnabled($scope.product)) {
      $scope.$on('product_set.child_product.changed', function (
        event,
        payload,
      ) {
        $scope.hasStock = payload.hasStock;
        $scope.selectedProductSetData = payload.selectedProductSetData;
        if (mainConfig.merchantData.enabled_stock_reminder) {
          productService
            .checkProductSetStock({
              id: $scope.product._id,
              productSetData: productSetService.generateProductSetParams(
                $scope.selectedProductSetData,
              ),
            })
            .then(function (res) {
              var data = res.data;
              var result = cartService.checkStockResult(0, data);
              $scope.quantityOfStock = result.quantityOfStock;
            });
        }
      });
    }

    $scope.productService = productService;

    $scope.lowerProductPrice = function (product) {
      if (
        product.member_price &&
        product.member_price.cents > 0 &&
        mainConfig.currentUser &&
        slFeatureService.hasFeature('member_price')
      ) {
        return product.member_price.label;
      } else if (product.price_sale && product.price_sale.cents > 0) {
        return product.price_sale.label;
      }
    };

    $scope.lowerProductPriceMembersTag = function (product) {
      if (!product) {
        return '';
      }
      if (
        product.member_price &&
        product.member_price.cents > 0 &&
        mainConfig.currentUser &&
        slFeatureService.hasFeature('member_price')
      ) {
        var memberOnlyTag = $filter('translate')('product.member_only');
        var memberTierPrice =
          mainConfig.currentUser && product.product_price_tiers
            ? _.find(product.product_price_tiers, function (tier) {
                return (
                  tier.membership_tier_id ===
                  mainConfig.currentUser.membership_tier_id
                );
              })
            : null;
        if (
          slFeatureService.hasFeature('tier_member_price') &&
          !_.isEmpty((memberTierPrice || {}).member_price) &&
          memberTierPrice.member_price.cents > 0
        ) {
          memberOnlyTag = $filter('translate')('product.tier_member_only', {
            tierName: $filter('translateModel')(
              mainConfig.currentUser.membership_tier.name_translations,
            ),
          });
        }
        return memberOnlyTag;
      }

      return '';
    };

    $scope.showVariationPrice = function (variation) {
      // not call showVariationPrice until variationSelected binded
      if (variation) return productService.showVariationPrice(variation);
    };
  },
]);
