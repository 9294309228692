app.service 'tappayService', [
  '$q'
  ($q) ->
    getClientToken: (payment_data) ->
      tokenDeferObject = $q.defer()
      cardNumber = payment_data.credit_card_number
      cvc = payment_data.cvc
      expMonth = payment_data.expiry_date.split('/')[0]
      expYear = payment_data.expiry_date.split('/')[1]
      TPDirect.card.createToken cardNumber, expMonth, expYear, cvc, (result) ->
        if result.status == 0
          tokenDeferObject.resolve result
        else
          tokenDeferObject.reject result
        return
      tokenDeferObject.promise
]