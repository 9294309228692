app.controller('ApplyReturnOrderController', [
  '$scope',
  'returnOrder',
  'imageService',
  '$filter',
  'returnDeliveryOptions',
  'staticResourceHost',
  '$http',
  'productService',
  function (
    $scope,
    returnOrder,
    imageService,
    $filter,
    returnDeliveryOptions,
    staticResourceHost,
    $http,
    productService,
  ) {
    $scope.checkPolicy = false;
    $scope.returnOrder = {
      _id: returnOrder._id,
      orderNumber: returnOrder.order_number,
      recipientName: returnOrder.delivery_address.recipient_name,
      recipientPhone: returnOrder.delivery_address.recipient_phone,
      region: null,
      district: null,
      address: returnOrder.delivery_address.address_1,
      paymentMethod: null,
      bankAccount: '',
    };
    $scope.state = {
      isLoadingSubmit: false,
    };
    $scope.returnReasons = [
      {
        value: 'not_expected',
        title: $filter('translate')('return_order.return_reason.not_expected'),
      },
      {
        value: 'broke_during_delivery',
        title: $filter('translate')(
          'return_order.return_reason.broke_during_delivery',
        ),
      },
      {
        value: 'wrong_variation',
        title: $filter('translate')(
          'return_order.return_reason.wrong_variation',
        ),
      },
      {
        value: 'wrong_item',
        title: $filter('translate')('return_order.return_reason.wrong_item'),
      },
      {
        value: 'other',
        title: $filter('translate')('return_order.return_reason.other'),
      },
    ];
    $scope.returnItems = returnOrder.subtotal_items.map(function (item) {
      return Object.assign({}, item, {
        returnQuantity: 1,
        isSelectedReturn: false,
        selectedReason: $scope.returnReasons[0],
        reasonContent: '',
      });
    });
    $scope.returnPaymentOptions = [];
    $scope.returnDeliveryOptions = returnDeliveryOptions.items
      .filter(function (option) {
        if (returnOrder.order_payment.payment_gateway !== 'shopline_payment') {
          return true;
        } else {
          return option.region_type === 'custom_return' ? true : false;
        }
      })
      .map(function (option) {
        return Object.assign({}, option, {
          id: option._id,
          key: option.region_type,
          name: option.name_translations,
        });
      });

    $scope.selectedReturnDelivery = $scope.returnDeliveryOptions[0];
    $scope.deliveryAddress = returnOrder.delivery_address;
    $scope.countryCode = returnOrder.delivery_address.country_code;
    $scope.regionOptions = [];
    $scope.allDistrictOptions = [];

    $scope.returnPaymentOptions = [];
    if (
      returnOrder.order_payment.payment_gateway !== 'shopline_payment' ||
      returnOrder.order_payment.object_data.config_data[
        'shopline_payment_payment'
      ] === 'fpx'
    ) {
      $scope.returnPaymentOptions.push(
        {
          value: 'bank_transfer_return',
          key: 'bank_transfer_return',
          title: $filter('translate')(
            'return_order.return_payment.bank_transfer_return',
          ),
        },
        {
          value: 'custom_return',
          key: 'custom_return',
          title: $filter('translate')(
            'return_order.return_payment.custom_return',
          ),
        },
      );
    } else {
      $scope.returnPaymentOptions.push({
        value: 'shopline_payment',
        key: 'shopline_payment',
        title: $filter('translate')(
          'return_order.return_payment.shopline_payment',
        ),
      });
    }

    $scope.errors = {
      notSelectReturnItems: false,
    };

    $scope.$watch('selectedReturnDelivery', function (newVal) {
      if (!newVal) {
        return;
      }
      $scope.returnDeliveryForm.$setPristine();
      $scope.returnOrder.paymentMethod = $scope.returnPaymentOptions[0];
    });

    $scope.$watch('returnOrder.paymentMethod', function (newVal) {
      if (!newVal) {
        return;
      }
      $scope.returnPaymentMethodForm.$setPristine();
    });

    $scope.getImgUrl = function (item) {
      var firstMedia = item.object_data.media[0];
      return firstMedia && firstMedia.images.original.url.match(/\.([A-z]+)\?/)
        ? imageService.getMediaImageUrl(firstMedia, {
            size: '200x',
          })
        : firstMedia.images.original.url;
    };

    $scope.getProductLink = productService.getProductItemLink;
    $scope.isVariant = productService.isItemVariant;
    $scope.showPromotionLabel = productService.showPromotionLabel;

    $scope.decreaseReturnQuantity = function (returnItem) {
      if (returnItem.returnQuantity === 0) return;
      returnItem.returnQuantity--;
    };
    $scope.increaseReturnQuantity = function (returnItem) {
      if (returnItem.returnQuantity === returnItem.quantity) return;
      returnItem.returnQuantity++;
    };

    $scope.getRegionOptions = function () {
      var country = $scope.countryCode;
      if (!['TW', 'HK'].includes(country)) {
        return;
      }

      var countryFileMap = {
        HK: 'hk.json',
        TW: 'tw.json',
      };

      var jsonUrl =
        staticResourceHost +
        'web/v1/translations/districts_' +
        countryFileMap[country];
      $.getJSON(jsonUrl, function (data) {
        $scope.regionOptions = _.filter(data, function (region) {
          return region.type === 'region';
        });
        $scope.allDistrictOptions = _.filter(data, function (district) {
          return district.type === 'district';
        });
        setDefaultAddress();
      });
    };

    function setDefaultAddress() {
      if (!returnOrder.delivery_address) {
        return;
      }
      $scope.returnOrder.region = _.find($scope.regionOptions, function (
        region,
      ) {
        return (
          region.data['en'] === returnOrder.delivery_address.city ||
          region.data['zh-hant'] === returnOrder.delivery_address.city
        );
      });
      $scope.regionChange();
      $scope.returnOrder.district = _.find($scope.allDistrictOptions, function (
        district,
      ) {
        return (
          district.data.postcode.toString() ===
          returnOrder.delivery_address.postcode
        );
      });
    }

    $scope.regionChange = function () {
      $scope.returnOrder.district = null;
      $scope.districtOptions = $scope.allDistrictOptions.filter(function (
        districtData,
      ) {
        return (
          districtData.data.region_key === $scope.returnOrder.region.data.key
        );
      });
    };

    $scope.preventInvalidChar = function ($event) {
      if ([13, 69, 189, 45, 190].includes($event.keyCode)) {
        $event.preventDefault();
      }
    };

    $scope.onQuantityChange = function ($index) {
      var currentItem = $scope.returnItems[$index];
      if (currentItem.returnQuantity > currentItem.quantity) {
        currentItem.returnQuantity = currentItem.quantity;
      }
      if (currentItem.returnQuantity < 1) {
        currentItem.returnQuantity = 1;
      }
    };

    $scope.getRegionOptions();

    $scope.validateReturnItems = function () {
      var result =
        $scope.returnItems.filter(function (item) {
          return item.isSelectedReturn;
        }).length > 0;
      $scope.errors.notSelectReturnItems = !result;
      return result;
    };

    $scope.submitForm = function () {
      $scope.returnItemsForm.$setPristine();
      $scope.returnItemsForm.$setSubmitted();
      $scope.returnDeliveryForm.$setSubmitted();
      $scope.returnPaymentMethodForm.$setSubmitted();
      $scope.additionalForm.$setSubmitted();
      if (
        !$scope.validateReturnItems() ||
        $scope.returnItemsForm.$invalid ||
        $scope.returnDeliveryForm.$invalid ||
        $scope.returnPaymentMethodForm.$invalid ||
        $scope.additionalForm.$invalid
      ) {
        return;
      }

      var subtotalItems = $scope.returnItems.map(function (item) {
        if (item.isSelectedReturn && item.returnQuantity > 0) {
          var obj = {
            id: item._id,
            quantity: item.returnQuantity,
            return_reason_key: item.selectedReason.value,
          };
          if (item.selectedReason.value === 'other') {
            obj.return_remark = item.reasonContent;
          }
          return obj;
        }
      });
      translateAddressName();
      var params = {
        address: $scope.returnOrder.address,
        country: {
          name: $scope.deliveryAddress.country,
          code: $scope.countryCode,
        },
        district: $scope.returnOrder.district,
        region: $scope.returnOrder.region,
        order_id: $scope.returnOrder._id,
        returned_by: 'shop',
        recipient_name: $scope.returnOrder.recipientName,
        recipient_phone: $scope.returnOrder.recipientPhone,
        delivery_option: $scope.selectedReturnDelivery,
        item_ids: $scope.returnItems.map(function (option) {
          return option._id;
        }),
        subtotal_items: subtotalItems,
        payment_option: $scope.returnOrder.paymentMethod,
        bank_account: $scope.returnOrder.bankAccount,
      };
      $scope.state.isLoadingSubmit = true;
      $http({
        method: 'POST',
        url: '/api/return_orders',
        data: params,
      })
        .then(function (res) {
          var authorizationToken = res.data.authorization_token;
          var returnOrderId = res.data._id;
          window.location =
            '/return_orders/' +
            returnOrderId +
            '/confirm?authorizationToken=' +
            authorizationToken;
        })
        .finally(function () {
          $scope.state.isLoadingSubmit = false;
        });
    };

    function translateAddressName() {
      if ($scope.selectedReturnDelivery.region_type === 'custom_return') {
        if ($scope.returnOrder.region) {
          $scope.returnOrder.region.region_name = $filter('translateModel')(
            $scope.returnOrder.region.data,
          );
        }
        if ($scope.returnOrder.district) {
          $scope.returnOrder.district.district_name = $filter('translateModel')(
            $scope.returnOrder.district.data,
          );
        }
      }
    }

    $scope.getOptionalWording = function (translateKey) {
      var translatedWording = $filter('translate')(translateKey);
      return $scope.selectedReturnDelivery.region_type === '711_return_nopay'
        ? translatedWording +
            '(' +
            $filter('translate')('return_order.return_delivery.optional') +
            ')'
        : translatedWording;
    };
  },
]);
