app.controller('CheckoutController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  'cartService',
  'merchantService',
  '$filter',
  '$sce',
  'flash',
  '$log',
  '$timeout',
  'trackerService',
  '$location',
  'mainConfig',
  'braintreeService',
  'Analytics',
  'productService',
  'eInvoiceService',
  'sevenElevenService',
  'ezshipService',
  'familyMartService',
  'gaService',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    cartService,
    merchantService,
    $filter,
    $sce,
    flash,
    $log,
    $timeout,
    trackerService,
    $location,
    mainConfig,
    braintreeService,
    Analytics,
    productService,
    eInvoiceService,
    sevenElevenService,
    ezshipService,
    familyMartService,
    gaService,
  ) {
    $scope.error = {};
    if (flash.errorMessage) {
      $scope.error.checkout = flash.errorMessage;
    }
    var currentCallback;
    var vm = this;
    var popupWin;

    $scope.empty_cart_saw = false;
    $scope.cart_saw = false;
    $scope.Math = $window.Math;

    $scope.state = {
      checkoutLoading: false,
      finishedLoading: false,
      updatingCart: false,
      couponLoading: false,
    };
    $scope.coupon = {};
    $scope.checkoutObj = {
      total: cartService.getTotal(),
    };
    $scope.generalCarrierTypes = eInvoiceService.carrierTypes;
    $scope.quantityOfStock = [];
    $scope.save_fields = {
      phone: false,
      delivery_address: false,
      all: !$rootScope.featureService.hasBetaFeature('hide_membership'),
      marketing: false,
      customer_info: {},
    };
    $scope.save_user_fields = {
      customer_info: {},
    };
    $scope.addNewSavedInfo = {
      phone: false,
    };
    $scope.datepickerOptions = {
      showWeeks: false,
      maxDate: Date.now(),
      minDate: new Date('1899-12-31'),
    };
    $scope.couponErrorMessage = '';

    $scope.trustAsHtml = function (content) {
      return $sce.trustAsHtml(content);
    };

    //Family Mart
    $scope.isFamilyMart = function () {
      if ($scope.order && $scope.order.delivery_option) {
        return familyMartService.isFamilyMart(
          $scope.order.delivery_option.region_type,
        );
      } else {
        return false;
      }
    };

    //Seven Eleven
    $scope.isSevenEleven = function () {
      if ($scope.order && $scope.order.delivery_option) {
        return sevenElevenService.isSevenEleven(
          $scope.order.delivery_option.region_type,
        );
      } else {
        return false;
      }
    };

    // This function will be called when a user change the delivery option
    // or after a user selected map, e.g 7-11, ezship
    // @params keep_data, only for getSessionData, to keep the delivery_data remains
    $scope.onDeliveryOptionChanged = function (keep_data) {
      if (!keep_data) {
        $scope.order.delivery_data = {};
      }
      if (vm) {
        $scope.paymentMethods = merchantService.validPaymentMethods(
          vm.filteredOptions.payments,
          $scope.order.delivery_option,
        );
      }

      if (mainConfig.currentUser) {
        filterDeliveryAddresses();
      }
      $scope.order.delivery_address = {};
      setDeliveryCountryOptions();
      $scope.addNewSavedInfo['delivery_address'] = false;

      if (
        !(
          $scope.order.payment_method &&
          $scope.order.payment_method._id &&
          _.findWhere($scope.paymentMethods, {
            _id: $scope.order.payment_method._id,
          })
        )
      ) {
        $scope.order.payment_method = $scope.paymentMethods[0];
      }
      updateOrder();

      if (
        _.isEmpty($scope.deliveryOptions) ||
        _.isEmpty($scope.order.payment_method)
      ) {
        $scope.error.blacklist = true;
      } else {
        $scope.error = {}; // clear all error on delivery option changed
      }
    };

    $scope.onPaymentChange = function () {
      $scope.error = {}; // clear all error on payment changed
      updateOrder();
    };

    // checking if order custom_field's hint_translation is empty
    $scope.isEmptyObject = function (obj) {
      // this function cannot be used directly on html for unknown reason. Weird angular
      return angular.equals({}, obj);
    };

    var updateOrder = function () {
      $scope.state.updatingCart = true;
      if (!$scope.order.billing_address) {
        $scope.order.billing_address = {};
      }

      $scope.order.billing_address.country = $scope.mainConfig.requestCountry;

      cartService.updateCart($scope.order);
    };

    var isPaymentOverLimitForAllpayEcpay = function () {
      var paymentType = $scope.order.payment_method.type;
      if (paymentType == 'ecpay' || paymentType == 'allpay') {
        var paymentSubtype =
          $scope.order.payment_method.config_data[paymentType + '_payment'];
        if (paymentSubtype == 'CVS' || paymentSubtype == 'BARCODE') {
          if ($scope.checkoutObj.total.cents < 30) {
            $scope.error['payment' + paymentSubtype + 'LimitLowest'] = true;
            return true;
          } else if ($scope.checkoutObj.total.cents > 20000) {
            $scope.error['payment' + paymentSubtype + 'LimitMaximum'] = true;
            return true;
          }
        }
      }
      return false;
    };

    $scope.beginCheckout = function () {
      if ($scope.state.checkoutLoading == true) {
        return;
      }

      $scope.error = {}; // clear all errors when trying to checkout
      $scope.orderForm.$submitted = true;

      if (!$scope.orderForm.$valid || $scope.couponErrorMessage) {
        return false;
      }

      if (!$scope.order.terms) {
        $scope.error.terms = true;
        return false;
      }

      return true;
    };

    $scope.endCheckout = function () {
      $scope.state.checkoutLoading = false;
    };

    var getOrderParams = function () {
      var orderParams = angular.copy($scope.order);
      delete orderParams.delivery_option.selectedDistrict;

      orderParams.custom_fields_translations = _.reject(
        orderParams.custom_fields_translations,
        function (customField) {
          return customField.eliminate;
        },
      );
      return orderParams;
    };

    $scope.onCheckoutClick = function () {
      if (!$scope.beginCheckout()) {
        return;
      }

      if (isPaymentOverLimitForAllpayEcpay()) {
        $scope.state.checkoutLoading = false;
        return;
      }

      $scope.order.customer_email = $scope.order.customer_email.toLowerCase();
      $scope.state.checkoutLoading = true;

      angular.forEach($scope.userSettings['custom_fields'], function (
        field,
        key,
      ) {
        value = angular.copy(
          $scope.save_user_fields['customer_info'][field.field_id],
        );
        if (value != undefined && value != '') {
          $scope.save_fields['customer_info'][field.field_id] = {
            value: value,
            name_translations: field.name_translations,
          };
        }
      });
      angular.forEach($scope.userSettings['general_fields'], function (
        field,
        key,
      ) {
        value = angular.copy($scope.save_user_fields[field.type]);
        if (value != undefined && value != '') {
          if (field.type == 'birthday') {
            value = new Date(value).toDateString();
          } // sometimes date is saved wrong otherwise
          $scope.save_fields['customer_info'][field.type] = {
            value: value,
          };
        }
      });

      if ($scope.order.payment_method.type == 'braintree') {
        braintreeService.getClientToken($scope.order.payment_method._id).then(
          function (token) {
            braintreeService
              .processCard(token, $scope.order.payment_data)
              .then(function (nonce) {
                submitCheckout(
                  angular.extend({}, $scope.order, {
                    payment_data: {
                      nonce: nonce,
                    },
                  }),
                );
              });
          },
          function () {
            $scope.state.checkoutLoading = false;
            $scope.error.checkout = 'Error With Payment Gateway';
          },
        );
        return;
      }

      submitCheckout(getOrderParams());
    };

    function finalCheckoutStep(data, order) {
      // when be free checkout, it needs to check payment method is "free_checkout"
      if ($scope.checkoutObj.total.cents == 0) {
        $scope.order.payment_method.type = 'free_checkout';
      }

      var paymentType = $scope.order.payment_method.type;

      switch (paymentType) {
        case 'paypal':
        case 'allpay':
        case 'ecpay':
        case 'asiapay':
        case 'esun':
          $scope.submitForm = $scope.order.payment_method.type;
          break;
        default:
          var link = data.redirect_to;
          $window.location.href = link;
          break;
      }

      // The assignment below is for onFinishRenderFilters in orders.checkout.html
      // Trigger onFinishRenderFilters AFTER assign $scope.submitForm
      // It will work to redirect to payment page.
      $scope.createdOrder = order;

      //This is a hackaround for user membership credit
      // https://developer.paypal.com/docs/classic/paypal-payments-standard/integration-guide/Appx_websitestandard_htmlvariables/
      //Because discount_amount_cart is the deduct total amount part
      //They don't have 2nd option for us to deduct the total
      if (paymentType == 'paypal') {
        $scope.createdOrder.paypal_discount_amount_cart =
          order.order_discount.total.dollars;
        if (order.user_credits) {
          $scope.createdOrder.paypal_discount_amount_cart +=
            order.user_credits.value;
        }
      }
    }

    var submitCheckout = function (orderParams) {
      // Track GA here before api call to give GA more time to trigger
      if (
        Analytics.configuration.enhancedEcommerce &&
        !cartService.isAllRedeemGift()
      ) {
        gaService.setUserId();
        Analytics.trackCheckout(2, 'Filled Form');
        Analytics.pageView();
      }
      cartService.requestCheckout(orderParams, $scope.save_fields, function (
        order,
        message,
        data,
      ) {
        if (!order) {
          $scope.endCheckout();
          if (message) {
            $scope.error.checkout = message;
            $scope.productOutOfStockData = data;
          }
          if (data && data.ezship) {
            $scope.error.checkout = ezshipService.getEzshipErrorMessage(
              data.ezship.order_status,
            );
          }
          return;
        }

        var waitUntilAppend = false;
        try {
          if (mainConfig.merchantData.merchant_affiliates) {
            var shopComObject = _.find(
              mainConfig.merchantData.merchant_affiliates,
              function (obj) {
                return obj.affiliate_name == 'shop_com';
              },
            );
            var paymentFee = $scope.currentCart.getPaymentFee().dollars;

            if (shopComObject && shopComObject.enabled) {
              var src =
                'https://marktamerica.go2cloud.org/aff_l?offer_id=' +
                shopComObject.configuration.offer_id +
                '&adv_sub=' +
                order.order_number +
                '&amount=' +
                (
                  order.total.dollars -
                  order.delivery_option.total.dollars -
                  paymentFee
                ).toString();

              var iframe = document.createElement('iframe');
              $scope.shopComTrackingUrl = $sce.trustAsResourceUrl(src);
              iframe.setAttribute('src', $scope.shopComTrackingUrl);
              iframe.setAttribute('scrolling', 'no');
              iframe.setAttribute('frameborder', '0');
              iframe.setAttribute('width', '1');
              iframe.setAttribute('height', '1');
              document.body.appendChild(iframe);

              waitUntilAppend = true;
            }
          }
        } catch (err) {
        } finally {
          if (waitUntilAppend) {
            $timeout(function () {
              finalCheckoutStep(data, order);
            }, 5000);
          } else {
            finalCheckoutStep(data, order);
          }
        }
      });
    };

    //Seems didn't trigger even selected map
    $window.handleCallback = function (result, win) {
      if (result.hasOwnProperty('storeid')) {
        //seven delivery
        if (!$scope.order.delivery_data) {
          $scope.order.delivery_data = {};
        }

        $scope.order.delivery_data.location_code = result.storeid;
        $scope.order.delivery_data.location_name = result.storename;
        $scope.order.delivery_data.store_address = result.storeaddress;
        $scope.$apply();
      } else if (result.hasOwnProperty('cvsspot')) {
        //family mart
        if (!$scope.order.delivery_data) {
          $scope.order.delivery_data = {};
        }

        $scope.order.delivery_data.location_code = result.cvsspot;
        $scope.order.delivery_data.location_name = result.name;
        $scope.order.delivery_data.store_address = result.addr;
        $scope.$apply();
      } else {
        //other delivery
        $scope.order.delivery_data.location_name = result.stName;
        $scope.order.delivery_data.location_code =
          result.stCate + result.stCode;
        $scope.$apply();
      }
    };

    var setDeliveryAddressCountry = function () {
      //set country based on delivery option
      if ('local' == $scope.order.delivery_option.region_type) {
        if (!$scope.order.delivery_address) {
          $scope.order.delivery_address = {};
        }
        $scope.order.delivery_address.country =
          $scope.mainConfig.merchantData.base_country_code;
      } else if (
        'international' == $scope.order.delivery_option.region_type ||
        'custom' == $scope.order.delivery_option.region_type
      ) {
        if (!$scope.order.delivery_address) {
          $scope.order.delivery_address = {};
        }
        if (
          $scope.order.delivery_option.accepted_countries == null ||
          $scope.order.delivery_option.accepted_countries.length == 0 ||
          $scope.order.delivery_option.accepted_countries.indexOf(
            $scope.mainConfig.requestCountry,
          ) >= 0
        ) {
          $scope.order.delivery_address.country =
            $scope.mainConfig.requestCountry;
        } else {
          $scope.order.delivery_address.country = $scope.countries[0].code;
        }
      }
    };

    var filterDeliveryAddresses = function () {
      $scope.filtered_delivery_addresses = angular.copy(
        mainConfig.currentUser.delivery_addresses,
      );
      var deliveryOption = $scope.order.delivery_option;
      if (deliveryOption.region_type == 'local') {
        $scope.filtered_delivery_addresses = _.filter(
          $scope.filtered_delivery_addresses,
          function (address) {
            return (
              address.country ==
              $scope.mainConfig.merchantData.base_country_code
            );
          },
        );
      }

      // Filter out addresses by whitelisted countries of delivery option
      if (deliveryOption.requires_customer_address) {
        $scope.filtered_delivery_addresses = _.filter(
          $scope.filtered_delivery_addresses,
          function (address) {
            return (
              deliveryOption.accepted_countries.length == 0 ||
              _.contains(deliveryOption.accepted_countries, address.country)
            );
          },
        );
      }
    };

    var setCustomUserData = function () {
      angular.forEach($scope.userSettings.general_fields, function (
        value,
        key,
      ) {
        if (mainConfig.currentUser[value.type]) {
          $scope.save_user_fields[value.type] =
            mainConfig.currentUser[value.type];
        }
      });

      if (mainConfig.currentUser.custom_data) {
        angular.forEach($scope.userSettings.custom_fields, function (
          value,
          key,
        ) {
          if (
            value.options.order.include == 'true' &&
            mainConfig.currentUser.custom_data[value.field_id]
          ) {
            $scope.save_user_fields.customer_info[value.field_id] =
              mainConfig.currentUser.custom_data[value.field_id];
          }
        });
      }
    };

    var checkoutDisplay = function () {
      if ($rootScope.currentCart.isCartEmpty()) {
        $scope.empty_cart_saw = true;
        $scope.cart_saw = false;
      } else {
        $scope.empty_cart_saw = false;
        $scope.cart_saw = true;
      }
    };

    var loadSessionData = function () {
      if (!angular.isUndefined(flash_data)) {
        result = JSON.parse(flash_data);
        if (result) {
          if (!$scope.order.delivery_data) {
            $scope.order.delivery_data = {};
          }

          if (result.storeid) {
            $scope.order.delivery_data.location_code = result.storeid;
            $scope.order.delivery_data.location_name = result.storename;
            $scope.order.delivery_data.store_address = result.storeaddress;
          } else if (result.cvsspot) {
            $scope.order.delivery_data.location_code = result.cvsspot;
            $scope.order.delivery_data.location_name = result.name;
            $scope.order.delivery_data.store_address = result.addr;
          } else {
            $scope.order.delivery_data.location_name = result.stName;
            $scope.order.delivery_data.location_code =
              result.stCate + result.stCode;
          }

          angular.forEach($scope.deliveryOptions, function (deliveryOption) {
            if (result.delivery_option == deliveryOption._id) {
              $scope.order.delivery_option = deliveryOption;
              $scope.onDeliveryOptionChanged(true);
            }
          });

          angular.forEach($scope.paymentMethods, function (paymentMethod) {
            if (result.payment_method == paymentMethod._id) {
              $scope.order.payment_method = paymentMethod;
            }
          });

          if (result.save_fields_phone) {
            $scope.save_fields.phone = true;
          }
          $scope.order.delivery_data.recipient_name = result.recipient_name;
          $scope.order.customer_phone = result.customer_phone;
          if ($scope.currentUser) {
            $scope.currentUser.phones[0] = result.customer_phone;
          }
          $scope.order.customer_email = result.customer_email;
          $scope.order.order_remarks = result.order_remarks;

          if (result.save_user_fields) {
            $scope.save_user_fields = result.save_user_fields;
          }

          // Get back the coupon codes to reapply, wait for cart service to be ready
          $scope.coupon_reapply = [];
          if (result.coupon_codes && result.coupon_codes.length > 0) {
            for (var i = 0; i < result.coupon_codes.length; i++) {
              $scope.coupon_reapply.push(result.coupon_codes[i]);
            }
          }
        }
      }
    };

    var setDeliveryCountryOptions = function () {
      if ($scope.order.delivery_option != null) {
        if (
          $scope.order.delivery_option.accepted_countries != null &&
          $scope.order.delivery_option.accepted_countries.length > 0
        ) {
          $scope.countries = vm.all_countries.filter(function (country) {
            return (
              $scope.order.delivery_option.accepted_countries.indexOf(
                country.code,
              ) >= 0
            );
          });
        } else {
          $scope.countries = vm.all_countries;
        }
        setDeliveryAddressCountry();
      }
    };

    var filterDeliveryPaymentByBlacklist = function () {
      delete $scope.error.blacklist;
      vm.filteredOptions = {
        deliveries: angular.copy(vm.deliveryOptions),
        payments: angular.copy(vm.payments),
      };
      merchantService.removeBlacklistedCheckoutOptions(
        vm.filteredOptions.deliveries,
        vm.filteredOptions.payments,
        cartService.getItems(),
      );
      $scope.deliveryOptions = vm.filteredOptions.deliveries;

      // preserve existing option if still valid
      if (
        !(
          $scope.order.delivery_option &&
          $scope.order.delivery_option._id &&
          _.findWhere($scope.deliveryOptions, {
            _id: $scope.order.delivery_option._id,
          })
        )
      ) {
        $scope.order.delivery_option = $scope.deliveryOptions[0];
      }

      // Previously selected delivery option may be changed due to blacklist, we update the available payment methods after that
      $scope.paymentMethods = merchantService.validPaymentMethods(
        vm.filteredOptions.payments,
        $scope.order.delivery_option,
      );

      if (
        !(
          $scope.order.payment_method &&
          $scope.order.payment_method._id &&
          _.findWhere($scope.paymentMethods, {
            _id: $scope.order.payment_method._id,
          })
        )
      ) {
        $scope.order.payment_method = $scope.paymentMethods[0];
      }

      if (
        _.isEmpty($scope.deliveryOptions) ||
        _.isEmpty($scope.order.payment_method)
      ) {
        $scope.error.blacklist = true;
      }
    };

    //This function should be on load when the page loaded
    var loadCheckoutOptions = function () {
      vm.all_countries = [];
      $http({
        method: 'GET',
        url: '/api/merchants/' + merchantService.merchantId + '/countries',
      }).then(function (res) {
        vm.all_countries = res.data;
        setDeliveryCountryOptions();
      });

      var data = $window.checkoutOpts;
      var deliveryOptions = data.delivery_options;
      var paymentMethods = data.payment_methods;

      var orderSettings = data.order_settings;
      var userSettings = data.user_settings;

      // $scope.autoApplyCoupons = data.auto_apply_coupons;

      $scope.order = {
        seller_id: merchantService.merchantId,
        delivery_option: deliveryOptions[0],
        // payment_method:paymentMethods[0],
        delivery_data: {},
        custom_fields_translations: [],
        coupons: [],
        payment_data: {},
        invoice: {
          invoice_type: 0,
          carrier_type: $scope.generalCarrierTypes[0].value,
        },
      };
      if (orderSettings.invoice && orderSettings.invoice.n_p_o_b_a_n) {
        $scope.order.invoice.n_p_o_b_a_n =
          orderSettings.invoice.n_p_o_b_a_n.code;
      }
      angular.forEach(orderSettings.custom_fields, function (value, key) {
        field = {};
        field.field_translations = value.name_translations;
        //ignore notes
        if (value.type == 'textarea') {
          field.eliminate = true;
        }
        $scope.order.custom_fields_translations.push(field);
      });

      // $scope.order.customer_email = userService.getEmail();  # not yet implemented

      $scope.orderSettings = orderSettings;
      $scope.userSettings = userSettings;

      merchantService.sanitizeCheckoutOptions(deliveryOptions, paymentMethods);
      // all possible options before filtering
      vm.deliveryOptions = deliveryOptions;
      vm.payments = paymentMethods;
    };

    $scope.onStripeChargeCreated = function (charge) {
      $scope.order.payment_data.token = charge.id;
      $scope.state.checkoutLoading = true;
      submitCheckout(getOrderParams());
    };

    //Listening event
    $scope.$on('ngRepeatFinished', function (ngRepeatFinishedEvent) {
      if ($scope.submitForm != undefined) {
        $('#' + $scope.submitForm + '_form').submit();
      }
    });

    $scope.$on('cartService.fetch', function (event, data) {
      checkoutDisplay();
      $scope.checkoutObj.total = cartService.getTotal();
      $scope.checkoutObj.currency = merchantService.getCurrency();
      $scope.state.updatingCart = false;
      cartService
        .validate()
        .then(
          function (data, status) {},
          function (response) {
            $scope.error.cart = response.message;
            $scope.productOutOfStockData = response.items;
          },
        )
        .finally(function () {
          // Always execute this on both error and success
          $scope.state.finishedLoading = true;
          filterDeliveryPaymentByBlacklist();
          // this function has to be executed after all delivery options and payments are filtered
          $http({
            method: 'GET',
            url: '/api/merchants/' + merchantService.merchantId + '/countries',
          }).then(function (res) {
            vm.all_countries = res.data;
            setDeliveryCountryOptions();
          });
          loadSessionData();

          updateOrder($scope.order);
          if (mainConfig.currentUser) {
            if ($scope.order.delivery_option != null) {
              filterDeliveryAddresses();
            }
            setCustomUserData();
          }
        });
    });

    $scope.$on('cartService.update', function (event, data) {
      checkoutDisplay();
      $scope.checkoutObj.total = cartService.getTotal();

      if ($scope.checkoutObj.total.cents == 0) {
        $scope.order.payment_method.type = 'free_checkout';
      } else {
        if ($scope.order.payment_method.type == 'free_checkout') {
          $scope.order.payment_method = $scope.paymentMethods[0];
        }
      }
      $scope.state.updatingCart = false;
    });

    $scope.$on('cartService.error', function (event, error) {
      checkoutDisplay();
      $scope.state.updatingCart = false;
    });

    $scope.$on('cartItemsUpdated', function (event, cartItems) {
      filterDeliveryPaymentByBlacklist();
      $scope.error = {}; // clear all error on items updated
      updateOrder();
    });

    $scope.$watch('order.terms', function (newValue) {
      if (newValue == true) {
        delete $scope.error.terms;
      }
    });

    loadCheckoutOptions();
  },
]);
