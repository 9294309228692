app.service('lineService', [
  '$http',
  function ($http) {
    return {
      getLineUser: function (lineId) {
        return $http.get('/oauth/line/users/' + lineId);
      },
      createUser: function (payload) {
        return $http.post('/oauth/line/users', payload);
      },
    };
  },
]);
