app.controller('PasswordsEditController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  '$location',
  '$filter',
  'resource',
  'mainConfig',
  'locationService',
  'RecaptchaService',
  '$cookies',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    $location,
    $filter,
    resource,
    mainConfig,
    locationService,
    RecaptchaService,
    $cookies,
  ) {
    $scope.resource = resource.data;

    $scope.resource.owner_id = mainConfig.merchantData._id;
    $scope.resource.reset_password_token = locationService.getQueryParams().reset_password_token;
    $scope.is_reset = locationService.getQueryParams().reset;

    $scope.$watch('resource.password', function (newVal, oldVal) {
      if (newVal != oldVal) {
        $scope.userForm.password.$setValidity('general', true);
      }
    });
    $scope.$watch('resource.password_confirmation', function (newVal, oldVal) {
      if (newVal != oldVal) {
        $scope.userForm.password_confirmation.$setValidity('general', true);
      }
      if (
        $scope.resource.password != $scope.resource.password_confirmation &&
        $scope.userForm.submitted
      ) {
        $scope.userForm['password_confirmation']['validationMessage'] = $filter(
          'translate',
        )('form.validation.confirmation');
        $scope.userForm['password_confirmation'].$setValidity('general', false);
      }
    });

    $scope.formSubmit = function () {
      if ($scope.userForm.submitting == true) {
        return;
      }
      $scope.userForm.submitted = true;
      $scope.userForm.submitting = true;
      $scope.userForm.errorMessages = null;

      if ($scope.resource.password != $scope.resource.password_confirmation) {
        $scope.userForm['password_confirmation']['validationMessage'] = $filter(
          'translate',
        )('form.validation.confirmation');
        $scope.userForm.password_confirmation.$setValidity('general', false);
      } else {
        $scope.userForm.password_confirmation.$setValidity('general', true);
      }

      if ($scope.userForm.$invalid) {
        $scope.userForm.submitting = false;
        return;
      }

      var method = 'PUT';
      var path = '/api/users/password';

      //console.log("submit form");
      $http({
        method: method,
        url: path,
        data: {
          user: $scope.resource,
          recaptchable: true,
        },
      })
        .then(function (res) {
          if ($scope.is_reset) {
            var redirect_to = res.data.redirect_to;
            $window.location.href = redirect_to;
          } else {
            var fbc = $cookies.get('_fbc') || '';
            var fbp = $cookies.get('_fbp') || '';
            $window.location.href =
              '/sign_up_confirmation?fbc=' + fbc + '&fbp=' + fbp;
          }
        })
        .catch(function (res) {
          // console.log('error:'+JSON.stringify(data.data));
          // var fieldName = $filter('translate')('users.fields.email');

          // $scope.userForm["email"]["validationMessage"] = fieldName + ' '+ data.data.email[0];
          // $scope.userForm.email.$setValidity('general',false);
          $scope.userForm.errorMessages = res.data.error_messages;
          $scope.userForm.submitting = false;
          //console.log("test"+JSON.stringify($scope.userForm["email"]));
        })
        .finally(function () {
          RecaptchaService.reset('edit-pw-recaptcha');
        });
    };
  },
]);
