/**
 * Directive to sort products
 **/
app.directive('slProductsListSettings', [
  '$location',
  '$timeout',
  function ($location, $timeout) {
    return {
      restrict: 'C',
      link: function (scope, element, attrs) {
        var currentQueryParams = $location.search();
        var $sorting = element.find('.sl-products-sorting');
        var $limit = element.find('.sl-products-limit');
        var $filter = element.find('.sl-products-filter');

        $sorting.prop('disabled', false);
        $limit.prop('disabled', false);
        $filter.prop('disabled', false);

        scope.sortBy =
          $sorting.find('option:selected').val() || 'created_at:desc';
        scope.limit = $limit.find('option:selected').val();
        scope.filter = $filter.find('option:selected').val();

        var appendNewQueryToUrl = function (searchParams) {
          Object.assign(currentQueryParams, searchParams);
          $location.search(currentQueryParams);
          window.location.href = $location.absUrl();
        };

        var updateSearch = function (changes, ignoreList) {
          var params = {};
          _.each(
            window.location.search.replace(/.*\?/, '').split('&'),
            function (chunk) {
              var parts = chunk.split('=');
              if (parts.length < 2) return;
              if (ignoreList && ignoreList.indexOf(parts[0]) > -1) return;
              params[parts[0]] = parts[1];
            },
          );
          _.extend(params, changes);
          window.location.search =
            '?' +
            _.chain(params)
              .map(function (v, k) {
                return k + '=' + v;
              })
              .value()
              .join('&');
        };

        element.on('change', '.sl-products-sorting', function () {
          $timeout(function () {
            scope.sortBy = $sorting.find('option:selected').val();
          }, 0);
        });

        element.on('change', '.sl-products-limit', function () {
          $timeout(function () {
            scope.limit = $limit.find('option:selected').val();
          }, 0);
        });

        element.on('change', '.sl-products-filter', function () {
          $timeout(function () {
            scope.filter = $filter.find('option:selected').val();
          }, 0);
        });

        scope.$watch('limit', function (newValue, oldValue) {
          if (!oldValue || oldValue === newValue) {
            return;
          }
          appendNewQueryToUrl({ limit: scope.limit });
        });

        scope.$watch('filter', function (newValue, oldValue) {
          if (!oldValue || oldValue === newValue) {
            return;
          }
          updateSearch({ filter: $filter.find('option:selected').val() }, [
            'page',
          ]);
        });

        scope.$watch('sortBy', function (newValue, oldValue) {
          if (!oldValue || oldValue === newValue) {
            return;
          }
          var searchParams = {};
          switch ($sorting.find('option:selected').val()) {
            case 'created_at_asc':
              searchParams.sort_by = 'created_at';
              searchParams.order_by = 'asc';
              break;
            case 'price_desc':
              searchParams.sort_by = 'lowest_price';
              searchParams.order_by = 'desc';
              break;
            case 'price_asc':
              searchParams.sort_by = 'lowest_price';
              searchParams.order_by = 'asc';
              break;
            case 'quantity_sold_desc':
              searchParams.sort_by = 'quantity_sold';
              searchParams.order_by = 'desc';
              break;
            case 'created_at_desc':
            default:
              searchParams.sort_by = 'created_at';
              searchParams.order_by = 'desc';
              break;
          }
          appendNewQueryToUrl(searchParams);
        });

        element.on('$destroy', function () {
          element.off(); // Unbind events
        });
      },
    };
  },
]);
