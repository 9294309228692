(function () {
'use strict';

angular.module('tw.translate-model', ['pascalprecht.translate'])
  .filter('translateModel', ["$translate", "mainConfig", function($translate, mainConfig) {
    return function(input, lang) {
      if (!input || angular.isUndefined(input) || _.isEmpty(input)) { return ""; }
      if (typeof input === 'string') { return input; }
      if (lang === undefined) { lang = $translate.use(); }

      var supported_languages = mainConfig.merchantData.supported_languages

      // Required language present in given input hash
      if (_.contains(supported_languages, lang) && !_.isEmpty(input[lang])) { return input[lang]; }

      // Language code that need special handling
      var available_fallbacks = []
      switch (lang) {
        case 'zh-cn':
          available_fallbacks = _.intersection(_.keys(input), supported_languages, ['zh-cn', 'zh-hant', 'zh-hk', 'zh-tw', 'en'])
          break;
        case 'zh-hant':
        case 'zh-hk':
        case 'zh-tw':
          available_fallbacks = _.intersection(_.keys(input), supported_languages, ['zh-tw', 'zh-hant', 'zh-hk', 'zh-cn', 'en'])
          break;
        default:
          available_fallbacks = _.intersection(_.keys(input), supported_languages, ['en']);
      }

      function valueFromfallbackLanguages() {
        return _.chain(input)
          .pick(function(v, k) { return _.contains(available_fallbacks, k); })
          .values()
          .reject(function(val) { return _.isEmpty(val); })
          .first()
          .value();
      }

      function valueFromExistingKeys() {
        return _.chain(input)
          .values()
          .reject(function(val) { return _.isEmpty(val); })
          .first()
          .value();
      }

      return valueFromfallbackLanguages() || valueFromExistingKeys() || ""
    };
}])
  .directive("translateModel",['$parse','$filter', function ($parse,$filter) {
    return {
      restrict: 'A',
      scope: {
          translateModel: "@", // Gets the string contents of the `translate-model` attribute
          translateModelLanguage: "@", // Gets the string contents of the `translate-model-language` attribute
      },link: function (scope, element, attrs) {
        // scope.currentLanguage = "en";
        // var model = element.attr("ng-model");
        // console.log("translateModel:"+scope.translateModel);
        // console.log("model:"+model);
        // //console.log("model:"+scope.$parent.$eval(model));
        // scope.$parent.$watch(scope.translateModel,function(value){
       
          
        // });
        
        // scope.$parent.$watch(scope.translateModelLanguage,function(value){
        //   console.log("watch translateModelLanguage:"+value);
        //   if (angular.isUndefined(value)) {return};
        //   scope.currentLanguage = value;
        // });
      }
    };
}]);

}) ();