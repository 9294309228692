app.service('eInvoiceService', [
  function () {
    var carrierTypes = [
      {
        value: 0,
        text: 'orders.invoices.carrier_type.member',
      },
      {
        value: 1,
        text: 'orders.invoices.carrier_type.mobile_barcode',
      },
      {
        value: 2,
        text: 'orders.invoices.carrier_type.npc_barcode',
      },
    ];

    return {
      carrierTypes: carrierTypes,
    };
  },
]);
