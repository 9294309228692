import '../../../assets/javascripts/ng.lib/tw.translate-model';
import '../../../assets/javascripts/ng.lib/tw.useragent';
import '../../../assets/javascripts/ng.lib/tw.lightbox';
import '../../../assets/javascripts/ng.lib/tw.filepicker';
import '../../../assets/javascripts/ng.lib/tw.dynamic-name';
import '../../../assets/javascripts/ng.lib/tw.control-group';
import '../../../assets/javascripts/ng.lib/sl.hide-field-by-country';
import '../../../assets/javascripts/ng.lib/sl.change-currency-filter';
import '../../../assets/javascripts/ng.lib/sl.promotion-title-filter';
import '../../../assets/javascripts/ng.lib/tw.ladda';
import '../shop/app.js';
import slFeatureService from '../lib/sl-feature';

window.app.service('slFeatureService', ['FEATURES_LIMIT', slFeatureService]);

var importAll = function (requireContext) {
  requireContext.keys().forEach(requireContext);
};

importAll(require.context('../shop/modules', false));
