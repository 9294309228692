app.directive('promotionCart', [
  '$rootScope',
  '$window',
  '$timeout',
  '$cookies',
  '$q',
  '$filter',
  'mainConfig',
  'cartService',
  'productService',
  'imageService',
  'promotion',
  'productIds',
  'modalTypes',
  'Analytics',
  'trackerService',
  'gaService',
  'fbService',
  'moneyService',
  'fastCheckoutService',
  'slFeatureService',
  'productSetService',
  function (
    $rootScope,
    $window,
    $timeout,
    $cookies,
    $q,
    $filter,
    mainConfig,
    cartService,
    productService,
    imageService,
    promotion,
    productIds,
    modalTypes,
    Analytics,
    trackerService,
    gaService,
    fbService,
    moneyService,
    fastCheckoutService,
    slFeatureService,
    productSetService,
  ) {
    return {
      restrict: 'A',
      link: function (scope) {
        var scrollbarInstance;
        var registeredEvents = [];
        var resizeEventHandler = _.throttle(function () {
          addEllipsisToTitle();
        }, 500);

        scope.state = {
          isPanelActived: false,
          isPreviousPanelActived: false, // save previous status for close event
          isPreviousPanelExpanded: false, // save previous status for close event
          isPanelExpanded: false,
          isCheckoutButtonLoading: false,
        };
        scope.promotionCartItems = [];
        scope.getItemPrice = cartService.getItemPrice;

        scope.togglePanel = function () {
          scope.state.isPanelExpanded = !scope.state.isPanelExpanded;
          $cookies.isPromotionCartPanelExpanded = scope.state.isPanelExpanded;
          fbService.setFacebookCustomerChatPosition();
          if (scope.state.isPanelExpanded) fbService.hideFacebookCustomerChat();
          else fbService.showFacebookCustomerChat();
        };

        scope.removeItemFromCart = function ($event, cartItemId) {
          $event.stopPropagation();
          cartService.removeItem(cartItemId);
        };

        scope.openProductLink = function ($event, productId) {
          window.open('/products/' + productId);
        };

        scope.getBundleGroupClass = function (
          productId,
          redWhitelistedProductIds,
        ) {
          return (
            'bundle-group-label ' +
            (redWhitelistedProductIds.includes(productId)
              ? 'bundle-group-red'
              : 'bundle-group-green')
          );
        };

        scope.getBundleGroupLabel = function (
          productId,
          redWhitelistedProductIds,
        ) {
          return redWhitelistedProductIds.includes(productId) ? 'A' : 'B';
        };

        scope.onCheckoutClicked = function ($event) {
          $event.stopPropagation();
          var tasks = [];
          if (!cartService.isAllRedeemGift()) {
            if (Analytics.configuration.enhancedEcommerce) {
              var cartItems = $rootScope.currentCart.getItems();
              gaService.setUserId();
              cartItems.forEach(function (item) {
                Analytics.addProduct(
                  productService.getSku(
                    item.product_id,
                    item.sku,
                    item.variation,
                  ),
                  $filter('translateModel')(item.product.title_translations),
                  '',
                  '',
                  productService.getVariationName(item.variation),
                  cartService.getItemPrice(item).dollars.toString(),
                  item.quantity,
                  '',
                  '0',
                );
              });
              Analytics.trackCheckout(1, 'Checkout');
              Analytics.trackEvent(
                'UX',
                'checkout',
                'Checkout',
                undefined,
                true,
              );
            }

            trackerService.fbInitiateCheckout(
              cartItems,
              $rootScope.currentCart.getSubtotal(),
            );
          }
          scope.state.isCheckoutButtonLoading = true;
          scope.promotionCartItems.forEach(function (item) {
            // remove all invalid items in cart
            if (item.quantity === 0)
              tasks.push(cartService.removeItem(item._id));
          });
          $q.all(tasks).then(function () {
            // fast checkout
            if (fastCheckoutService.isEnableEc) {
              if (Analytics.configuration.enhancedEcommerce) {
                Analytics.trackCheckout(2, 'InitateCheckout');
                Analytics.trackEvent(
                  'UX',
                  'initate_checkout',
                  'InitateCheckout',
                  undefined,
                  true,
                );
              }
              $window.location = '/fast_checkout';
              return;
            }

            if (scope.isInIframe()) {
              var win = window.open(mainConfig.checkoutLandingPath, '_blank');
              win.focus();
            } else {
              $window.location = mainConfig.checkoutLandingPath;
            }
          });
        };

        scope.isInIframe = function () {
          try {
            return window.self !== window.top;
          } catch (e) {
            return true;
          }
        };

        scope.getImgSrc = function (item, size) {
          return imageService.getMediaImageUrl(
            item.variation && item.variation.media
              ? item.variation.media
              : item.product.cover_media,
            { size: size },
          );
        };

        scope.getCartItemsQuantity = function () {
          return scope.promotionCartItems.reduce(function (acc, cur) {
            return acc + (parseInt(cur.quantity, 10) || 0);
          }, 0);
        };

        scope.getCartItemsQuantityOfBundleGroup = function (
          whitelistedProductIds,
        ) {
          return scope.promotionCartItems.reduce(function (prev, item) {
            if (whitelistedProductIds.includes(item.product_id)) {
              return prev + (parseInt(item.quantity, 10) || 0);
            } else {
              return prev;
            }
          }, 0);
        };

        scope.updateQuantity = function (cartItem, newValue, oldValue) {
          var isUpdatedByButtonClick = typeof newValue === 'number';
          var newQuantity = parseInt(newValue, 10);
          var originQuantity =
            parseInt(oldValue, 10) || cartItem.originQuantity;

          if (newValue.length === 0) return;
          if (newQuantity < 1) {
            // when clicking minus btn, remove it if there's only one left item in that product
            cartService.removeItem(cartItem._id);
            return;
          }

          var checkStockFn = function () {
            return cartItem.type === 'product_set' &&
              slFeatureService.hasFeature('product_set')
              ? productService.checkProductSetStock({
                  id: cartItem.product_id,
                  productSetData: cartItem.item_data.selected_child_products,
                })
              : productService.checkStock(
                  cartItem.product_id,
                  cartItem.variation_id,
                );
          };

          checkStockFn().then(function (res) {
            var stock = res.data;
            var quantityDiff = isUpdatedByButtonClick
              ? newQuantity - cartItem.quantity
              : newQuantity - originQuantity;
            var result = cartService.checkStockResult(quantityDiff, stock);

            cartItem.purchaseLimit = stock.max_order_quantity;

            if (result.notEnoughStockQty >= 0 || result.reachedPurchaseLimit) {
              // fill original value into the input
              cartItem.quantity = cartItem.originQuantity;
            }

            // show reached limit warning with fade-in/out animation
            if (result.reachedPurchaseLimit) {
              cartItem.isReachedPurchaseLimit = true;
              $timeout(function () {
                cartItem.isReachedPurchaseLimit = false;
              }, 3000);
              return;
            }

            // show out of stock warning with fade-in/out animation
            if (result.notEnoughStockQty >= 0) {
              cartItem.isOutOfStock = true;
              $timeout(function () {
                cartItem.isOutOfStock = false;
              }, 3000);
              return;
            }

            // in the end, update item's quantity if there's nothing error above
            cartService.updateItem(
              cartItem._id,
              cartItem.variation_id,
              newQuantity,
            );
            cartItem.originQuantity = newQuantity;

            var slPixelActionName =
              +quantityDiff > 0 ? 'addToCart' : 'removeFromCart';
            cartService.sendSlPixelTracking(
              slPixelActionName,
              cartItem,
              Math.abs(+quantityDiff),
            );
          });
        };

        scope.getBundleGroupGapReminder = function (group) {
          if (!scope.untriggeredPromotion) return;
          var conditionGap = _.find(
            scope.untriggeredPromotion.condition_gaps,
            function (conditionGap) {
              return conditionGap.type === group;
            },
          );
          return conditionGap && conditionGap.gap_value > 0
            ? $filter('translate')('cart.promotion_reminder.group_gap', {
                gap: conditionGap.gap_value,
              })
            : '';
        };

        scope.getChildVariationShorthand = function (variation) {
          return variation.fields
            .map(function (field) {
              return $filter('translateModel')(field.name_translations);
            })
            .join(', ');
        };

        scope.tagIcon = "<i class='fa fa-tag'></i> ";

        function getConditionGaps(promotion, cartData) {
          var conditionGaps = promotion.conditions.map(function (condition) {
            var conditionGap = { type: condition.type };
            var selectedValue = getSelectedGapValue(cartData, condition);
            if (condition.min_price) {
              conditionGap.gap_type = 'amount';
              conditionGap.selected_value = moneyService.toMoney({
                cents: selectedValue,
              });
              conditionGap.gap_value = moneyService.toMoney({
                cents: condition.min_price.cents - selectedValue,
              });
              conditionGap.hasFulfillment =
                condition.min_price.cents <= selectedValue;
            } else if (condition.min_item_count) {
              conditionGap.gap_type = 'item';
              conditionGap.selected_value = selectedValue;
              var gapValue =
                condition.min_item_count - conditionGap.selected_value;
              conditionGap.gap_value = gapValue < 0 ? 0 : gapValue;
              conditionGap.hasFulfillment = conditionGap.gap_value <= 0;
            } else {
              return;
            }
            return conditionGap;
          });

          return _.compact(conditionGaps);
        }

        function getAppliedPromotion(cartData) {
          if (typeof cartData === 'string') {
            return;
          }

          var conditionGaps = getConditionGaps(promotion, cartData);
          if (
            conditionGaps.every(function (conditionGap) {
              return conditionGap.hasFulfillment;
            })
          ) {
            return promotion;
          }
        }

        function getUntriggeredPromotion(cartData) {
          if (typeof cartData === 'string') {
            return;
          }
          if (promotion.conditions.length === 0) {
            return;
          }

          var untriggeredPromotion = {
            discount_on: promotion.discount_on,
            discount_type: promotion.discount_type,
            condition_type:
              promotion.conditions[0].whitelisted_category_ids.length > 0
                ? 'category'
                : 'item',
            condition_gaps: [],
          };

          untriggeredPromotion.condition_gaps = getConditionGaps(
            promotion,
            cartData,
          );

          if (
            untriggeredPromotion.condition_gaps.every(function (conditionGap) {
              return conditionGap.hasFulfillment;
            })
          ) {
            return;
          }
          return untriggeredPromotion;
        }

        function getPromotionDiscountType(promotion) {
          return _.contains(['free_shipping', 'gift'], promotion.discount_type)
            ? promotion.discount_type
            : 'discount';
        }

        function getRawBundleGroupAppliedReminder() {
          if (scope.hasStackableReminder) {
            // applied & stackable
            return (
              $filter('translate')('cart.promotion_reminder.applied.discount') +
              $filter('translate')('cart.promotion_reminder.stackable')
            );
          } else if (!scope.untriggeredPromotion) {
            // applied
            return $filter('translate')(
              'cart.promotion_reminder.applied_highest.discount',
            );
          }
          return '';
        }

        function getGeneralPromotionReminder(cartData) {
          var promotionType = getPromotionDiscountType(promotion);
          if (
            scope.appliedPromotion &&
            scope.appliedPromotion.extend_promotions.length
          ) {
            var nextExtendPromotion = getNextExtendPromotion(cartData);
            if (nextExtendPromotion) {
              var nextStepConditionGap = getConditionGaps(
                nextExtendPromotion,
                cartData,
              )[0];
            }
          }

          if (scope.untriggeredPromotion) {
            // not applied
            var conditionGap = scope.untriggeredPromotion.condition_gaps[0];
            return (
              $filter('translate')(
                'cart.promotion_reminder.' + conditionGap.gap_type,
                {
                  gap: conditionGap.gap_value.label
                    ? conditionGap.gap_value.label
                    : conditionGap.gap_value,
                },
              ) +
              $filter('translate')(
                'cart.promotion_reminder.discount_target.' + promotionType,
              )
            );
          } else if (
            scope.appliedPromotion &&
            scope.appliedPromotion.extend_promotions &&
            nextStepConditionGap &&
            !nextStepConditionGap.hasFulfillment &&
            promotionType !== 'gift'
          ) {
            // applied & multiple step
            return (
              $filter('translate')(
                'cart.promotion_reminder.applied.' + promotionType,
              ) +
              $filter('translate')(
                'cart.promotion_reminder.' + nextStepConditionGap.gap_type,
                {
                  gap: nextStepConditionGap.gap_value.label
                    ? nextStepConditionGap.gap_value.label
                    : nextStepConditionGap.gap_value,
                },
              ) +
              $filter('translate')('cart.promotion_reminder.multiple_step')
            );
          } else if (
            scope.hasStackableReminder &&
            promotionType === 'discount'
          ) {
            // applied & stackable
            return (
              $filter('translate')(
                'cart.promotion_reminder.applied.' + promotionType,
              ) + $filter('translate')('cart.promotion_reminder.stackable')
            );
          } else if (scope.appliedPromotion) {
            // applied
            return $filter('translate')(
              'cart.promotion_reminder.applied_highest.' + promotionType,
            );
          }
        }

        function updateCart($event, cartData) {
          var originCartItemsLength = scope.promotionCartItems.length;

          $timeout(function () {
            addEllipsisToTitle();
            replaceMobileRemoveButton();
          });
          scope.promotionCartItems = getPromotionCartItems(cartData.items);

          scope.appliedPromotion = getAppliedPromotion(cartData);
          scope.untriggeredPromotion = getUntriggeredPromotion(cartData);
          scope.hasStackableReminder =
            scope.appliedPromotion && scope.appliedPromotion.is_accumulated;

          if (promotion.discount_type === 'bundle_group') {
            scope.bundleGroupAppliedReminder = getRawBundleGroupAppliedReminder();
            scope.getBundleGroupAppliedReminder = function (isMobile) {
              if (scope.bundleGroupAppliedReminder && !isMobile) {
                return (
                  $filter('translate')(
                    'cart.promotion_reminder.applied.discount_prefix',
                  ) + scope.bundleGroupAppliedReminder
                );
              } else {
                return scope.bundleGroupAppliedReminder;
              }
            };
          } else {
            scope.generalPromotionReminder = getGeneralPromotionReminder(
              cartData,
            );
          }

          if (
            $cookies.isPromotionCartPanelExpanded === undefined ||
            $cookies.isPromotionCartPanelExpanded === 'true'
          ) {
            scope.state.isPanelExpanded = true;
          }

          // toggle cart panel depends on size of promotion cart after cart fetching and updating
          if (scope.promotionCartItems.length > 0) {
            scope.state.isPanelActived = true;
            fbService.hideFacebookCustomerChat();
          } else {
            scope.state.isPanelActived = false;
            scope.state.isPanelExpanded = false;
            fbService.showFacebookCustomerChat();
          }
          scope.state.isPreviousPanelExpanded = scope.state.isPanelExpanded;
          scope.state.isPreviousPanelActived = scope.state.isPanelActived;

          if (scope.promotionCartItems.length > originCartItemsLength) {
            addScrollBar();
            // scroll the cart to bottom when the item user added is not existed in cart
            var $cartScrollZone = $('.PromotionCart-content');
            var targetScrollTop = $cartScrollZone.find('.container').height();
            scrollbarInstance.scroll({ y: targetScrollTop }, 450);
          }
          $timeout(function () {
            fbService.setFacebookCustomerChatPosition();
          });
        }

        function getNextExtendPromotion(cartData) {
          var extendPromotions = sortedExtendedPromotions();
          if (cartData.promotion_ids.includes(promotion.id)) {
            return extendPromotions[0];
          } else {
            return _.find(extendPromotions, function (promotion) {
              return promotion.conditions.every(function (condition) {
                var conditiinMinValue =
                  condition.min_item_count || condition.min_price.cents;
                return (
                  conditiinMinValue > getSelectedGapValue(cartData, condition)
                );
              });
            });
          }
        }

        function sortedExtendedPromotions() {
          var minType = promotion.conditions[0].min_price ? 'amount' : 'item';
          return _.sortBy(promotion.extend_promotions, function (
            extendedPromotion,
          ) {
            var condition = extendedPromotion.conditions[0];
            if (minType === 'amount') {
              return condition.min_price.cents;
            } else if (minType === 'item') {
              return condition.min_item_count;
            }
          });
        }

        function getSelectedGapValue(cartData, condition) {
          var discountableIds =
            promotion.discount_type === 'bundle_group'
              ? condition.whitelisted_product_ids
              : productIds;
          if (condition.min_price) {
            return cartData.items.reduce(function (amount, item) {
              if (discountableIds.includes(item.product_id)) {
                return amount + (item.total.cents || 0);
              }
              return amount;
            }, 0);
          } else if (condition.min_item_count) {
            return cartData.items.reduce(function (int, item) {
              if (discountableIds.includes(item.product_id)) {
                return int + item.quantity;
              }
              return int;
            }, 0);
          }
        }

        function getPromotionCartItems(cartItems) {
          // magic number 0 from cart service means no data in cart
          if (!cartItems || cartItems === 0) return [];
          return cartItems.reduce(function (acc, cur) {
            // is not a discountable item
            if (productIds.indexOf(cur.product_id) < 0) return acc;
            // filter out product_set if key is OFF
            if (
              !slFeatureService.hasFeature('product_set') &&
              cur.type === 'product_set'
            ) {
              return acc;
            }
            cur.originQuantity = cur.quantity;
            return acc.concat(cur);
          }, []);
        }

        function addEllipsisToTitle() {
          $timeout(function () {
            $jq('.PromotionCart-panel .product-content .title').each(
              function () {
                $(this).dotdotdot({
                  wrap: 'letter',
                  ellipsis: '...',
                  height: $(this).css('max-height').replace('px', ''), // attach ellipsis by elements' height
                });
              },
            );
          });
        }

        function replaceMobileRemoveButton() {
          var currentTheme = mainConfig.merchantData.current_theme_key;
          var enableTheme = ['sangria'];
          if (enableTheme.includes(currentTheme)) {
            $timeout(function () {
              $('.PromotionCart-content .remove-button.visible-xs').each(
                function () {
                  $(this)
                    .find('.fa')
                    .replaceWith(
                      '<svg class="icons icon-delete"><use xlink:href="#icon-delete"></use></svg>',
                    );
                },
              );
            });
          }
        }

        function addScrollBar() {
          if (scrollbarInstance) {
            scrollbarInstance.destroy();
            scrollbarInstance = null;
          }

          scrollbarInstance = $('.PromotionCart-content')
            .overlayScrollbars({ overflowBehavior: { x: 'hidden' } })
            .overlayScrollbars(); // a hacky way to get the instance
        }

        registeredEvents.push(
          $rootScope.$on('cartService.fetch', updateCart),
          $rootScope.$on('cartItemsUpdated', function (event, cartData) {
            $cookies.isPromotionCartPanelExpanded = true;
            scope.state.isPanelExpanded = true;
            updateCart(event, cartData);
          }),
          $rootScope.$on('modal.open', function (event, payload) {
            if (payload.modalType !== modalTypes.QUICK_CART) return;

            scope.state.isPreviousPanelExpanded = scope.state.isPanelExpanded;
            scope.state.isPreviousPanelActived = scope.state.isPanelActived;
            scope.state.isPanelExpanded = false;
            scope.state.isPanelActived = false;
          }),
          $rootScope.$on('modal.close', function (event, payload) {
            if (payload.modalType !== modalTypes.QUICK_CART) return;

            $timeout(function () {
              scope.state.isPanelExpanded = scope.state.isPreviousPanelExpanded;
              scope.state.isPanelActived = scope.state.isPreviousPanelActived;
            }, 500);
          }),
        );

        $rootScope.$on('$destroy', function () {
          angular.element($window).off('resize', resizeEventHandler);
          registeredEvents.forEach(function (unregisterEvent) {
            unregisterEvent();
          });
        });

        // use timeout to delay for preventing cart cache when history go back
        $timeout(function () {
          $('.body-wrapper').css('padding-bottom', '50px');
          cartService.fetchItems();
        });

        angular.element($window).on('resize', resizeEventHandler);

        $window.onpageshow = function (event) {
          if (event.persisted) {
            // prevent some browser(e.g. mobile safari) try to get old state from history
            scope.state.isCheckoutButtonLoading = false;
            cartService.fetchItems();
          }
        };
      },
    };
  },
]);
