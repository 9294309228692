(function () {
'use strict';

angular.module('tw.control-group', ['pascalprecht.translate'])
  .directive("controlGroup",['$parse','$filter', function ($parse,$filter) {
    return {
        restrict: 'A',
        templateUrl: function(element, attr) { return attr.templateUrl ? attr.templateUrl : '/themes/v1/default/views/modules.control-group.html?' },
        transclude: true,
        require: ["^form"],
        scope: {
            label: "@", // Gets the string contents of the `label` attribute
            fieldName: "@", // Gets the string contents of the `data-field-name` attribute
            formInline: "@",
            labelHtml: "@",
            validateBeforeSubmit: '=',
            description: "=",
        },
        link: function (scope, element, attrs, ctrls) {
          var formController = ctrls[0];

          // Get the `name` attribute of the input
          //TODO: change by a different identifier, such as select or checkbox

          // label need '' to hide if have labelHtml
          if(scope.labelHtml) {
            scope.label = ''
          }

          // for manually set as submitted
          scope.$watch('validateBeforeSubmit', function() {
            validate();
          })

          var input = element.find("input");
          if (input.length ==0) input = element.find("select");
          if (input.length ==0) input = element.find("textarea");
          scope.input = input;
          var formName = formController.$name;
          var fieldName = input.attr("name");
          var modelName = input.attr("ng-model");
          var greater_than = input.attr("ng-greater-than");
          var validation = input.attr("ng-validation");

          scope.for = fieldName;


          var fieldExpression = formName + '.' + fieldName;

          var validateCallback = function (newValue, oldValue) {
            if (newValue != oldValue) {
              validate();
            }
          };

          if (input.length > 0){
            if (element[0].hasAttribute('watch-field-validity')) {
              scope.$watch(function() {
                if(formController[fieldName]) {
                  return formController[fieldName].$valid;
                }
              }, validateCallback);
            } else {
              scope.$watch(function() {
                return input[0].value;
              }, validateCallback);
            }
          }
          scope.$watch(function(scope) {
            return formController.$dirty;
          }, validateCallback) ;
          scope.$watch(function(scope) {
            return formController.$valid;
          }, validateCallback) ;
          scope.$watch(function(scope) {
            return formController.$submitted;
          }, validateCallback);
          scope.$watch(function(scope) {
            return formController.submitted;
          }, validateCallback);
          scope.$watch(modelName, function () {
            validate();
           });

          if (fieldName === 'user_email') {
            scope.$watch(function() {
              return formController[fieldName].$error.uniqueness;
            }, validateCallback);
          }

          if (input.attr("ng-pattern")) {
            scope.$watch(function() {
              return formController[fieldName].$error.pattern;
            }, validateCallback);
          }

          if (input.attr("type") == "link") {
            // custom validation for link type
            var fieldObject = formController[fieldName];
            fieldObject.$parsers.push(function(viewValue) {  // used push instead of unshift to be the last to parser
              if (viewValue == undefined || viewValue.length == 0) {
                fieldObject.$setValidity('link', true);
                return "";
              }
              if (isEmail(viewValue)) {
                viewValue = "mailto:" + viewValue;
                fieldObject.$setValidity('link', true);
              }else {
                if (!hasProtocol(viewValue)) {
                  viewValue = "http://" + viewValue;
                }
                if (isURL(viewValue)) {
                  fieldObject.$setValidity('link', true);
                }else {
                  fieldObject.$setValidity('link', false);
                  return undefined;
                }
              }
              return viewValue;
            });
          }


          function validate() {
            var formObject = formController;
            var fieldObject = formController[fieldName];
            if (fieldObject == undefined) return;

            if (scope.validateBeforeSubmit == true || formObject.$submitted || formObject.submitted) {
              scope.hasError =  fieldObject.$invalid;
            } else {
              scope.hasError =  false;
            }
            scope.error =fieldObject.$error;

            greater_than = input.attr("ng-greater-than");
            if (greater_than) {
              greater_than_value = scope.$parent.$eval(greater_than);
              validation_value = scope.$parent.$eval(validation);
              if (validation_value) {
                if (parseInt(fieldObject.$viewValue) <= parseInt(greater_than_value)) {
                  fieldObject.$setValidity('greater_than', false);
                } else {
                  fieldObject.$setValidity('greater_than', true);
                }
              }
            }

            if ( input.attr("min-date") ){

              var minDate = scope.$parent.$parent.$parent.$eval(input.attr("min-date"));
              var inputDate = scope.$parent.$parent.$parent.$eval(modelName);
              if(inputDate < minDate) fieldObject.$setValidity('date', false);
            }

            if ( input.attr("max-date") ){
              var maxDate = scope.$parent.$parent.$parent.$eval(input.attr("max-date"));
              var inputDate = scope.$parent.$parent.$parent.$eval(modelName);
              if(inputDate > maxDate) fieldObject.$setValidity('date', false);
            }

            if (_.isUndefined(scope.error)) return ;

            if ((_.has(scope.error,"required")) && (scope.error.required == true) ){
              scope.errorMessage =  $filter('translate')('form.validation.required',{field_name:scope.fieldName})
              return;
            }
            if ((_.has(scope.error,"parse")) && (scope.error.parse === true)){
              scope.errorMessage =  $filter('translate')('form.validation.invalid',{field_name:scope.fieldName})
              return;
            }
            if (scope.error.minlength == true ){
              scope.errorMessage =  $filter('translate')('form.validation.minlength',{field_name:scope.fieldName})
              return;
            }
            if (scope.error.maxlength == true ){
              scope.errorMessage =  $filter('translate')('form.validation.maxlength',{field_name:scope.fieldName})
              return;
            }
            if (scope.error.email == true ){
              scope.errorMessage =  $filter('translate')('form.validation.email',{field_name:scope.fieldName})
              return;
            }
            if (scope.error.uniqueness == true ){
              scope.errorMessage =  $filter('translate')('form.validation.unique',{field_name:scope.fieldName})
              return;
            }
            if (scope.error.pattern == true ){
              scope.errorMessage =  $filter('translate')('form.validation.pattern',{field_name:scope.fieldName})
              return;
            }
            if (scope.error.date == true ){
              scope.errorMessage =  $filter('translate')('form.validation.pattern',{field_name:scope.fieldName})
              return;
            }
            if (scope.error.link) {
              scope.errorMessage = $filter('translate')('form.validation.link');
              return;
            }
            if (scope.error.url) {
              scope.errorMessage = $filter('translate')('form.validation.url', {field_name:scope.fieldName});
              return;
            }
            if (scope.error.emailUnique) {
              scope.errorMessage = $filter('translate')('form.validation.email.duplicate');
              return;
            }
            if (scope.error.greater_than) {
              scope.errorMessage = $filter('translate')('form.validation.greater_than', {field_name:scope.fieldName, greater_than: greater_than_value});
              return;
            }
            if ((_.has(scope.error,"general")) && (scope.error.general == true) ){
              scope.errorMessage = fieldObject.validationMessage;
            }

          };


          var hasProtocol = function(str) {
            var urlregex = new RegExp("^(http|https|ftp)\://");
            return urlregex.test(str);
          }

          var isEmail = function(str)
          {
              var re = /[^\s@]+@[^\s@]+\.[^\s@]+/;
              return re.test(str);
          }

          var isURL = function(str) {
            var urlRegex = new RegExp(/(ftp|http|https):\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
            return urlRegex.test(str);
          }

          var isLarger = function(str) {

            return true
          }
        }
    };
}]);

}) ();