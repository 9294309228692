app.controller('UsersWelcomeController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  'merchantService',
  '$location',
  '$filter',
  'mainConfig',
  'trackerService',
  'redirectTo',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    merchantService,
    $location,
    $filter,
    mainConfig,
    trackerService,
    redirectTo,
  ) {
    setTimeout(function () {
      var path = redirectTo;
      if (!angular.isUndefined(path) && path != '') {
        $window.location.href = path;
      } else {
        $window.location.href = '/';
      }
    }, 5000);

    $scope.seconds = 5;
    trackerService.fbSignup($rootScope.currentUser._id);

    var timer = setInterval(function () {
      if ($scope.seconds != 0) {
        $scope.$apply(($scope.seconds -= 1));
      } else {
        clearInterval(timer);
      }
    }, 1000);
  },
]);
