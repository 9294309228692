angular.module('tw.filepicker', ['ui.bootstrap'])
.directive("filepicker", function(){
	return {
		transclude: true,
		restrict: "A",
		template: " <div> <div ng-transclude> </div></div>",
		link: function(scope, element, attrs) {
			scope.$parent.openFile = function() {
				var input = element.find("input");
				input.trigger('click');
				// var elem = angular.element(document.querySelector( '#'+type ));
    // 		elem.trigger('click');
			}
		}
	};
})
  
;