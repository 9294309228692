app.directive('lockInventoryQuantity', [
  '$filter',
  '$timeout',
  'mainConfig',
  function ($filter, $timeout, mainConfig) {
    return {
      restrict: 'E',
      scope: {
        lockedQuantity: '=',
        expiredTime: '=',
        startTime: '=',
        eventName: '=',
      },
      template:
        '<span class="lock-inventory-description lock-inventory-locked-quantity"' +
        'uib-tooltip="{{lockInfoTooltip}}">' +
        '{{lockedQuantity}}' +
        ' ' +
        $filter('translate')('lock_inventory.locked_quantity_unit') +
        '</span>',
      link: function (scope) {
        $timeout(function () {
          var offset = new Date().getTimezoneOffset() / -60;
          timezoneOffset = offset < 0 ? offset : '+' + Math.abs(offset);

          timeFormat =
            mainConfig.localeData.loadedLanguage.code === 'vi'
              ? 'DD/MM/YYYY HH:mm'
              : 'YYYY/MM/DD HH:mm';

          scope.lockInfoTooltip = $filter('translate')(
            'lock_inventory.tooltip',
            {
              expired_time: dayjs(scope.expiredTime).format(timeFormat),
              start_time: dayjs(scope.startTime).format(timeFormat),
              event_name: scope.eventName,
              timezone: timezoneOffset,
            },
          );
        });
      },
    };
  },
]);
