app.directive("checkoutDeliveryForm", [
  '$compile'
  '$timeout'
  '$rootScope'
  'featureService'
  'checkoutService'
  (
    $compile
    $timeout
    $rootScope
    featureService
    checkoutService
  ) ->
    {
      restrict: 'A'
      scope: {}
      link: (scope, element, attrs) ->
        scope.state = {
          syncRecipientCustomer: false
        }

        syncSaveCountry = false
        notifyForRecipientSync = (isChecked) ->
          scope.state.syncRecipientCustomer = isChecked
          $rootScope.$broadcast "checkout.customer.needs_update" if isChecked

        element.on "click", "input[name='order[delivery_data][recipient_is_customer]']", (event) ->
          notifyForRecipientSync(angular.element(event.currentTarget).is(":checked"))

        scope.$on "checkout.customer.changed", ($event, payload) ->
          if scope.state.syncRecipientCustomer
            checkoutService.getElement('order[delivery_data][recipient_name]').val(payload.customer_name)
            checkoutService.getElement('order[delivery_data][recipient_phone]').val(payload.customer_phone)
            if featureService.hasRolloutFeature('country_code')
              country = if $rootScope.currentUser && $rootScope.currentUser.phones.length > 0 && checkoutService.getElement('order[customer_phone]').val() != 'new'
              then $('.contry-content').intlTelInput('getSelectedCountryData').iso2
              else $('.order-customer-phone-input').intlTelInput('getSelectedCountryData').iso2

              if $('#recipient-phone').intlTelInput('getSelectedCountryData').iso2 != country
                syncSaveCountry = true
                $('#recipient-phone').intlTelInput('setCountry', country)
              else
                syncSaveCountry = false

        element.on 'input', 'input[name="order[delivery_data][recipient_name]"], input[name="order[delivery_data][recipient_phone]"]', () ->
          diableSyncRecipientCustomer()

        element.on "change", "input[name='form[address]']", (event) ->
          target = angular.element(event.currentTarget)
          value = target.val()
          element.find(".saved-delivery-data").hide()
          if value is 'new'
            if ($('.dynamic-delivery-fee').length > 0)
              $rootScope.$broadcast 'delivery.fee.need.recalculate'
            element.find("#delivery-form-content").show()
          else
            if ($('.dynamic-delivery-fee').length > 0)
              addressNodeData = target.siblings("input[name='order[delivery_address]']").val()
              if addressNodeData
                params = {}
                addressNodeData = parseAndSaveLogisticCodeData(addressNodeData)
                if (addressNodeData && addressNodeData.logistic_codes)
                  params = { delivery_address: { logistic_codes: addressNodeData.logistic_codes }}
                $rootScope.$broadcast 'delivery.fee.update', params
            element.find("#delivery-form-content").hide()
            target.siblings("input[name='order[delivery_address]']").show()
            target.siblings("input[name='order[delivery_data]']").show()
          $rootScope.$broadcast 'tax.fee.address.changed'

        element.on "click", "input[name='saveFields[default_address]']", (event) ->
          isChecked = angular.element(event.currentTarget).is(":checked")
          $saveAddressOption = checkoutService.getElement('saveFields[delivery_address]', element)
          $saveAddressOption.prop("checked", true) if isChecked
          $saveAddressOption.prop("disabled", isChecked)

        element.on "destroy", (() -> element.off()) # Unbind events
        notifyForRecipientSync(checkoutService.getElement('order[delivery_data][recipient_is_customer]').is(":checked"))

        if featureService.hasRolloutFeature('country_code')
          $ '#recipient-phone'
            .on 'countrychange', ->
              if !syncSaveCountry
                diableSyncRecipientCustomer()
              else
                syncSaveCountry = false

          $ '.order-customer-phone-input, .contry-content'
            .on 'countrychange', (e, countryData) ->
              if scope.state.syncRecipientCustomer
                syncSaveCountry = true
                $('#recipient-phone').intlTelInput('setCountry', countryData.iso2)


        diableSyncRecipientCustomer = ->
          if angular.element('input[name="order[delivery_data][recipient_is_customer]"]').is(':checked')
            checkoutService.getElement('order[delivery_data][recipient_is_customer]').prop('checked', false)
            scope.state.syncRecipientCustomer = false

        $timeout(()->
          $rootScope.scrollTo($('.delivery-form')) if $('span[name="order[delivery_data][location_code]"]').text()
        ,0)

        parseAndSaveLogisticCodeData = (addressNodeData) ->
          try
            parsedData = JSON.parse(addressNodeData)
            if (parsedData && parsedData.logistic_code)
              $("#dynamic-deliver-fee-logistic-code").val(parsedData.logistic_code)
            return parsedData
          catch e
            console.log(e)

        $(window).load(() -> 
          if ($('.dynamic-delivery-fee').length > 0)
            try
              params = { status: 'formDefaultAddressCall' }
              if ($('.saved-delivery-data[name="order[delivery_address]"]').val())
                addressNodeData = parseAndSaveLogisticCodeData($('.saved-delivery-data[name="order[delivery_address]"]').val())
                if (addressNodeData && addressNodeData.logistic_codes)
                  params = { delivery_address: { logistic_codes: addressNodeData.logistic_codes }}
              else
                $("#dynamic-deliver-fee-logistic-code").val("")
              $rootScope.$broadcast 'delivery.fee.update', params
            catch e
              console.log(e)
          
          $(".dynamic-delivery-fee-state-select").change(() ->
            try
              addressNode = JSON.parse($(this).val())
              $('input[name="order[delivery_address][state]"').val(addressNode.name)
              $("#dynamic-deliver-fee-logistic-code").val(addressNode.logistic_code)
              $rootScope.$broadcast 'delivery.fee.update'
            catch e
              $rootScope.$broadcast 'delivery.fee.need.recalculate'
          )
        )
    }
])