app.directive('slFormResponse', [
  '$http',
  'pnotifyService',
  function ($http, pnotifyService) {
    return {
      restrict: 'C',
      scope: {
        formId: '@',
        offset: '@',
        total: '@',
        limit: '@',
        requiredCustomer: '@',
        paginationMode: '@',
      },
      link: function (scope, element, attrs) {
        var getResponses = function (page) {
          var requestUrl =
            'forms/' +
            scope.formId +
            '/responses?page=' +
            page +
            '&required_customer=' +
            scope.requiredCustomer +
            '&limit=' +
            scope.limit;
          return $http
            .get(requestUrl)
            .then(function (result) {
              scope.offset = result.data.offset;
              scope.total = result.data.total;
              return result;
            })
            .catch(function (e) {
              pnotifyService.notify(
                $filter('translate')('form.response.load.more.error'),
                { customClass: 'error', icon: 'fa fa-exclamation-triangle' },
              );
            });
        };

        if (scope.paginationMode === 'scroll') {
          element.find('#form-response-content').scroll(function () {
            var el = $(this);
            if (el.scrollTop() + el.innerHeight() >= el[0].scrollHeight) {
              if (scope.offset + scope.limit <= scope.total) {
                var page = scope.offset / scope.limit + 2;
                getResponses(page).then(function (result) {
                  element
                    .find('#form-response-content')
                    .append(result.data.html);
                });
              }
            }
          });

          scope.$on('form.response.submit', function (event, formId) {
            var page = 1;
            getResponses(page).then(function (result) {
              element.find('#form-response-content').html(result.data.html);
            });
          });
        }

        if (scope.paginationMode === 'pagination') {
          element.find('.paginator-page-button').click(function () {
            var el = $(this);
            var page = parseInt(el.attr('data-index'), 10);
            getResponses(page).then(function (result) {
              element.find('#form-response-content').html(result.data.html);
              renderPageButton(page);
            });
          });

          element
            .find(
              '.paginator-button-cursor-prev, .paginator-button-cursor-next',
            )
            .click(function () {
              var el = $(this);
              var currentPage = scope.offset / scope.limit + 1;
              var page = currentPage + parseInt(el.attr('data-index'), 10);
              getResponses(page).then(function (result) {
                element.find('#form-response-content').html(result.data.html);
                renderPageButton(page);
              });
            });

          var renderPageButton = function (page) {
            if (
              parseInt(scope.offset, 10) + parseInt(scope.limit, 10) <=
              parseInt(scope.total, 10)
            ) {
              element.find('.paginator-button-cursor-next').show();
            } else {
              element.find('.paginator-button-cursor-next').hide();
            }

            if (parseInt(scope.offset) == 0) {
              element.find('.paginator-button-cursor-prev').hide();
            } else {
              element.find('.paginator-button-cursor-prev').show();
            }

            element.find('.paginator-page-button').removeClass('active');
            element
              .find(".paginator-page-button[data-index='" + page + "']")
              .addClass('active');
          };

          scope.$on('form.response.submit', function (event, formId) {
            var page = 1;
            if (scope.formId == formId) {
              getResponses(page).then(function (result) {
                element.find('#form-response-content').html(result.data.html);
                renderPageButton(page);
              });
            }
          });
        }
      },
    };
  },
]);
