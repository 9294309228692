app.directive('fieldGroup', function () {
  return {
    restrict: 'A',

    templateUrl: function (element, attr) {
      return attr.templateUrl
        ? attr.templateUrl
        : '/themes/v1/default/views/modules.field-group.html';
    },
    transclude: true,
    scope: {
      label: '@', // Gets the string contents of the `label` attribute
      fieldName: '@', // Gets the string contents of the `data-field-name` attribute
    },
    link: function (scope, element, attrs) {},
  };
});
