app.controller('MessagesController', [
  '$rootScope',
  '$scope',
  '$http',
  '$location',
  'mainConfig',
  '$lightbox',
  function ($rootScope, $scope, $http, $location, mainConfig, $lightbox) {
    $scope.loadItems = function (page) {
      $scope.loadingItems = true;
      var getURL =
        '/api/messages?recipient_id=' + mainConfig.merchantId + '&page=' + page;
      var currentQuery = new URI(window.location.href);
      currentQuery = currentQuery.query();
      currentQuery = URI.parseQuery(currentQuery);

      if ($rootScope.currentUser) {
        getURL += '&sender_id=' + $rootScope.currentUser._id;
      } else if (currentQuery['sender_id']) {
        getURL += '&sender_id=' + currentQuery['sender_id'];
      }
      $http({
        method: 'GET',
        url: getURL,
      }).then(function (res) {
        _.extend($scope, {
          totalItems: res.data.data.total,
          currentPage: page,
          messages: res.data.data.items.reverse().concat($scope.messages),
          loadingItems: false,
          limit: res.data.data.limit,
          hasMore: res.data.data.items.length >= res.data.data.limit,
        });
      });
    };

    //init
    $scope.messages = [];
    $scope.hasMore = false;
    $scope.currentPage = 1;
    //$scope.paginationLoaded = false;
    $scope.loadingItems = true;
    $scope.loadMore = function () {
      var nextPage = $scope.currentPage + 1;
      $scope.loadItems(nextPage);
    };

    $scope.$on('chat.message.created', function (event, data) {
      $scope.messages.push(data);
    });

    $scope.loadItems($scope.currentPage);

    $scope.onImageCliked = function ($event, index) {
      $lightbox.open([$scope.messages[index].media], 0);
      return;
    };
  },
]);
