app.directive('carouselDisplay', [
  'imageService',
  '$window',
  '$compile',
  '$timeout',
  'digestLife',
  'slFeatureService',
  '$filter',
  function (
    imageService,
    $window,
    $compile,
    $timeout,
    digestLife,
    slFeatureService,
    $filter,
  ) {
    return {
      transclude: false,
      restrict: 'E',
      scope: {
        mobileMedia: '=',
        desktopMedia: '=',
        imageItem: '=',
        isPreview: '=',
      },
      templateUrl: '/themes/v1/default/views/templates.carousel-display.html',
      link: function (scope, element, attrs) {
        scope.isMobile = $window.innerWidth < 768;
        var options = {
          loop: true,
          items: 1,
          margin: 10,
          nav: false,
          lazyLoad: true,
          autoplay: true,
          autoHeight: true,
          autoHeightClass: 'owl-height',
          autoplayTimeout:
            parseFloat(
              (scope.imageItem && scope.imageItem.carousel.interval) || 5,
            ) * 1000,
          autoplayHoverPause: false,
          onLoadedLazy: function (event) {
            target = element.find('.owl-carousel');
            window.resizeImages(event.target);
            if (target.hasClass('initializing')) {
              $(
                target.closest('.ImageItem-carouselContainer').children()[0],
              ).hide();
              target.removeClass('initializing');
            }
          },
          onInitialized: function () {
            window.resizeImages(element[0]);
            setTimeout(function () {
              element.find('.owl-carousel').trigger('refresh.owl.carousel');
            }, 1000);
          },
        };

        scope.switchMediaPlatform = function () {
          var isMobileValid =
            slFeatureService.hasFeature('image_widget_mobile') &&
            scope.mobileMedia.length;
          scope.media =
            scope.isMobile && isMobileValid
              ? scope.mobileMedia
              : scope.desktopMedia;
        };
        scope.switchMediaPlatform();

        scope.getImage = function (media) {
          return imageService.getMediaImageUrl(media);
        };
        scope.initCarousel = function () {
          element.find('.owl-carousel').owlCarousel(options);
        };
        scope.rebuildCarousel = function () {
          var owl = element.find('.owl-carousel');
          owl.owlCarousel('destroy');
          scope.switchMediaPlatform();
        };
        var afterDigest = _.throttle(function () {
          scope.initCarousel();
          digestLife.unregisterByScope(scope);
        }, 200);

        scope.$on('window.resize', function () {
          if ($window.innerWidth < 768 && !scope.isMobile) {
            scope.isMobile = true;
            scope.rebuildCarousel();
            scope.$apply();
          }
          if ($window.innerWidth >= 768 && scope.isMobile) {
            scope.isMobile = false;
            scope.rebuildCarousel();
            scope.$apply();
          }
        });
        // Hook on the scope
        digestLife.registerByScope(scope, afterDigest);

        $(window).on('focus', function () {
          $('carousel-display .owl-carousel').trigger('next.owl.carousel');
        });

        scope.getAlt = function (media) {
          if (!media) return '';
          return $filter('translateModel')(media.alt_translations);
        };

        scope.getLinkAndNewTab = function (media) {
          if (!media) return {};
          return {
            link: media.link,
            newTab: media.new_tab,
          };
        };
      },
    };
  },
]);
