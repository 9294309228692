app.directive('facebookBanner', [
  '$filter',
  'execCommandService',
  'userAgentService',
  function ($filter, execCommandService, userAgentService) {
    return {
      restrict: 'A',
      link: function (scope) {
        scope.state = {
          isBannerOpened: (function () {
            if (!document.body.classList.contains('orders')) {
              return false;
            }

            if (!userAgentService.isFacebookBrowser()) {
              return false;
            }

            var version = userAgentService.getAndroidVersion();
            if (!version) {
              return false;
            }

            var major = version.split('.')[0];
            return Number(major) < 10;
          })(),
        };

        if (scope.state.isBannerOpened) {
          $('.search-panel-mobile, .body-wrapper').addClass(
            'has-facebook-banner',
          );
          $('.trial-banner').hide();
        }

        scope.onClose = function () {
          $('.search-panel-mobile, .body-wrapper').removeClass(
            'has-facebook-banner',
          );
          $('.trial-banner').show();
          scope.state.isBannerOpened = false;
        };

        scope.copy = function () {
          execCommandService.copyToClipboard(window.location.href);

          new PNotify({
            title: $filter('translate')('member_referral.copy.link.success'),
            addclass: 'success facebook-copy-notify',
            animate: {
              animate: true,
              in_class: 'fadeInDown',
              out_class: 'fadeOut',
            },
            delay: 4000,
            icon: 'fa fa-check-circle',
          });
        };
      },
    };
  },
]);
