app.service('fastCheckoutService', [
  'slFeatureService',
  'mainConfig',
  '$compile',
  'buyNowService',
  function (slFeatureService, mainConfig, $compile, buyNowService) {
    this.isSpbEnabled = slFeatureService.hasFeature('paypal_spb_checkout');
    this.isEnableEc =
      slFeatureService.hasFeature('ec_fast_checkout') &&
      mainConfig.merchantData.checkout_setting?.enable_ec_fast_checkout;

    this.createFixedFastCheckoutButton = function () {
      var hasMultipleBtn = ['sangria'].includes(
        mainConfig.merchantData.current_theme_key,
      );
      var productScope = angular
        .element($('[ng-controller="ProductsShowController"]'))
        .scope();
      var blacklistPaymentIds = productScope.product
        ? productScope.product.blacklisted_payment_ids
        : [];
      var currentPaypalPaymentId = $('#paypal-payment-id').val();
      if (
        slFeatureService.hasFeature('paypal_spb_checkout') &&
        productScope.hasStock &&
        productScope.product &&
        !productScope.product.hide_price &&
        currentPaypalPaymentId &&
        !blacklistPaymentIds.includes(currentPaypalPaymentId) &&
        $('.available-time-disabled').length <= 0
      ) {
        var fixedBuyNowButton = $compile(
          '<div class="btn-cart-fixed btn-fast-checkout" ng-show="hasStock"><paypal-fast-checkout-btn position="fixed-btn" click-action="addItemToCart($event, false, true)"></paypal-fast-checkout-btn></div>',
        )(productScope);
        if (buyNowService.isEnabled()) {
          $('.js-sticky-cart-button-container').addClass(
            'fast-checkout-container has-buy-now-button',
          );
        } else {
          $('.js-sticky-cart-button-container').addClass(
            'fast-checkout-container',
          );
        }

        if (
          hasMultipleBtn &&
          window.matchMedia('(min-width: 1200px)').matches
        ) {
          $('.js-sticky-cart-button-container.nav-desktop').append(
            fixedBuyNowButton,
          );
        } else {
          $('.js-sticky-cart-button-container').append(fixedBuyNowButton);
        }
      }
    };
  },
]);
