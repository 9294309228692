app.directive('redeemGiftItem', [
  'productService',
  'pnotifyService',
  'trackerService',
  'cartService',
  '$window',
  '$rootScope',
  '$filter',
  function (
    productService,
    pnotifyService,
    trackerService,
    cartService,
    $window,
    $rootScope,
    $filter,
  ) {
    return {
      restrict: 'E',
      scope: {
        redeemGiftId: '@',
        redeemPoint: '=',
      },
      link: function (scope, element) {
        scope.$on('redeemPointUpdate', function (e, remainingMemberPoint) {
          scope.notEnoughMemberPoint =
            remainingMemberPoint - scope.redeemPoint < 0;
        });

        element.on('click', '.js-btn-add-to-promotion-cart', function (e) {
          e.preventDefault();
          if (!$rootScope.isUserLoggedIn) {
            return ($window.location.href = '/users/sign_in');
          }
          if (!$(e.target).hasClass('sold-out-item')) {
            scope.addItemToCart();
          }
        });

        scope.addItemToCart = function () {
          if (scope.notEnoughMemberPoint) {
            return pnotifyService.notify(
              $filter('translate')('products.quick_cart.insufficient_point'),
              { customClass: 'error', icon: 'fa fa-exclamation-triangle' },
            );
          }

          productService.checkStock(scope.redeemGiftId).then(function (data) {
            var redeemGiftStock = data.data;
            var hasStock =
              redeemGiftStock.unlimited_quantity ||
              redeemGiftStock.cart_quantity + 1 <= redeemGiftStock.quantity;

            if (hasStock) {
              var cartItemData = { quantity: 1, type: 'redeem_gift' };
              cartService.addItem(scope.redeemGiftId, cartItemData, function (
                data,
              ) {
                var redeemGift = _.find(data.items, function (item) {
                  return item.product_id === scope.redeemGiftId;
                });
                trackerService.fbAddToCart(
                  redeemGift.product,
                  0,
                  null,
                  data.event_id,
                );
              });
            } else {
              pnotifyService.notify(
                $filter('translate')('products.quick_cart.low_stock_hint'),
                { customClass: 'error', icon: 'fa fa-exclamation-triangle' },
              );
            }
          });
        };
      },
    };
  },
]);
