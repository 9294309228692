app.directive("checkoutCartSummary", [
  '$http'
  'featureService'
  'checkoutService'
  '$compile'
  '$filter'
  '$timeout'
  '$uibModal'
  (
    $http
    featureService
    checkoutService
    $compile
    $filter
    $timeout
    $uibModal
  ) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        # prevent user to input invalid value
        element.on "keydown", "#apply-credit-form input", (event) ->
          if event.which == 13 || event.which == 69 || event.which == 189 || event.which == 190
            event.preventDefault()

        element.on "click", ".applied-store-credits .btn-toggle", (event) ->
          element.find('.applied-store-credits .error-text').hide()
          element.find('#apply-credit-form').show()

        element.on "keydown", "#apply-member-point-form input", (event) ->
          if event.which == 13 || event.which == 69 || event.which == 189 || event.which == 190
            event.preventDefault()

        element.on "click", ".applied-member-points .btn-toggle", (event) ->
          element.find('.applied-member-points .error-text').hide()
          element.find('#apply-member-point-form').show()

        element.on "click", ".coupon-form .btn-select", (event) ->
          $uibModal.open({
            templateUrl: '/themes/v1/default/views/templates.dialog.checkout-coupon-list.html',
            controller: 'CheckoutCouponListDialogController',
            backdrop: 'true',
          })
          .result
          .then (res) ->
            updateCoupon res.codes[0]
          .catch () -> return;

        element.on "click", "#apply-credit-form .btn-apply", (event) ->
          creditApplyAmount = angular.element("#apply-credit-form input").val()
          return if _.isEmpty(creditApplyAmount)
          scope.state.isCartLoading = true
          checkoutService.requestPartial('cart', 'summary', { credit_apply_amount: creditApplyAmount })
            .then (res) ->
              element.html($compile(res.data)(scope))
              scope.$emit "checkout.cart.summary.changed"
            .catch (error) ->
              console.error("Unable to load cart form")
            .finally () ->
              scope.$emit "checkout.cart.summary.loaded"
              scope.state.isCartLoading = false
              element.find('#credit-apply-form').hide()

        element.on "click", "#apply-member-point-form .btn-apply", (event) ->
          applyMemberPoint = angular.element("#apply-member-point-form input").val()
          return if _.isEmpty(applyMemberPoint)
          scope.state.isCartLoading = true
          checkoutService.requestPartial('cart', 'summary', { apply_member_point: applyMemberPoint })
            .then (res) ->
              element.html($compile(res.data)(scope))
              scope.$emit "checkout.cart.summary.changed"
            .catch (error) ->
              console.error("Unable to load cart form")
            .finally () ->
              scope.$emit "checkout.cart.summary.loaded"
              scope.state.isCartLoading = false

        element.on "keypress", "#order-coupon", (event) ->
          if event.which == 13
            event.preventDefault()
            applyCoupon()

        element.on "click", ".cart-coupon .use-coupon", (event) ->
          element.find(".cart-coupon .coupon-form").show()
          element.find(".cart-coupon .use-coupon").hide()

        element.on "click", ".cart-coupon .use-affiliate", (event) ->
          element.find(".cart-coupon .affiliate-form").show()
          element.find(".cart-coupon .use-affiliate").hide()

        element.on "click", ".cart-coupon .btn-coupon-apply", ((event) -> applyCoupon())

        scope.$on "checkout.cart.coupon.remove", ($event, code) ->
          updateCoupon null, code

        applyCoupon = (event) ->
          coupon_code = angular.element(".cart-coupon #order-coupon").val()
          return if _.isEmpty(coupon_code)
          updateCoupon coupon_code

        updateCoupon = (addCode, removeCode) ->
          payload = if removeCode? then { remove_code: removeCode } else { coupon_code: addCode }
          scope.state.isCartLoading = true
          checkoutService.requestPartial('cart', 'summary', payload)
            .then (res) ->
              element.html($compile(res.data)(scope))
              scope.$emit "checkout.cart.summary.changed"
            .catch (error) ->
              console.error("Unable to load cart form")
            .finally () ->
              scope.$emit "checkout.cart.summary.loaded"
              scope.state.isCartLoading = false

        scope.$on "checkout.cart.summary.reload", () ->
          scope.state.isCartLoading = true
          checkoutService.requestPartial('cart', 'summary')
            .then (res) ->
              element.html($compile(res.data)(scope))
            .catch (error) ->
              console.error("Unable to load cart form")
            .finally () ->
              scope.$emit "checkout.cart.summary.loaded"
              scope.state.isCartLoading = false

        if featureService.hasFeature('taxes_settings')
          scope.$on 'tax.fee.update', (event, data) ->
            checkoutService.requestPartial('cart', 'summary', data)
              .then (res) ->
                element.html($compile(res.data)(scope))
              .catch (error) ->
                console.error('Unable to load cart form')
              .finally () ->
                scope.$emit 'checkout.cart.summary.loaded'

        scope.$on 'delivery.fee.update', (e, data) ->
          checkoutService.requestPartial('cart', 'summary', angular.extend({
            delivery_address: {
              logistic_code: $("#dynamic-deliver-fee-logistic-code").val(),
              logistic_codes: null
            }
          }, data))
          .then (res) ->
            element.html($compile(res.data)(scope))
          .then () ->
            scope.$emit 'checkout.cart.summary.loaded'
            updateTotalHeaderValue()
            if (data.status == 'formDefaultAddressCall')
              if (!$("#dynamic-deliver-fee-logistic-code").val())
                $timeout(() ->
                  setDynamicDeliveryFeeWithoutFeeView()
                , 200)
              else
                updateDeliveryFeeLabel()
            else
              updateDeliveryFeeLabel()
              scope.$emit 'delivery.fee.isUpdated'

        updateDeliveryFeeLabel = (shouldUseDefaultText) ->
          if shouldUseDefaultText && $("#is_using_dynamic_delivery_fee").val()
            newLabel = $filter('translate')('dynamic.delivery.fee.no.state.info')
          else
            labelText = $('.delivery-fee').children('.pull-left').text().match(/.*:/g) && $('.delivery-fee').children('.pull-left').text().match(/.*:/g)[0].trim()
            newLabel = labelText + ' ' + $('.delivery-fee').children('.pull-right').text()
          $('.delivery-form').children('.section-header').children('.pull-right').text(newLabel)

        updateTotalHeaderValue = () ->
          newTotalLabel =
            $('.total').children('.pull-left').html()
              .replace(/<span.*<\/span>/, '')
          newTotal = $('.total').children('.pull-right').text()
          $('#summary-header-total-price').text(newTotalLabel + ' ' + newTotal)

        scope.$on 'delivery.fee.need.recalculate', () ->
          checkoutService.requestPartial('cart', 'summary', delivery_address: {
              logistic_code: null,
              logistic_codes: null
            })
            .then (res) ->
              element.html($compile(res.data)(scope))
            .then () ->
              $timeout(() ->
                $('.delivery-fee').addClass('emphasize')
                updateDeliveryFeeLabel(true)
                updateTotalHeaderValue()
                setDynamicDeliveryFeeWithoutFeeView()
              , 200)

        element.on "destroy", (() -> element.off()) # Unbind events

        element.on "keypress", "#affiliate-code-field", (event) ->
          if event.which == 13
            event.preventDefault()
            applyAffiliate()

        element.on "click", ".cart-coupon .btn-affiliate-apply", ((event) -> applyAffiliate())

        applyAffiliate = (event) ->
          affiliateCode = angular.element("#affiliate-code-field").val()
          return if _.isEmpty(affiliateCode)
          updateAffiliate(affiliateCode)

        updateAffiliate = (addCode, removeCode) ->
          payload = if removeCode? then { remove_affiliate_code: removeCode } else { affiliate_code: addCode }
          scope.state.isCartLoading = true
          checkoutService.requestPartial('cart', 'summary', payload)
            .then (res) ->
              element.html($compile(res.data)(scope))
              scope.$emit "checkout.cart.summary.changed"
            .catch (error) ->
              console.error("Unable to load cart form")
            .finally () ->
              scope.$emit "checkout.cart.summary.loaded"
              scope.state.isCartLoading = false

        setDynamicDeliveryFeeWithoutFeeView = () ->
          $('.total').removeClass('hidden')
          updateDeliveryFeeLabel(true)
          $('.dynamic-delivery-fee').children('.pull-right').html(
            $filter('translate')('dynamic.delivery.fee.no.state.info')
          );

        $(document).ready(() ->
          if ($('.dynamic-delivery-fee').length > 0)
            if (window.location.pathname == '/checkout')
              selectedAddress = $('form[name="deliveryForm"]').find('input[type="radio"]:checked')
              if !selectedAddress.length > 0 || selectedAddress.val() == 'new'
                setDynamicDeliveryFeeWithoutFeeView()
              else
                scope.$emit 'delivery.fee.isReady'
        )
    }
])
