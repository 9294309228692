app.controller('MemberOrdersController', [
  '$scope',
  'memberOrdersService',
  'ordersService',
  function ($scope, memberOrdersService, ordersService) {
    // init
    $scope.orderItems = [];
    $scope.ordersService = ordersService;

    $scope.pagination = {
      total: 0,
      itemsPerPage: 0,
      current: 0,
    };

    $scope.state = {
      loadingItems: false,
    };

    $scope.errors = {
      loadMoreError: false,
    };

    $scope.getOrders = function () {
      $scope.errors.loadMoreError = false;

      if (
        $scope.pagination.current > $scope.maxPage ||
        $scope.state.loadingItems
      ) {
        return;
      }

      $scope.state.loadingItems = true;

      memberOrdersService
        .getOrders($scope.pagination.current + 1)
        .then(function (response) {
          $scope.pagination.current += 1;
          var data = response.data.data;
          data.items.forEach(function (item) {
            if (!_.findWhere($scope.orderItems, { _id: item._id })) {
              $scope.orderItems.push(item);
            }
          });
          $scope.pagination.total = data.total;
          $scope.pagination.itemsPerPage = data.limit;
          $scope.maxPage = Math.ceil(
            $scope.pagination.total / $scope.pagination.itemsPerPage,
          );
        })
        .catch(function () {
          $scope.errors.loadMoreError = true;
        })
        .finally(function () {
          $scope.state.loadingItems = false;
        });
    };

    $scope.getOrders();
  },
]);
