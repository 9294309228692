app.directive('productSetSelector', [
  'productService',
  '$filter',
  'imageService',
  '$lightbox',
  'productSetService',
  function (
    productService,
    $filter,
    imageService,
    $lightbox,
    productSetService,
  ) {
    return {
      restrict: 'E',
      scope: {
        enableCollapse: '=',
        enableOverlay: '=',
        product: '=',
      },
      templateUrl:
        '/themes/v1/default/views/templates.product-set-selector.html',
      link: function (scope) {
        if (!productSetService.isProductSetEnabled(scope.product)) {
          return;
        }
        scope.childProducts = scope.product.child_products;
        scope.selectedVariationNameArray = [];
        scope.isChildProductOpen = [];
        scope.productService = productService;
        var calculateProductSetStock =
          productSetService.calculateProductSetStock;
        function initProductSet() {
          scope.selectedProductSetData = scope.childProducts.map(function (
            childProductData,
            index,
          ) {
            var variation =
              childProductData.variations.length === 0
                ? null
                : findVariationWithStock(childProductData) ||
                  childProductData.variations[0];

            if (variation) {
              scope.selectedVariationNameArray[index] = variation
                ? variation.fields.map(function (field) {
                    return $filter('translateModel')(field.name_translations);
                  })
                : null;
            }
            return {
              childProduct: childProductData,
              childVariation: variation,
            };
          });

          scope.childProducts.forEach(function (childProduct) {
            scope.isChildProductOpen.push(false);
            if (childProduct.variations.length === 0) {
              return;
            }

            var _fieldsArray = childProduct.variations.map(function (
              variation,
            ) {
              return variation.fields.map(function (field) {
                return $filter('translateModel')(field.name_translations);
              });
            });
            childProduct.digest_variations = childProduct.field_titles.map(
              function (field, index) {
                return _.uniq(
                  _fieldsArray.map(function (fieldArr) {
                    return fieldArr[index];
                  }),
                );
              },
            );
          });

          // to check stock when variations are loaded and changed for the first time
          scope.$emit('product_set.child_product.changed', {
            hasStock:
              calculateProductSetStock(scope.selectedProductSetData) > 0,
            selectedProductSetData: scope.selectedProductSetData,
          });
        }

        function initChildProductSlide() {
          // variations are default hidden, can be toggle slide down after
          setTimeout(function () {
            $('.child-product-variation-wrapper').hide();
          });
        }

        initProductSet();
        if (scope.enableCollapse) {
          initChildProductSlide();
        }

        scope.onVariationChange = function (childProductIndex) {
          var targetChildProduct = scope.childProducts[childProductIndex];
          var selectedVariation = targetChildProduct.variations.find(function (
            variation,
          ) {
            return variation.fields.every(function (field, index) {
              return (
                $filter('translateModel')(field.name_translations) ===
                scope.selectedVariationNameArray[childProductIndex][index]
              );
            });
          });
          scope.selectedProductSetData[
            childProductIndex
          ].childVariation = selectedVariation;

          scope.$emit('product_set.child_product.changed', {
            hasStock:
              calculateProductSetStock(scope.selectedProductSetData) > 0,
            selectedProductSetData: scope.selectedProductSetData,
            changedChildProduct:
              scope.selectedProductSetData[childProductIndex],
          });
        };

        scope.getImage = function (childProductIndex) {
          var targetChildProductData =
            scope.selectedProductSetData[childProductIndex];
          var noVariations =
            targetChildProductData.childProduct.variations.length === 0;
          var media;
          // no variations
          if (noVariations) {
            media = targetChildProductData.childProduct.media[0];
            // has variations
          } else {
            if (targetChildProductData.childVariation.media) {
              media = targetChildProductData.childVariation.media;
            } else {
              media = targetChildProductData.childProduct.media[0];
            }
          }
          return {
            media: media,
            url: imageService.getMediaImageUrl(media, { size: '200x' }),
          };
        };

        scope.onProductImageClicked = function (childProductIndex) {
          $lightbox.open([scope.getImage(childProductIndex).media], 0);
        };

        scope.toggleSlideChildProduct = function (childProductIndex) {
          scope.isChildProductOpen[childProductIndex] = !scope
            .isChildProductOpen[childProductIndex];
          $('.child-product-wrapper')
            .eq(childProductIndex)
            .find('.child-product-variation-wrapper')
            .slideToggle();
        };

        function findVariationWithStock(product) {
          return product.variations.find(function (variation) {
            return (
              product.unlimited_quantity ||
              product.out_of_stock_orderable ||
              variation.quantity > 0
            );
          });
        }

        if (scope.enableOverlay) {
          scope.scrollState = {
            position: '', // top, middle, bottom
          };
          scope.showOverlay = false;
          initScrollShadow();
        }
        function checkScrollPosition() {
          var $this = $(this);
          if ($this.scrollTop() === 0) {
            // if reach top
            scope.scrollState.position = 'top';
          } else if (
            $this.scrollTop() + $this.innerHeight() ===
            $this[0].scrollHeight
          ) {
            // if reach bottom
            scope.scrollState.position = 'bottom';
          } else {
            // if in middle
            scope.scrollState.position = 'middle';
          }
          scope.$digest();
        }

        function initScrollShadow() {
          $('.product-set-scroll-wrapper').on(
            'scroll',
            _.throttle(checkScrollPosition, 50),
          );

          var maxHeight = 700;
          var observer = new MutationObserver(function () {
            if ($('.product-set-scroll-wrapper').innerHeight() > maxHeight) {
              $(
                '.product-set-selector-wrapper, .product-set-scroll-wrapper',
              ).css('max-height', maxHeight);
              scope.scrollState.position = 'top';
              scope.showOverlay = true;
            }
          });
          var scrollWrapper = document.querySelector(
            '.product-set-scroll-wrapper',
          );
          observer.observe(scrollWrapper, {
            childList: true,
            subtree: true,
          });
        }
      },
    };
  },
]);
