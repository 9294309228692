angular.module('tw.lightbox', ['ui.bootstrap'])
  .provider('$lightbox', function () {
    var $lightboxProvider = {
      options: {

      },
      $get: ['$injector', '$rootScope', '$q', '$http', '$templateCache', '$controller', '$uibModal', '$timeout', 'staticImageHost',
        function ($injector, $rootScope, $q, $http, $templateCache, $controller, $uibModal, $timeout, staticImageHost) {
        
	var disableDrag = undefined;
	var $section = undefined;
	var $panzoom = undefined;  
        var $lightbox = {};
        var lightboxController = ['$scope','$uibModalInstance','items', 'selectedIndex',function ($scope, $uibModalInstance, items,selectedIndex) {
          $scope.staticImageHost = staticImageHost;
          $scope.items = items;
          if (angular.isUndefined(selectedIndex)){
            selectedIndex = 0;
          }

          $scope.selectedIndex = selectedIndex;
          
          $scope.selectedItem = $scope.items[selectedIndex];

          $scope.displayImage = function (item) {
            $scope.selectedIndex = $scope.items.indexOf(item);
          };
          $scope.hasPrev = function () {
            return ($scope.selectedIndex !== 0);
          };
          $scope.hasNext = function () {
            return ($scope.selectedIndex < $scope.items.length - 1);
          };
          $scope.close = function () {
            $uibModalInstance.close();
          };
          $scope.next = function () {
            $panzoom.panzoom("resetPan");
	    $panzoom.panzoom("resetZoom");
            $scope.selectedIndex = $scope.selectedIndex + 1;
            $scope.selectedItem = $scope.items[$scope.selectedIndex];
          };
          $scope.prev = function () {
            $panzoom.panzoom("resetPan");
	    $panzoom.panzoom("resetZoom");
            $scope.selectedIndex = $scope.selectedIndex - 1;
            $scope.selectedItem = $scope.items[$scope.selectedIndex];
          };
        }];
        $lightbox.open =function(items,selectedIndex, opts) {
          var lightboxOpts = {
            templateUrl: '/themes/v1/default/views/modules.lightbox.modal.html?' + (new Date()).getTime(),
            //templateUrl: '/modules/lightbox/modal',
            controller: lightboxController,
            resolve: {
              items: function () {return items;},
              selectedIndex: function() {return selectedIndex}
            },
            windowClass: "lightbox-modal-window",
          };
          if (opts && is.json(opts)) {
            for(var key in opts) lightboxOpts[key] = opts[key];
          }
          var lightbox = $uibModal.open(lightboxOpts);

          lightbox.opened.then(function(result){

            // zoom https://github.com/timmywil/jquery.panzoom
            $timeout(function(){

              disableDrag = true;
              $section = $('.lightbox-modal-window');
              $panzoom = $section.find('.lightbox-image').panzoom({
                onEnd: function () {
                  if (disableDrag) {
                    $panzoom.panzoom("resetPan");
                  }
                },
                onZoom: function () {
                  disableDrag = false;
                },
                onPan: function () {
                  // This is panning  equivalent with dragging 
                  // console.log("onpan")
                }
              });

              $panzoom.parent().on('mousewheel.focal', function( e ) {

                disableDrag = false;
                e.preventDefault();
                var delta = e.delta || e.originalEvent.wheelDelta;
                var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;
                $panzoom.panzoom('zoom', zoomOut, {
                  increment: 0.1,
                  animate: false,
                  focal: e
                });
                
              });

              $('.lightbox-content').css("overflow", "inherit");
              
            }, 100);
          })

        }
        return $lightbox;
      }]
    }
    return $lightboxProvider;
  })
;
