app.directive('ytoStoreOptions', [
  'slLogisticsYtoService',
  'cart',
  '$filter',
  function (slLogisticsYtoService, cart, $filter) {
    return {
      restrict: 'E',
      link: function (scope) {
        scope.regionType = cart.delivery_option.region_type;
        scope.state = {
          isLoadingDistrict: false,
          isLoadingStore: false,
        };
        scope.storeError = false;
        scope.selectedProvince = '';
        scope.selectedCity = '';
        scope.selectedDistrict = '';
        scope.selectedStore = '';

        var provinceMap = {};
        scope.provinceOptions = [];
        scope.cityOptions = [];
        scope.districtOptions = [];
        scope.storeOptions = [];

        slLogisticsYtoService
          .getCityList(scope.regionType)
          .then(function (res) {
            provinceMap = res.data.area_nodes;
            scope.provinceOptions = _.map(provinceMap, 'name');
          })
          .catch(function () {
            scope.$emit('checkout.delivery.yto_store.loaded.error');
          });

        scope.generateCityOptions = function () {
          if (scope.selectedProvince === '' || provinceMap.length === 0)
            return [];
          return _.find(provinceMap, function (province) {
            return province.name === scope.selectedProvince;
          }).area_nodes;
        };

        scope.onProvinceChange = function () {
          scope.selectedCity = '';
          scope.selectedDistrict = '';
          scope.selectedStore = '';
          scope.cityOptions = scope.generateCityOptions();
        };

        scope.onCityChange = function () {
          scope.selectedDistrict = '';
          scope.selectedStore = '';
          scope.storeError = false;
          if (!!scope.selectedCity) {
            slLogisticsYtoService
              .getDistrictList(
                scope.regionType,
                scope.selectedProvince,
                scope.selectedCity,
              )
              .then(function (res) {
                scope.districtOptions = res.data.area_nodes;
              })
              .catch(function () {
                scope.storeError = true;
                scope.selectedCity = '';
              });
          }
        };

        scope.onDistrictChange = function () {
          scope.selectedStore = '';
          scope.storeError = false;
          if (!!scope.selectedDistrict) {
            slLogisticsYtoService
              .getStoreList({
                region_type: scope.regionType,
                province: scope.selectedProvince,
                city: scope.selectedCity,
                district: scope.selectedDistrict,
              })
              .then(function (res) {
                var storeData = res.data.data;
                storeData.forEach(function (store) {
                  var stationName = store.station_name.endsWith('店')
                    ? store.station_name.slice(0, -1)
                    : store.station_name;
                  stationName = stationName.replace(store.station_code, '');
                  store.label =
                    $filter('translate')(
                      mapYtoLocationType(store.station_type),
                    ) +
                    stationName.replace(store.station_code, '') +
                    $filter('translate')(
                      'orders.fields.delivery_data.sl_logistics_yto_store.store.label',
                    );
                  store.station_name =
                    stationName +
                    $filter('translate')(
                      'orders.fields.delivery_data.sl_logistics_yto_store.store.label',
                    );
                });
                scope.storeOptions = storeData;
              })
              .catch(function () {
                scope.storeError = true;
                scope.selectedDistrict = '';
              });

            function mapYtoLocationType(typeNumber) {
              var map = {
                0: 'orders.fields.delivery_data.sl_logistics_yto_store.seven_eleven.label',
                1: 'orders.fields.delivery_data.sl_logistics_yto_store.family_mart.label',
              };
              return map[typeNumber];
            }
          }
        };
      },
    };
  },
]);
