app.directive('gaProduct', [
  'Analytics',
  'productService',
  'slPixelService',
  'gaService',
  function (Analytics, productService, slPixelService, gaService) {
    return {
      restrict: 'A',
      scope: {
        product: '@gaProduct',
      },
      link: function (scope, element, attrs) {
        slPixelService.addImpression(JSON.parse(scope.product));
        if (Analytics.configuration.enhancedEcommerce) {
          var variation = null;
          var product = JSON.parse(scope.product);
          variations = JSON.parse(_.unescape(product['variations']));
          if (variations && variations.length > 0) {
            variation = variations[0];
          }

          gaService.addImpression({
            productId: productService.getSku(
              product['id'],
              product['sku'],
              variation,
            ),
            name: product['title'],
            list: '',
            brand: '',
            category: '',
            variant: productService.getVariationName(variation),
            price: '',
          });
        }
      },
    };
  },
]);
