app.directive('rewardCreditSummary', [
  'checkoutService',
  '$compile',
  function (checkoutService, $compile) {
    return {
      restrict: 'A',
      link: function (scope, element) {
        scope.$on('checkout.cart.summary.changed', function () {
          checkoutService
            .requestPartial('cart', 'reward_credit_summary')
            .then(function (res) {
              element.html(res.data);
            })
            .catch(function (err) {
              console.error('Unable to load reward credit', err);
            });
        });
        scope.$on('checkout.cart.summary.reload', function () {
          checkoutService
            .requestPartial('cart', 'reward_credit_summary')
            .then(function (res) {
              element.html(res.data);
            })
            .catch(function (err) {
              console.error('Unable to load reward credit', err);
            });
        });
      },
    };
  },
]);
