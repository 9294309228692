app.controller('PasswordsNewController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  '$location',
  '$filter',
  'resource',
  'mainConfig',
  'featureService',
  'mobilePhoneService',
  'mobileSignInStep',
  'customValidator',
  'RecaptchaService',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    $location,
    $filter,
    resource,
    mainConfig,
    featureService,
    mobilePhoneService,
    mobileSignInStep,
    customValidator,
    RecaptchaService,
  ) {
    $scope.resource = resource.data;
    $scope.resource.owner_id = mainConfig.merchantData._id;
    $scope.mobileSignInStep = mobileSignInStep;
    $scope.emailMobileRegex = customValidator.EMAIL_MOBILE_REGEX;

    if (!isEnableMobilePhoneFeature()) {
      $scope.$watch('resource.email', function (newVal, oldVal) {
        if (newVal != oldVal) {
          $scope.userForm.email.$setValidity('general', true);
        }
      });
    }

    $scope.$on('mobile-step-change', function (event, step) {
      if (step === undefined) {
        $scope.resource.mobilePhone = undefined;
        $scope.resource.mobilePhoneOrEmail = undefined;
        $scope.userForm.submitted = false;
      }

      $scope.mobileSignInStep = step;
    });

    var checkUserExistenceByPhone = function () {
      mobilePhoneService
        .checkUserExistenceByPhone({
          mobilePhone: $scope.resource.mobilePhone,
          countryCallingCode: $scope.resource.countryCallingCode,
        })
        .then(
          function () {
            $scope.userForm.errorMessages = null;
            $scope.$broadcast('mobile-step-change', 'check_mobile');
          },
          function () {
            $scope.userForm.errorMessages = [
              $filter('translate')('session.forget_passowrd.check_phone.error'),
            ];
          },
        )
        .finally(function () {
          $scope.userForm.submitting = false;
        });
    };

    var resetPassword = function () {
      $http({
        method: 'POST',
        url: '/api/users/password',
        data: {
          user: $scope.resource,
          recaptchable: true,
        },
      })
        .then(function (res) {
          $scope.userForm.submitting = false;
          $scope.userForm.errorMessages = null;
          $scope.userForm.successMessages = [res.data.message];
        })
        .catch(function (res) {
          $scope.userForm.errorMessages = res.data.error_messages;
          $scope.userForm.submitting = false;
        })
        .finally(function () {
          RecaptchaService.reset('forgot-pw-recaptcha');
        });
    };

    $scope.formSubmit = function () {
      if ($scope.userForm.submitting) {
        return;
      }
      $scope.userForm.submitting = true;
      $scope.userForm.submitted = true;
      $scope.userForm.errorMessages = null;
      if ($scope.userForm.$invalid) {
        $scope.userForm.submitting = false;
        return;
      }

      if (isEnableMobilePhoneFeature()) {
        // currently emailMobileRegex not handle all number
        if (
          $scope.emailMobileRegex.test($scope.resource.mobilePhoneOrEmail) &&
          !/^\d+$/.test($scope.resource.mobilePhoneOrEmail)
        ) {
          $scope.resource.email = $scope.resource.mobilePhoneOrEmail;
          resetPassword();
        } else {
          $scope.resource.mobilePhone = $scope.resource.mobilePhoneOrEmail;
          $scope.resource.countryCallingCode =
            $scope.resource.countryCallingCode;
          checkUserExistenceByPhone();
        }
      } else {
        resetPassword();
      }
    };

    function isEnableMobilePhoneFeature() {
      return featureService.hasFeature('mobile_signup_p2');
    }
  },
]);
