app.directive('checkoutOmisePayNow', [
  'mainConfig',
  function (mainConfig) {
    return {
      restrict: 'A',
      scope: '=',
      link: function (scope, element, attr) {
        return $.getScript(
          mainConfig.universalPaymentCashierSDKUrl,
          function () {
            scope.omisePayNow = {
              env: attr.env,
              deviceType: /iPad/.test(navigator.userAgent)
                ? 'pad'
                : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                    navigator.userAgent,
                  )
                ? 'phone'
                : 'pc',
              pay: window.paymentElement.pay,
            };
          },
        );
      },
    };
  },
]);
