app.directive('productAvailableInfo', [
  'featureService',
  'localDatetimeServices',
  '$timeout',
  '$filter',
  function (featureService, localDatetimeServices, $timeout, $filter) {
    return {
      templateUrl:
        '/themes/shared/product/templates.product_available_info.html',
      restrict: 'E',
      scope: {
        product: '=?',
      },
      link: function (scope, element) {
        if (featureService.hasFeature('product_available_time')) {
          var product = scope.product || scope.$parent.product;
          var currentTime = dayjs(new Date().getTime());
          var startTime = dayjs(product.available_start_time);
          var endTime = dayjs(product.available_end_time);
          var format = 'YYYY/MM/DD HH:mm';

          if (currentTime.isBefore(startTime)) {
            scope.infoText1 = 'product.available_time_info.start.1';
            scope.infoText2 = 'product.available_time_info.start.2';
            scope.infoTime = startTime.format(format);
          } else if (currentTime.isBetween(startTime, endTime)) {
            scope.infoText1 = 'product.available_time_info.end.1';
            scope.infoText2 = 'product.available_time_info.end.2';
            scope.infoTime = endTime.format(format);
          }

          $timeout(function () {
            var $dateDOM = element.find('.product-available-info-time');
            var offset = new Date().getTimezoneOffset() / -60;
            var title = $filter('translate')(
              'promotions.page.display_local_timezone',
              {
                offset: offset < 0 ? offset : '+' + Math.abs(offset),
              },
            );

            localDatetimeServices.mountTooltip($dateDOM, title);
          });
        }
      },
    };
  },
]);
