app.directive('storePickUpOptions', [
  'mainConfig',
  function (mainConfig) {
    return {
      restrict: 'E',
      templateUrl:
        '/themes/shared/checkout/templates.store_pick_up_options.html',
      scope: {
        pickUpConfig: '=?',
        addresses: '=?',
      },
      link: function (scope, element, attrs) {
        scope.language = mainConfig.localeData.loadedLanguage.code;
        scope.addressesBasedOnLang = scope.addresses[scope.language];
        scope.addressFormat = scope.pickUpConfig.address_format[scope.language];
        scope.fields = {};
        scope.selectedStore = {};

        if (scope.pickUpConfig.is_show_dropdown) {
          scope.dropdownFieldNames = scope.pickUpConfig.dropdown_fields[
            scope.language
          ].map(function (fieldName) {
            scope.fields[fieldName] = '';
            return fieldName['field'];
          });
          scope.fieldLength = scope.dropdownFieldNames.length;
        }

        scope.getFieldLabel = function (fieldName) {
          return _.find(
            scope.pickUpConfig.dropdown_fields[scope.language],
            function (field) {
              return field['field'] === fieldName;
            },
          )['label'];
        };

        scope.generateOptions = function (fieldName, index) {
          // if first dropdown
          if (index === 0) {
            return _.keys(scope.addressesBasedOnLang);
          }
          // if second dropdown
          var prevFieldName = scope.dropdownFieldNames[index - 1];
          var prevFieldValue = scope.fields[prevFieldName];
          if (prevFieldValue !== '') {
            return _.keys(scope.addressesBasedOnLang[prevFieldValue]);
          } else {
            return [''];
          }
        };

        scope.generateStoreOptions = function () {
          if (scope.pickUpConfig.is_show_dropdown) {
            var firstFieldValue = scope.fields[scope.dropdownFieldNames[0]];
            var secondFieldValue = scope.fields[scope.dropdownFieldNames[1]];
            if (scope.dropdownFieldNames.length === 1) {
              return firstFieldValue
                ? scope.addressesBasedOnLang[firstFieldValue]
                : [];
            }

            if (scope.dropdownFieldNames.length === 2) {
              return secondFieldValue
                ? scope.addressesBasedOnLang[firstFieldValue][secondFieldValue]
                : [];
            }
          } else {
            return scope.addressesBasedOnLang;
          }
        };

        scope.getLabelName = function (option) {
          return option.store_name + ' (' + scope.getStoreAddress(option) + ')';
        };

        scope.getStoreAddress = function (store) {
          return scope.addressFormat.reduce(function (accum, fieldName) {
            return accum + store[fieldName];
          }, '');
        };
      },
    };
  },
]);
