app.directive('membershipReminder', [
  'mainConfig',
  '$uibModal',
  '$filter',
  'moneyService',
  'featureService',
  function (mainConfig, $uibModal, $filter, moneyService, featureService) {
    return {
      restrict: 'E',
      scope: {
        merchantTiers: '=',
        reminderSetting: '=',
      },
      templateUrl: '/themes/shared/users/templates.membership_reminder.html',
      link: function (scope) {
        scope.featureService = featureService;
        scope.mainConfig = mainConfig;
        scope.$on('memberCenterUser.fetch', function (event, user) {
          scope.user = user;
          scope.membershipTierGap = user.membership_tier_gap;
          if (!_.isEmpty(user.membership_tier_gap)) {
            setMemberShipInfo();
            setNextUpgradeInfo();
          }
        });

        function setMemberShipInfo() {
          var infoHash = {
            levelName: $filter('translate')('users.member.normal-level'),
          };

          if (!_.isEmpty(scope.user.membership_tier)) {
            var extendRule = _.find(
              scope.user.membership_tier.membership_tier_rules,
              function (rule) {
                return rule.effect_type === 'extend';
              },
            );

            infoHash['tierExpireData'] = getTierExpireDate(
              scope.user.tier_expires_at,
              scope.user.membership_tier.valid_period.time_value,
              extendRule,
            );
            infoHash['levelName'] = $filter('translateModel')(
              scope.user.membership_tier.name_translations,
            );
            infoHash['currentDiscount'] = getDiscountTranslate(
              scope.user.membership_tier.member_discount_percentage,
            );

            if (
              !_.isEmpty(extendRule) &&
              !_.isNull(scope.user.tier_expires_at)
            ) {
              infoHash['extendTierCondition'] = getTierCondition(extendRule);
            }
          }

          if (!_.isEmpty(scope.membershipTierGap.next_tier)) {
            var upgradeRule = _.find(
              scope.membershipTierGap.next_tier.membership_tier_rules,
              function (rule) {
                return rule.effect_type === 'upgrade';
              },
            );
            infoHash['nextTierName'] = $filter('translateModel')(
              scope.membershipTierGap.next_tier.name_translations,
            );
            infoHash['nextDiscount'] = getDiscountTranslate(
              scope.membershipTierGap.next_discount,
            );
            infoHash['nextTierCondition'] = getTierCondition(upgradeRule);
          }
          scope.memberShipInfo = infoHash;
        }

        function setNextUpgradeInfo() {
          var nextTierHash = {};
          var currencySymbol =
            mainConfig.merchantData.base_currency.alternate_symbol;

          if (!_.isEmpty(scope.user.membership_tier_gap.next_tier)) {
            var upgradeRule = _.find(
              scope.user.membership_tier_gap.next_tier.membership_tier_rules,
              function (rule) {
                return rule.effect_type === 'upgrade';
              },
            );
            if (upgradeRule.condition_interval.type === 'single_purchase') {
              nextTierHash['nextSpending'] =
                currencySymbol +
                spendingToMoney(upgradeRule.total_spending.cents);
            }
            nextTierHash['upgradeType'] = upgradeRule.condition_interval.type;
            nextTierHash['progressPercentage'] = getProgressPercentage(
              scope.user.membership_tier_gap,
            );
            nextTierHash['currentSpending'] =
              currencySymbol +
              spendingToMoney(scope.user.membership_tier_gap.user_spending);
            nextTierHash['gapSpending'] =
              currencySymbol +
              spendingToMoney(
                scope.user.membership_tier_gap.next_total_spending -
                  scope.user.membership_tier_gap.user_spending,
              );
            nextTierHash['upgradeCondition'] = getUpgradeCondition(
              upgradeRule,
              nextTierHash['upgradeType'],
            );
          }
          scope.upgradeTierInfo = nextTierHash;
        }

        var getDiscountTranslate = function (discount) {
          var currentLanguage = mainConfig.localeData.loadedLanguage.code;
          if (_.contains(['zh-hant', 'zh-cn'], currentLanguage)) {
            var discountValue = 100 - discount;
            if (discountValue < 10) {
              discountValue = '0.' + discountValue;
            }
            return (discountValue + '').replace(/0$/, '');
          }
          return discount;
        };

        var getTierCondition = function (rule) {
          if (_.isNull(rule) || _.isUndefined(rule)) {
            return null;
          }
          var currencySymbol =
            mainConfig.merchantData.base_currency.alternate_symbol;
          var spending =
            currencySymbol +
            spendingToMoney(rule.total_spending.cents, currencySymbol);

          if (rule.effect_type === 'extend') {
            return $filter('translate')(
              'users.membership.extend_valid_period',
              {
                months: rule.effect_interval.time_value,
                type: rule.condition_interval.type,
                spending: spending,
              },
            );
          }
          if (rule.condition_interval.type === 'within_interval') {
            return $filter('translate')(
              'users.membership.spending_within_months',
              {
                months: rule.condition_interval.time_value,
                spending: spending,
              },
            );
          }
          return $filter('translate')(
            'users.membership.spending_single_purchase',
            {
              spending: spending,
            },
          );
        };

        var spendingToMoney = function (spending) {
          return moneyService.centsToDollars(
            spending,
            mainConfig.merchantData.base_currency_code,
          );
        };

        function getProgressPercentage(tierGap) {
          var percentage =
            (tierGap.user_spending / tierGap.next_total_spending) * 100;
          return (percentage >= 100 ? 100 : percentage) + '%';
        }

        function getUpgradeCondition(rule, type) {
          if (type === 'single_purchase') {
            return $filter('translate')(
              'users.membership.tier.upgrade.to_next_level.single',
            );
          } else {
            return $filter('translate')(
              'users.membership.tier.upgrade.to_next_level.within',
              {
                month: rule.condition_interval.time_value,
              },
            );
          }
        }

        function formatDate(dateString) {
          var date = new Date(dateString);
          return (
            date.getFullYear() +
            '/' +
            (date.getMonth() + 1) +
            '/' +
            date.getDate()
          );
        }

        function getTierExpireDate(tierExpiresAt, timeValue, extendRule) {
          if (tierExpiresAt) {
            return formatDate(tierExpiresAt);
          }
          return $filter('translate')('users.membership.expire.unlimited');
        }

        scope.openMerchantTiersDialog = function () {
          $uibModal.open({
            templateUrl:
              '/themes/v1/default/views/templates.dialog.merchant-tiers',
            controller: 'UsersEditMerchantTiersDialogController',
            resolve: {
              merchantTiers: function () {
                return scope.merchantTiers;
              },
              getDiscountTranslate: function () {
                return getDiscountTranslate;
              },
              spendingToMoney: function () {
                return spendingToMoney;
              },
            },
          });
        };
      },
    };
  },
]);
